import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
// eslint-disable-next-line import/first
import { useMemo, useCallback } from "react"
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AccessRequest = {
  __typename: 'AccessRequest';
  approvedAt?: Maybe<Scalars['String']['output']>;
  consentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  durationHours?: Maybe<Scalars['Int']['output']>;
  grantedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  requireApproval: Scalars['Boolean']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  tenant: Scalars['String']['output'];
  user: User;
};

export type AddAccessRequestInput = {
  durationHours?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  requireApproval: Scalars['Boolean']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  tenant: Scalars['String']['input'];
  user: UserIdentifier;
};

export type AddAccessRequestResponse = {
  __typename: 'AddAccessRequestResponse';
  eligible: Scalars['Boolean']['output'];
  request: AccessRequest;
};

export type AddDataPipelineSourceInput = {
  dataPipelineId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
};

export type AddDataPipelineSourceResponse = {
  __typename: 'AddDataPipelineSourceResponse';
  dataPipeline: DataPipeline;
};

export type AddDataRepositoryFieldInput = {
  dataRepositoryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddDataRepositoryFieldResponse = {
  __typename: 'AddDataRepositoryFieldResponse';
  dataRepository: DataRepository;
};

export type AddDataTableConnectionCriterionInput = {
  dataTableConnectionId: Scalars['String']['input'];
  ignoreOnBlankTarget?: InputMaybe<Scalars['Boolean']['input']>;
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type AddDataTableConnectionCriterionResponse = {
  __typename: 'AddDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type AddImportFieldMappingInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type AddImportFieldMappingResponse = {
  __typename: 'AddImportFieldMappingResponse';
  updatedImportConfiguration: ImportConfiguration;
};

export type AddLookupOperationCriteriaInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type AddLookupOperationCriteriaResponse = {
  __typename: 'AddLookupOperationCriteriaResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type AddLookupOperationFieldOutputsInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type AddLookupOperationFieldOutputsResponse = {
  __typename: 'AddLookupOperationFieldOutputsResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type AddManyDataRepositoryFieldsInput = {
  dataRepositoryId: Scalars['String']['input'];
  fieldNames: Array<Scalars['String']['input']>;
};

export type AddManyDataRepositoryFieldsResponse = {
  __typename: 'AddManyDataRepositoryFieldsResponse';
  dataRepository: DataRepository;
};

export type AddManyImportFieldMappingsInput = {
  fieldMappings: Array<ImportFieldMappingInput>;
  importConfigurationId: Scalars['String']['input'];
};

export type AddManyImportFieldMappingsResponse = {
  __typename: 'AddManyImportFieldMappingsResponse';
  updatedImportConfiguration: ImportConfiguration;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationInput = {
  addOrRemove: AddOrRemoveInput;
  dataTableId: Scalars['String']['input'];
  fields: Array<Scalars['String']['input']>;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationResponse = {
  __typename: 'AddOrRemoveFieldsFromDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type AddOrRemoveInput =
  | 'ADD'
  | 'REMOVE';

export type AddTagValueToColumnInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type AddTagValueToColumnResponse = {
  __typename: 'AddTagValueToColumnResponse';
  tag: TagConfiguration;
};

export type AggregatedView = {
  __typename: 'AggregatedView';
  aggregationConfigurations: Array<AggregationConfiguration>;
  filters: Array<AnalysisFilter>;
  groupingConfigurations: Array<GroupingConfiguration>;
  id: Scalars['ID']['output'];
  resultDataTableId: Scalars['String']['output'];
  sourceDataTableId: Scalars['String']['output'];
};

export type AggregationConfiguration = {
  __typename: 'AggregationConfiguration';
  field: Scalars['String']['output'];
  filters: Array<AnalysisFilter>;
  id: Scalars['ID']['output'];
  type: AggregationType;
};

export type AggregationType =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AggregationTypeInput =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AnalysisFilter = {
  __typename: 'AnalysisFilter';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  exclude: Array<Scalars['String']['output']>;
  excludeBlanks?: Maybe<Scalars['Boolean']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  field: Scalars['String']['output'];
  filterType: Scalars['String']['output'];
  include: Array<Scalars['String']['output']>;
  includeBlanks?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  periodLength?: Maybe<Scalars['Int']['output']>;
  periodUnit?: Maybe<Scalars['String']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type AnalysisFilterInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  exclude: Array<Scalars['String']['input']>;
  excludeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  include: Array<Scalars['String']['input']>;
  includeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  periodLength?: InputMaybe<Scalars['Int']['input']>;
  periodUnit?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyChangesInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type ApplyChangesResponse = {
  __typename: 'ApplyChangesResponse';
  notificationIds: Array<Scalars['String']['output']>;
};

export type ApplyDataTableConnectionChangesInput = {
  changedConnectionColumns: Array<Scalars['String']['input']>;
  columnsWithChangedCubeConfiguration: Array<Scalars['String']['input']>;
  createdConnectionColumns: Array<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesResponse = {
  __typename: 'ApplyDataTableConnectionChangesResponse';
  notificationId: Scalars['String']['output'];
};

export type AsyncDataStatus =
  | 'FINISHED'
  | 'IN_PROGRESS';

export type AutofillGroupStructureFieldInputInput = {
  fieldKey: Scalars['String']['input'];
};

export type ChangeOperationTypeInput =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type CheckNewDataColumnRulesInput = {
  rules: Array<CheckRulePayloadInput>;
  targetDataColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type CheckNewDataColumnRulesResponse = {
  __typename: 'CheckNewDataColumnRulesResponse';
  match: Array<DataColumnRule>;
};

export type CheckRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  value: Scalars['String']['input'];
};

export type ClassificationDataColumn = {
  __typename: 'ClassificationDataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type ClassificationFieldSamples = {
  __typename: 'ClassificationFieldSamples';
  fieldKey: Scalars['String']['output'];
  samples: Array<Scalars['String']['output']>;
};

export type ClassificationModeInput =
  | 'AFTER_EXISTING_RULES'
  | 'BEFORE_EXISTING_RULES';

export type CompressionType =
  | 'GZIP';

export type CompressionTypeInput =
  | 'GZIP';

export type ConflictingRule = {
  __typename: 'ConflictingRule';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  oldGroupPath: Array<Scalars['String']['output']>;
};

export type ConflictingRuleFromFile = {
  __typename: 'ConflictingRuleFromFile';
  filters: Array<Filter>;
  groupInFilePath: Array<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
};

export type ConnectedFieldAndColumn = {
  __typename: 'ConnectedFieldAndColumn';
  dataColumnId: Scalars['String']['output'];
  dataColumnName: Scalars['String']['output'];
  fieldId: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  globalType?: Maybe<Scalars['String']['output']>;
};

export type ContentType =
  | 'CSV'
  | 'JSON'
  | 'NJSON'
  | 'XLSX';

export type ContentTypeInput =
  | 'CSV'
  | 'JSON'
  | 'NJSON'
  | 'XLSX';

export type CreateAggregatedDataColumnInput = {
  aggregationField: Scalars['String']['input'];
  aggregationType: AggregationTypeInput;
  dataTableId: Scalars['String']['input'];
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sourceDataTable: Scalars['String']['input'];
};

export type CreateAggregatedDataColumnResponse = {
  __typename: 'CreateAggregatedDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateAggregatedViewInput = {
  aggregations: Array<CreateAggregationInput>;
  baseDataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAggregatedViewResponse = {
  __typename: 'CreateAggregatedViewResponse';
  entity: AggregatedView;
};

export type CreateAggregationInput = {
  field: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  name: Scalars['String']['input'];
  type: AggregationTypeInput;
};

export type CreateConvertCountryDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateConvertCountryDataPipelineOperationResponse = {
  __typename: 'CreateConvertCountryDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType: DataColumnTypeInput;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnPayloadInput = {
  dataType: DataColumnTypeInput;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnResponse = {
  __typename: 'CreateDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateDataColumnRulePayloadInput = {
  dataColumnRuleSetId?: InputMaybe<Scalars['String']['input']>;
  filters: Array<FilterInput>;
  groupName: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureInput = {
  classificationMode: ClassificationModeInput;
  fieldsWithLevel: Array<FieldWithLevelInput>;
  groupStructureId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type CreateDataColumnRuleSetFromGroupStructureResponse = {
  __typename: 'CreateDataColumnRuleSetFromGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateDataColumnsInput = {
  dataColumns: Array<CreateDataColumnPayloadInput>;
  dataTableId: Scalars['String']['input'];
};

export type CreateDataColumnsResponse = {
  __typename: 'CreateDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type CreateDataPipelineInput = {
  name: Scalars['String']['input'];
  sourceDataRepositoryIds: Array<Scalars['String']['input']>;
  targetDataTableId: Scalars['String']['input'];
};

export type CreateDataPipelineResponse = {
  __typename: 'CreateDataPipelineResponse';
  dataPipeline: DataPipeline;
};

export type CreateDataRepositoryCollectionInput = {
  name: Scalars['String']['input'];
};

export type CreateDataRepositoryCollectionResponse = {
  __typename: 'CreateDataRepositoryCollectionResponse';
  dataRepositoryCollection: DataRepositoryCollection;
};

export type CreateDataRepositoryInput = {
  name: Scalars['String']['input'];
  repositoryCollectionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRepositoryResponse = {
  __typename: 'CreateDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type CreateDataRowInput = {
  dataJson: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRowResponse = {
  __typename: 'CreateDataRowResponse';
  dataTableRow: DataTableRow;
};

export type CreateDataTableCollectionInput = {
  DataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDataTableCollectionResponse = {
  __typename: 'CreateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type CreateDataTableConnectionCriterionInputInput = {
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type CreateDataTableConnectionInput = {
  criteria: Array<CreateDataTableConnectionCriterionInputInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  targetDataSourceIdentifier: Scalars['String']['input'];
  targetDataSourceType: DataTableConnectionSourceTypeInput;
};

export type CreateDataTableConnectionResponse = {
  __typename: 'CreateDataTableConnectionResponse';
  dataTableConnection: DataTableConnection;
};

export type CreateDataTableInput = {
  name: Scalars['String']['input'];
};

export type CreateDataTableResponse = {
  __typename: 'CreateDataTableResponse';
  entity: DataTable;
};

export type CreateDateParserDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  dateFormat: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateDateParserDataPipelineOperationResponse = {
  __typename: 'CreateDateParserDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateDownloadUrlsV2Input = {
  fileIds: Array<Scalars['String']['input']>;
};

export type CreateDownloadUrlsV2Response = {
  __typename: 'CreateDownloadUrlsV2Response';
  urls: Array<FileUrl>;
};

export type CreateFillBlanksDataPipelineOperationInput = {
  blankValue?: InputMaybe<Scalars['String']['input']>;
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  withColumn: Scalars['Boolean']['input'];
};

export type CreateFillBlanksDataPipelineOperationResponse = {
  __typename: 'CreateFillBlanksDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateGetOrCreateDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  getOrCreateCriteria: Array<GetOrCreateCriterionInput>;
  name: Scalars['String']['input'];
  targetDataRepositoryId: Scalars['String']['input'];
  upsert: Scalars['Boolean']['input'];
};

export type CreateGetOrCreateDataPipelineOperationResponse = {
  __typename: 'CreateGetOrCreateDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupResponse = {
  __typename: 'CreateGroupResponse';
  group: Group;
};

export type CreateGroupStructureFromUploadFileInput = {
  dataTableId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  groupStructureName: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupStructureFromUploadFileResponse = {
  __typename: 'CreateGroupStructureFromUploadFileResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  externallyManaged?: InputMaybe<Scalars['Boolean']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type CreateGroupStructureResponse = {
  __typename: 'CreateGroupStructureResponse';
  dataColumnGroupStructure: DataColumnGroupStructure;
};

export type CreateImportConfigurationInput = {
  compressionType?: InputMaybe<CompressionTypeInput>;
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  importType: ImportTypeInput;
  name: Scalars['String']['input'];
  targetDataRepository: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type CreateImportConfigurationResponse = {
  __typename: 'CreateImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
};

export type CreateInputOutputConnectionInput = {
  dataPipelineId: Scalars['String']['input'];
  from: InputOutputNodeInput;
  to: InputOutputNodeInput;
};

export type CreateInputOutputConnectionResponse = {
  __typename: 'CreateInputOutputConnectionResponse';
  inputOutputConnection: InputOutputConnection;
};

export type CreateKeywordTaggerDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateKeywordTaggerDataPipelineOperationResponse = {
  __typename: 'CreateKeywordTaggerDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLetterCasingDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  letterCasing: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateLetterCasingDataPipelineOperationResponse = {
  __typename: 'CreateLetterCasingDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateListParserDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  splitString: Scalars['String']['input'];
};

export type CreateListParserDataPipelineOperationResponse = {
  __typename: 'CreateListParserDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLookupCriterionInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type CreateLookupDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  lookupCriteria: Array<CreateLookupCriterionInput>;
  lookupFieldOutputs: Array<CreateLookupFieldOutputInput>;
  name: Scalars['String']['input'];
  targetDataRepositoryId: Scalars['String']['input'];
};

export type CreateLookupDataPipelineOperationResponse = {
  __typename: 'CreateLookupDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateLookupFieldOutputInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type CreateManyInputOutputConnectionsInput = {
  connections: Array<InputOutputConnectionCreationInputInput>;
  dataPipelineId: Scalars['String']['input'];
};

export type CreateManyInputOutputConnectionsResponse = {
  __typename: 'CreateManyInputOutputConnectionsResponse';
  inputOutputConnections: Array<InputOutputConnection>;
};

export type CreateMappingDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMappingDataPipelineOperationResponse = {
  __typename: 'CreateMappingDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMatchCompanyDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMatchCompanyDataPipelineOperationResponse = {
  __typename: 'CreateMatchCompanyDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMathematicalExpressionDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  expressionJson: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMathematicalExpressionDataPipelineOperationResponse = {
  __typename: 'CreateMathematicalExpressionDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateMonetaryAmountDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMonetaryAmountDataPipelineOperationResponse = {
  __typename: 'CreateMonetaryAmountDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreatePipelineFilterCombinationInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type CreatePipelineFilterCombinationResponse = {
  __typename: 'CreatePipelineFilterCombinationResponse';
  dataPipeline: DataPipeline;
};

export type CreatePipelineFilterInput = {
  filterCombinationId: Scalars['String']['input'];
  filterDataType: FilterDataTypeInput;
  operator: FilterOperatorTypeInput;
  sourceId: Scalars['String']['input'];
  sourceType: InputOutputNodeTypeInput;
  value: Scalars['String']['input'];
};

export type CreatePipelineFilterResponse = {
  __typename: 'CreatePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type CreateRegexDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regex: Scalars['String']['input'];
};

export type CreateRegexDataPipelineOperationResponse = {
  __typename: 'CreateRegexDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateReplaceDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  isRegex: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  replaceString: Scalars['String']['input'];
  withString: Scalars['String']['input'];
};

export type CreateReplaceDataPipelineOperationResponse = {
  __typename: 'CreateReplaceDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateRepositoryRowDeleteQueryInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  numberOfPeriods?: InputMaybe<Scalars['Int']['input']>;
  periodGranularity?: InputMaybe<PeriodGranularityInput>;
  queryType: QueryTypeInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRepositoryRowDeleteQueryResponse = {
  __typename: 'CreateRepositoryRowDeleteQueryResponse';
  repositoryRowDeleteQuery: RepositoryRowDeleteQuery;
};

export type CreateRulesInput = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesResponse = {
  __typename: 'CreateRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type CreateRulesV2Input = {
  classificationMode: ClassificationModeInput;
  dataColumnId: Scalars['String']['input'];
  rules: Array<CreateDataColumnRulePayloadInput>;
};

export type CreateRulesV2Response = {
  __typename: 'CreateRulesV2Response';
  insertedIds: Array<IdKeyPair>;
  notificationId: Scalars['String']['output'];
};

export type CreateSplitDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  splitString: Scalars['String']['input'];
};

export type CreateSplitDataPipelineOperationResponse = {
  __typename: 'CreateSplitDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateTagColumnInput = {
  dataTableId: Scalars['String']['input'];
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  tagStringValues: Array<Scalars['String']['input']>;
};

export type CreateTagColumnResponse = {
  __typename: 'CreateTagColumnResponse';
  columnId: Scalars['String']['output'];
  tag: TagConfiguration;
};

export type CreateTemplateStringDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  templateString: Scalars['String']['input'];
};

export type CreateTemplateStringDataPipelineOperationResponse = {
  __typename: 'CreateTemplateStringDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateTranslationDataPipelineOperationInput = {
  dataPipelineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateTranslationDataPipelineOperationResponse = {
  __typename: 'CreateTranslationDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type CreateUniqueIdentifierConfigurationInput = {
  partialReplace: Scalars['Boolean']['input'];
  repositoryId: Scalars['String']['input'];
  retroactive: Scalars['Boolean']['input'];
  uniqueIdentifierFields: Array<Scalars['String']['input']>;
};

export type CreateUniqueIdentifierConfigurationResponse = {
  __typename: 'CreateUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type CreateUploadUrlsV2Input = {
  files: Array<FileDetailsV2Input>;
  type: Scalars['String']['input'];
};

export type CreateUploadUrlsV2Response = {
  __typename: 'CreateUploadUrlsV2Response';
  urls: Array<FileUrl>;
};

export type CriterionType =
  | 'MATCH'
  | 'ON_CREATE';

export type CriterionTypeInput =
  | 'MATCH'
  | 'ON_CREATE';

export type CsvConfiguration = {
  __typename: 'CsvConfiguration';
  delimiter?: Maybe<DelimiterType>;
  encoding?: Maybe<EncodingType>;
  escapeCharacter?: Maybe<EscapeCharacterType>;
  quoteCharacter?: Maybe<QuoteCharacterType>;
};

export type CsvConfigurationInput = {
  delimiter?: InputMaybe<DelimiterTypeInput>;
  encoding?: InputMaybe<EncodingTypeInput>;
  escapeCharacter?: InputMaybe<EscapeCharacterTypeInput>;
  quoteCharacter?: InputMaybe<QuoteCharacterTypeInput>;
};

export type DataColumn = {
  __typename: 'DataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type DataColumnElasticFieldMap = {
  __typename: 'DataColumnElasticFieldMap';
  dataColumn: DataColumn;
  fields: Array<Scalars['String']['output']>;
};

export type DataColumnGroupStructure = {
  __typename: 'DataColumnGroupStructure';
  dataColumn: ClassificationDataColumn;
  groupStructure: GroupStructure;
};

export type DataColumnRule = {
  __typename: 'DataColumnRule';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  dataColumnRuleSetId: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  filters: Array<Filter>;
  groupLevel?: Maybe<Scalars['Int']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  precedence?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type DataColumnRuleSet = {
  __typename: 'DataColumnRuleSet';
  fieldsWithFilterType: Array<FieldWithFilterType>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  precedence: Scalars['Int']['output'];
  targetDataColumnId: Scalars['String']['output'];
};

export type DataColumnSelectionInput =
  | 'INCLUDE_RELATED'
  | 'ONLY_INDEXED';

export type DataColumnType =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataColumnTypeInput =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataCubeConfiguration = {
  __typename: 'DataCubeConfiguration';
  dataTableId: Scalars['String']['output'];
  fieldsToInclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DataPipeline = {
  __typename: 'DataPipeline';
  dataFilterCombinations: Array<DataPipelineFilterCombination>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceDataRepositoryIds: Array<Scalars['String']['output']>;
  targetDataTableId: Scalars['String']['output'];
};

export type DataPipelineExecution = {
  __typename: 'DataPipelineExecution';
  batchIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  dataPipelineId: Scalars['String']['output'];
  dryRun?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type DataPipelineFilter = {
  __typename: 'DataPipelineFilter';
  filterDataType: FilterDataType;
  id: Scalars['ID']['output'];
  operator: FilterOperatorType;
  sourceId: Scalars['String']['output'];
  sourceType: InputOutputNodeType;
  value: Scalars['String']['output'];
};

export type DataPipelineFilterCombination = {
  __typename: 'DataPipelineFilterCombination';
  filters: Array<DataPipelineFilter>;
  id: Scalars['ID']['output'];
};

export type DataPipelineLayout = {
  __typename: 'DataPipelineLayout';
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  layoutJson: Scalars['String']['output'];
};

export type DataPipelineOperation = {
  __typename: 'DataPipelineOperation';
  dataPipelineId: Scalars['String']['output'];
  dateParserOperationAdapter?: Maybe<DateParserOperationAdapter>;
  fillBlanksOperationAdapter?: Maybe<FillBlanksOperationAdapter>;
  getOrCreateOperationAdapter?: Maybe<GetOrCreateOperationAdapter>;
  id: Scalars['ID']['output'];
  input: Array<DataPipelineOperationInputOutput>;
  letterCasingOperationAdapter?: Maybe<LetterCasingOperationAdapter>;
  listParserOperationAdapter?: Maybe<ListParserOperationAdapter>;
  lookupOperationAdapter?: Maybe<LookupOperationAdapter>;
  mappingOperationAdapter?: Maybe<MappingOperationAdapter>;
  mathematicalExpressionOperationAdapter?: Maybe<MathematicalExpressionOperationAdapter>;
  name: Scalars['String']['output'];
  operationType: OperationType;
  output: Array<DataPipelineOperationInputOutput>;
  regexOperationAdapter?: Maybe<RegexOperationAdapter>;
  replaceOperationAdapter?: Maybe<ReplaceOperationAdapter>;
  splitOperationAdapter?: Maybe<SplitOperationAdapter>;
  templateStringOperationAdapter?: Maybe<TemplateStringOperationAdapter>;
};

export type DataPipelineOperationInputOutput = {
  __typename: 'DataPipelineOperationInputOutput';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type DataRepository = {
  __typename: 'DataRepository';
  autoTriggerPipelines: Scalars['Boolean']['output'];
  fields: Array<DataRepositoryField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  repositoryCollectionId?: Maybe<Scalars['String']['output']>;
  uniqueIdentifierConfiguration?: Maybe<UniqueIdentifierConfiguration>;
};

export type DataRepositoryCollection = {
  __typename: 'DataRepositoryCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataRepositoryField = {
  __typename: 'DataRepositoryField';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DataRepositoryRow = {
  __typename: 'DataRepositoryRow';
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId: Scalars['String']['output'];
};

export type DataTable = {
  __typename: 'DataTable';
  DataTableCollectionId?: Maybe<Scalars['String']['output']>;
  elasticIndex: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  hasPendingConnectionChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateSetting?: Maybe<RelativeDateSetting>;
  tableType?: Maybe<Scalars['String']['output']>;
};

export type DataTableCollection = {
  __typename: 'DataTableCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataTableCollectionOrderItem = {
  __typename: 'DataTableCollectionOrderItem';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type DataTableCollectionOrderItemInput = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};

export type DataTableConnection = {
  __typename: 'DataTableConnection';
  criteria: Array<DataTableConnectionCriterion>;
  dataColumnId: Scalars['String']['output'];
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  targetDataSource: DataTableConnectionSource;
};

export type DataTableConnectionCriterion = {
  __typename: 'DataTableConnectionCriterion';
  id: Scalars['ID']['output'];
  ignoreOnBlankTarget: Scalars['Boolean']['output'];
  sourceField: Scalars['String']['output'];
  targetField: Scalars['String']['output'];
  type: DataTableConnectionCriterionType;
};

export type DataTableConnectionCriterionInput = {
  id: Scalars['ID']['input'];
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type DataTableConnectionCriterionType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionCriterionTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionInput = {
  criteria: Array<DataTableConnectionCriterionInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  targetDataSource: DataTableConnectionSourceInput;
};

export type DataTableConnectionSource = {
  __typename: 'DataTableConnectionSource';
  identifier: Scalars['String']['output'];
  type: DataTableConnectionSourceType;
};

export type DataTableConnectionSourceInput = {
  identifier: Scalars['String']['input'];
  type: DataTableConnectionSourceTypeInput;
};

export type DataTableConnectionSourceType =
  | 'DATA_TABLE';

export type DataTableConnectionSourceTypeInput =
  | 'DATA_TABLE';

export type DataTableExport = {
  __typename: 'DataTableExport';
  exportDate: Scalars['String']['output'];
  exportFormat: ExportFormats;
  id: Scalars['ID']['output'];
};

export type DataTableRow = {
  __typename: 'DataTableRow';
  createdAt: Scalars['String']['output'];
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
};

export type DataTableRowWithLabel = {
  __typename: 'DataTableRowWithLabel';
  dataJsonWithLabel: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DateParserOperationAdapter = {
  __typename: 'DateParserOperationAdapter';
  dateFormat: Scalars['String']['output'];
};

export type DeleteDataColumnRuleSetsInput = {
  dataColumnRuleSetIds: Array<Scalars['String']['input']>;
  isDeletingDataColumn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteDataColumnRuleSetsResponse = {
  __typename: 'DeleteDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type DeleteDataColumnRulesResponse = {
  __typename: 'DeleteDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataColumnsInput = {
  dataColumnIds: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type DeleteDataColumnsResponse = {
  __typename: 'DeleteDataColumnsResponse';
  dataTable: DataTable;
  notificationId: Scalars['String']['output'];
};

export type DeleteDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type DeleteDataPipelineOperationInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataPipelineOperationResponse = {
  __typename: 'DeleteDataPipelineOperationResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataPipelineResponse = {
  __typename: 'DeleteDataPipelineResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRepositoryCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataRepositoryCollectionResponse = {
  __typename: 'DeleteDataRepositoryCollectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataRepositoryInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataRepositoryResponse = {
  __typename: 'DeleteDataRepositoryResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRowInput = {
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId: Scalars['String']['input'];
};

export type DeleteDataRowResponse = {
  __typename: 'DeleteDataRowResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableCollectionResponse = {
  __typename: 'DeleteDataTableCollectionResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableConnectionInput = {
  dataTableConnectionId: Scalars['String']['input'];
};

export type DeleteDataTableConnectionResponse = {
  __typename: 'DeleteDataTableConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataTableInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableResponse = {
  __typename: 'DeleteDataTableResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteFilesV2Input = {
  fileIds: Array<Scalars['String']['input']>;
};

export type DeleteFilesV2Response = {
  __typename: 'DeleteFilesV2Response';
  count: Scalars['Int']['output'];
};

export type DeleteGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteGroupResponse = {
  __typename: 'DeleteGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteImportConfigurationInput = {
  id: Scalars['String']['input'];
};

export type DeleteImportConfigurationResponse = {
  __typename: 'DeleteImportConfigurationResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteImportsInput = {
  dataRepositoryId: Scalars['String']['input'];
  importIDs: Array<Scalars['String']['input']>;
};

export type DeleteImportsResponse = {
  __typename: 'DeleteImportsResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteInputOutputConnectionInput = {
  inputOutputConnectionId: Scalars['String']['input'];
};

export type DeleteInputOutputConnectionResponse = {
  __typename: 'DeleteInputOutputConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteInvitesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteInvitesResponse = {
  __typename: 'DeleteInvitesResponse';
  ids: Array<Scalars['ID']['output']>;
};

export type DeletePipelineFilterCombinationInput = {
  id: Scalars['String']['input'];
};

export type DeletePipelineFilterCombinationResponse = {
  __typename: 'DeletePipelineFilterCombinationResponse';
  dataPipeline: DataPipeline;
};

export type DeletePipelineFilterInput = {
  id: Scalars['String']['input'];
};

export type DeletePipelineFilterResponse = {
  __typename: 'DeletePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type DeleteRepositoryRowDeleteQueryInput = {
  id: Scalars['String']['input'];
};

export type DeleteRepositoryRowDeleteQueryResponse = {
  __typename: 'DeleteRepositoryRowDeleteQueryResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteRowsByDeleteQueryInput = {
  deleteQueryId: Scalars['String']['input'];
};

export type DeleteRowsByDeleteQueryResponse = {
  __typename: 'DeleteRowsByDeleteQueryResponse';
  notificationId: Scalars['String']['output'];
};

export type DeleteTagValueByValueInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type DeleteTagValueByValueResponse = {
  __typename: 'DeleteTagValueByValueResponse';
  tag: TagConfiguration;
};

export type DeleteUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
};

export type DeleteUniqueIdentifierConfigurationResponse = {
  __typename: 'DeleteUniqueIdentifierConfigurationResponse';
  deletedId: Scalars['String']['output'];
};

export type DelimiterType =
  | 'COMMA'
  | 'SEMICOLON'
  | 'TAB';

export type DelimiterTypeInput =
  | 'COMMA'
  | 'SEMICOLON'
  | 'TAB';

export type EditRolesInput = {
  id: Scalars['ID']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditRolesResponse = {
  __typename: 'EditRolesResponse';
  result: Invite;
};

export type ElasticFieldItem = {
  __typename: 'ElasticFieldItem';
  elasticIndex: Scalars['String']['output'];
  field: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  globalTypeSubKey?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
  labelFieldType?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  maxDateForRelativeFilters?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type ElasticFieldWithOptionalRelationalFieldInput = {
  field: Scalars['String']['input'];
  relationalLabelField?: InputMaybe<Scalars['String']['input']>;
  relationalLabelType?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type EncodingType =
  | 'ISO88591'
  | 'UTF';

export type EncodingTypeInput =
  | 'ISO88591'
  | 'UTF';

export type EnsureValidSupplierUploadStateInput = {
  checkOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnsureValidSupplierUploadStateResponse = {
  __typename: 'EnsureValidSupplierUploadStateResponse';
  isValid: Scalars['Boolean']['output'];
};

export type EnumElement = {
  __typename: 'EnumElement';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type EscapeCharacterType =
  | 'BACKSLASH'
  | 'DOUBLE_QUOTE'
  | 'VERTICAL_BAR';

export type EscapeCharacterTypeInput =
  | 'BACKSLASH'
  | 'DOUBLE_QUOTE'
  | 'VERTICAL_BAR';

export type ExportDataTableToFileInput = {
  dataTableId: Scalars['String']['input'];
  exportFormat: ExportFormatsInput;
  fields: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  notificationTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDataTableToFileResponse = {
  __typename: 'ExportDataTableToFileResponse';
  notificationId: Scalars['String']['output'];
};

export type ExportFormats =
  | 'CSV'
  | 'XLSX';

export type ExportFormatsInput =
  | 'CSV'
  | 'XLSX';

export type FieldSamples = {
  __typename: 'FieldSamples';
  fieldKey: Scalars['String']['output'];
  samples: Array<Scalars['String']['output']>;
};

export type FieldWithFilterType = {
  __typename: 'FieldWithFilterType';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
};

export type FieldWithLevelInput = {
  field: Scalars['String']['input'];
  level: Scalars['Int']['input'];
};

export type FileDetailsV2Input = {
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  fileType: Scalars['String']['input'];
};

export type FileInfo = {
  __typename: 'FileInfo';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FileUploadStatusV2 = {
  __typename: 'FileUploadStatusV2';
  id: Scalars['ID']['output'];
  uploadStatus: UploadStatusType;
};

export type FileUploadStatusV2Input = {
  id: Scalars['ID']['input'];
  uploadStatus: UploadStatusTypeInput;
};

export type FileUrl = {
  __typename: 'FileUrl';
  fileId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FileV2 = {
  __typename: 'FileV2';
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  uploadStatus: UploadStatusType;
};

export type FillBlanksOperationAdapter = {
  __typename: 'FillBlanksOperationAdapter';
  blankValue?: Maybe<Scalars['String']['output']>;
  withColumn: Scalars['Boolean']['output'];
};

export type Filter = {
  __typename: 'Filter';
  field: Scalars['String']['output'];
  filterType: RuleFilterType;
  labelValue?: Maybe<Scalars['String']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterDataType =
  | 'DATE'
  | 'NUMBER'
  | 'TEXT';

export type FilterDataTypeInput =
  | 'DATE'
  | 'NUMBER'
  | 'TEXT';

export type FilterInput = {
  field: Scalars['String']['input'];
  filterType: RuleFilterTypeInput;
  labelValue?: InputMaybe<Scalars['String']['input']>;
  numericValue?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FilterOperatorType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'NOT_EQUAL';

export type FilterOperatorTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'NOT_EQUAL';

export type FindBlankCellsInColumnInput = {
  columnIndex: Scalars['Int']['input'];
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type FindBlankCellsInColumnResponse = {
  __typename: 'FindBlankCellsInColumnResponse';
  dummyOutput: Scalars['String']['output'];
};

export type FindDuplicatesInFileInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  uniqueColumnIndicies: Array<Scalars['Int']['input']>;
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type FindDuplicatesInFileResponse = {
  __typename: 'FindDuplicatesInFileResponse';
  dummyOutput: Scalars['String']['output'];
};

export type GetAllDataColumnRuleSetsInput = {
  targetDataColumnId: Scalars['String']['input'];
};

export type GetAllDataColumnRuleSetsResponse = {
  __typename: 'GetAllDataColumnRuleSetsResponse';
  dataColumnRuleSets: Array<DataColumnRuleSet>;
};

export type GetAllDataColumnsResponse = {
  __typename: 'GetAllDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetAllDataPipelineExecutionsInput = {
  dataPipelineId: Scalars['String']['input'];
  dryRunsOnly: Scalars['Boolean']['input'];
};

export type GetAllDataPipelineExecutionsResponse = {
  __typename: 'GetAllDataPipelineExecutionsResponse';
  dataPipelineExecutions: Array<DataPipelineExecution>;
};

export type GetAllDataPipelinesResponse = {
  __typename: 'GetAllDataPipelinesResponse';
  dataPipelines: Array<DataPipeline>;
};

export type GetAllDataRepositoriesResponse = {
  __typename: 'GetAllDataRepositoriesResponse';
  dataRepositories: Array<DataRepository>;
};

export type GetAllDataRepositoryCollectionsResponse = {
  __typename: 'GetAllDataRepositoryCollectionsResponse';
  dataRepositoryCollections: Array<DataRepositoryCollection>;
};

export type GetAllDataTableCollectionsResponse = {
  __typename: 'GetAllDataTableCollectionsResponse';
  dataTableCollections: Array<DataTableCollection>;
};

export type GetAllDataTableConnectionsResponse = {
  __typename: 'GetAllDataTableConnectionsResponse';
  dataTableConnections: Array<DataTableConnection>;
};

export type GetAllElasticFieldsResponse = {
  __typename: 'GetAllElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetAllGroupStructuresResponse = {
  __typename: 'GetAllGroupStructuresResponse';
  groupStructures: Array<GroupStructure>;
};

export type GetAllImportConfigurationsResponse = {
  __typename: 'GetAllImportConfigurationsResponse';
  importConfigurations: Array<ImportConfiguration>;
};

export type GetAllImportsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetAllImportsByDataTableResponse = {
  __typename: 'GetAllImportsByDataTableResponse';
  imports: Array<Import>;
};

export type GetAllPendingChangesInput = {
  includeAffectedColumns?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetAllPendingChangesResponse = {
  __typename: 'GetAllPendingChangesResponse';
  pendingChanges: Array<PendingChange>;
};

export type GetAllRunningRulesResponse = {
  __typename: 'GetAllRunningRulesResponse';
  runningRules: Array<RunningRules>;
};

export type GetCompleteStructureByPredefinedTypeInput = {
  predefinedType: GroupStructurePredefinedTypeInput;
};

export type GetCompleteStructureByPredefinedTypeResponse = {
  __typename: 'GetCompleteStructureByPredefinedTypeResponse';
  groups: Array<GroupNode>;
};

export type GetCredentialStateResponse = {
  __typename: 'GetCredentialStateResponse';
  passwordSet: Scalars['Boolean']['output'];
  providers: Array<Scalars['String']['output']>;
  requireSso: Scalars['Boolean']['output'];
};

export type GetDataColumnDependenciesInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDataColumnDependenciesResponse = {
  __typename: 'GetDataColumnDependenciesResponse';
  hasDeps: Scalars['Boolean']['output'];
  messageToUser: Scalars['String']['output'];
};

export type GetDataColumnElasticFieldMapInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnElasticFieldMapResponse = {
  __typename: 'GetDataColumnElasticFieldMapResponse';
  dataColumnElasticFieldMaps: Array<DataColumnElasticFieldMap>;
};

export type GetDataColumnRulesByRuleSetInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataColumnRulesByRuleSetInput;
};

export type GetDataColumnRulesByRuleSetResponse = {
  __typename: 'GetDataColumnRulesByRuleSetResponse';
  count: Scalars['Int']['output'];
  dataColumnRules: Array<DataColumnRule>;
};

export type GetDataColumnsInput = {
  columnSelection?: InputMaybe<DataColumnSelectionInput>;
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnsResponse = {
  __typename: 'GetDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDataCubeConfigurationInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataCubeConfigurationResponse = {
  __typename: 'GetDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type GetDataPipelineInput = {
  id: Scalars['String']['input'];
};

export type GetDataPipelineLayoutInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataPipelineLayoutResponse = {
  __typename: 'GetDataPipelineLayoutResponse';
  dataPipelineLayout: DataPipelineLayout;
};

export type GetDataPipelineOperationInput = {
  id: Scalars['String']['input'];
};

export type GetDataPipelineOperationResponse = {
  __typename: 'GetDataPipelineOperationResponse';
  operation: DataPipelineOperation;
};

export type GetDataPipelineOperationsInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type GetDataPipelineOperationsResponse = {
  __typename: 'GetDataPipelineOperationsResponse';
  dataPipelineOperations: Array<DataPipelineOperation>;
};

export type GetDataPipelineResponse = {
  __typename: 'GetDataPipelineResponse';
  dataPipeline: DataPipeline;
};

export type GetDataRepositoryInput = {
  id: Scalars['String']['input'];
};

export type GetDataRepositoryResponse = {
  __typename: 'GetDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type GetDataRepositoryRowCountInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetDataRepositoryRowCountResponse = {
  __typename: 'GetDataRepositoryRowCountResponse';
  rowCount: Scalars['Int']['output'];
};

export type GetDataRepositoryRowsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataRepositoryRowsInput;
};

export type GetDataRepositoryRowsResponse = {
  __typename: 'GetDataRepositoryRowsResponse';
  rows: Array<DataRepositoryRow>;
};

export type GetDataSourcesByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataSourcesByDataTableResponse = {
  __typename: 'GetDataSourcesByDataTableResponse';
  enrichmentSources: Array<DataRepository>;
  mainSources: Array<DataRepository>;
};

export type GetDataTableByGlobalTypeInput = {
  globalTypeKey: Scalars['String']['input'];
};

export type GetDataTableByGlobalTypeResponse = {
  __typename: 'GetDataTableByGlobalTypeResponse';
  dataTable?: Maybe<DataTable>;
};

export type GetDataTableElasticFieldsInput = {
  dataTableId: Scalars['String']['input'];
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeLabelFields?: InputMaybe<Scalars['Boolean']['input']>;
  withEnrichments: Scalars['Boolean']['input'];
};

export type GetDataTableElasticFieldsResponse = {
  __typename: 'GetDataTableElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetDataTableFieldLabelsInput = {
  dataTableId: Scalars['String']['input'];
  includePostfix: Scalars['Boolean']['input'];
};

export type GetDataTableFieldLabelsResponse = {
  __typename: 'GetDataTableFieldLabelsResponse';
  fieldLabelsJson: Scalars['String']['output'];
};

export type GetDataTableInput = {
  id: Scalars['String']['input'];
};

export type GetDataTableResponse = {
  __typename: 'GetDataTableResponse';
  entity: DataTable;
};

export type GetDataTableRowCountInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableRowCountResponse = {
  __typename: 'GetDataTableRowCountResponse';
  count: Scalars['Int']['output'];
};

export type GetDataTableRowInput = {
  dataTableId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type GetDataTableRowResponse = {
  __typename: 'GetDataTableRowResponse';
  dataTableRow: DataTableRow;
};

export type GetDataTableRowsInput = {
  dataTableId: Scalars['String']['input'];
  rowIds: Array<Scalars['String']['input']>;
};

export type GetDataTableRowsResponse = {
  __typename: 'GetDataTableRowsResponse';
  rows: Array<DataTableRow>;
};

export type GetDataTableRowsSearchInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataTableRowsSearchInput;
};

export type GetDataTableRowsSearchResponse = {
  __typename: 'GetDataTableRowsSearchResponse';
  dataTableRows: Array<DataTableRow>;
  total: Scalars['Int']['output'];
};

export type GetDataTableValidationSummaryInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableValidationSummaryResponse = {
  __typename: 'GetDataTableValidationSummaryResponse';
  cubeConfigurationCount: Scalars['Int']['output'];
  indexFieldCount: Scalars['Int']['output'];
};

export type GetDependantColumnsInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDependantColumnsResponse = {
  __typename: 'GetDependantColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDependentRulesByGroupIdInput = {
  groupId: Scalars['String']['input'];
};

export type GetDependentRulesByGroupIdResponse = {
  __typename: 'GetDependentRulesByGroupIdResponse';
  dependentRules: Array<DataColumnRule>;
};

export type GetExportDownloadUrlsInput = {
  exportId: Scalars['String']['input'];
};

export type GetExportDownloadUrlsResponse = {
  __typename: 'GetExportDownloadUrlsResponse';
  downloadUrls: Array<Scalars['String']['output']>;
};

export type GetExportsByTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetExportsByTableIdResponse = {
  __typename: 'GetExportsByTableIdResponse';
  exports: Array<DataTableExport>;
};

export type GetFilesByIdsV2Input = {
  fileIds: Array<Scalars['String']['input']>;
};

export type GetFilesByIdsV2Response = {
  __typename: 'GetFilesByIdsV2Response';
  files: Array<FileV2>;
};

export type GetGlobalRolesResponse = {
  __typename: 'GetGlobalRolesResponse';
  result: Array<Role>;
};

export type GetGroupHasChildrenInput = {
  groupId: Scalars['String']['input'];
};

export type GetGroupHasChildrenResponse = {
  __typename: 'GetGroupHasChildrenResponse';
  hasChildren: Scalars['Boolean']['output'];
};

export type GetGroupInput = {
  id: Scalars['String']['input'];
};

export type GetGroupPredictionInput = {
  groupStructureId: Scalars['String']['input'];
  keyValuePairs: Array<KeyValuePairInput>;
};

export type GetGroupPredictionResponse = {
  __typename: 'GetGroupPredictionResponse';
  group: Group;
};

export type GetGroupPredictionsInput = {
  groupStructureId: Scalars['String']['input'];
  predictionInputs: Array<GroupPredictionInputInput>;
};

export type GetGroupPredictionsResponse = {
  __typename: 'GetGroupPredictionsResponse';
  predictions: Array<GroupPredictionOutput>;
};

export type GetGroupResponse = {
  __typename: 'GetGroupResponse';
  group: Group;
};

export type GetGroupStructureInput = {
  id: Scalars['String']['input'];
};

export type GetGroupStructureResponse = {
  __typename: 'GetGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type GetGroupStructuresInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetGroupStructuresResponse = {
  __typename: 'GetGroupStructuresResponse';
  dataColumnGroupStructures: Array<DataColumnGroupStructure>;
};

export type GetGroupsByGroupStructureInput = {
  groupStructureId: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetGroupsByGroupStructureResponse = {
  __typename: 'GetGroupsByGroupStructureResponse';
  groups: Array<GroupWithHasChildren>;
};

export type GetGroupsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetGroupsResponse = {
  __typename: 'GetGroupsResponse';
  groups: Array<Group>;
};

export type GetImportConfigurationInput = {
  id: Scalars['String']['input'];
};

export type GetImportConfigurationResponse = {
  __typename: 'GetImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
};

export type GetImportsByDataRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetImportsByDataRepositoryResponse = {
  __typename: 'GetImportsByDataRepositoryResponse';
  imports: Array<Import>;
};

export type GetImportsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GetImportsResponse = {
  __typename: 'GetImportsResponse';
  imports: Array<Import>;
};

export type GetInputOutputConnectionsInput = {
  dataPipelineIds: Array<Scalars['String']['input']>;
};

export type GetInputOutputConnectionsResponse = {
  __typename: 'GetInputOutputConnectionsResponse';
  inputOutputConnections: Array<InputOutputConnection>;
};

export type GetInvitesInput = {
  _cacheKey?: InputMaybe<Scalars['String']['input']>;
};

export type GetInvitesResponse = {
  __typename: 'GetInvitesResponse';
  result: Array<Invite>;
};

export type GetLinkedProvidersResponse = {
  __typename: 'GetLinkedProvidersResponse';
  providers: Array<Scalars['String']['output']>;
};

export type GetManyAggregatedViewsResponse = {
  __typename: 'GetManyAggregatedViewsResponse';
  entities: Array<AggregatedView>;
};

export type GetManyDataTablesResponse = {
  __typename: 'GetManyDataTablesResponse';
  entities: Array<DataTable>;
};

export type GetManyUniqueIdentifierConfigurationsResponse = {
  __typename: 'GetManyUniqueIdentifierConfigurationsResponse';
  entities: Array<UniqueIdentifierConfiguration>;
};

export type GetOperationOutputUsageInput = {
  id: Scalars['String']['input'];
};

export type GetOperationOutputUsageResponse = {
  __typename: 'GetOperationOutputUsageResponse';
  dataColumnIds: Array<Scalars['String']['output']>;
  dataPipelineOperationIds: Array<Scalars['String']['output']>;
  usedInFilters: Scalars['Boolean']['output'];
};

export type GetOrCreateCriterion = {
  __typename: 'GetOrCreateCriterion';
  criterionType: CriterionType;
  dataRepositoryFieldId: Scalars['String']['output'];
};

export type GetOrCreateCriterionInput = {
  criterionType: CriterionTypeInput;
  dataRepositoryFieldId: Scalars['String']['input'];
};

export type GetOrCreateDataTableCollectionOrderResponse = {
  __typename: 'GetOrCreateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type GetOrCreateOperationAdapter = {
  __typename: 'GetOrCreateOperationAdapter';
  dataRepositoryId: Scalars['String']['output'];
  getOrCreateCriteria: Array<GetOrCreateCriterion>;
  upsert: Scalars['Boolean']['output'];
};

export type GetPaginatedConflictingRulesForUploadInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedConflictingRulesForUploadInput;
};

export type GetPaginatedConflictingRulesForUploadResponse = {
  __typename: 'GetPaginatedConflictingRulesForUploadResponse';
  conflictingRules: Array<ConflictingRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDanglingRulesInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDanglingRulesInput;
};

export type GetPaginatedDanglingRulesResponse = {
  __typename: 'GetPaginatedDanglingRulesResponse';
  rules: Array<DataColumnRule>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedDuplicateRulesInFileInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedDuplicateRulesInFileInput;
};

export type GetPaginatedDuplicateRulesInFileResponse = {
  __typename: 'GetPaginatedDuplicateRulesInFileResponse';
  duplicateRulesInFile: Array<ConflictingRuleFromFile>;
  total: Scalars['Int']['output'];
};

export type GetPaginatedImportsByDataRepositoryInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetPaginatedImportsByDataRepositoryInput;
};

export type GetPaginatedImportsByDataRepositoryResponse = {
  __typename: 'GetPaginatedImportsByDataRepositoryResponse';
  imports: Array<Import>;
  totalImports: Scalars['Int']['output'];
};

export type GetParentIdsBySearchTermInput = {
  groupStructureId: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
};

export type GetParentIdsBySearchTermResponse = {
  __typename: 'GetParentIdsBySearchTermResponse';
  ids: Array<Scalars['String']['output']>;
};

export type GetParentNamesByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentNamesByChildIdResponse = {
  __typename: 'GetParentNamesByChildIdResponse';
  names: Array<Scalars['String']['output']>;
};

export type GetParentsByChildIdInput = {
  childId: Scalars['String']['input'];
};

export type GetParentsByChildIdResponse = {
  __typename: 'GetParentsByChildIdResponse';
  groups: Array<Group>;
};

export type GetPipelineOperationExecutionRowsByBatchIdInput = {
  batchId?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['String']['input'];
  pipelineExecutionId: Scalars['String']['input'];
};

export type GetPipelineOperationExecutionRowsByBatchIdResponse = {
  __typename: 'GetPipelineOperationExecutionRowsByBatchIdResponse';
  dataPipelineExecutionRows: Array<PipelineExecutionRow>;
};

export type GetPreviewFileInput = {
  fileId: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
};

export type GetPreviewFileResponse = {
  __typename: 'GetPreviewFileResponse';
  fields: Array<ClassificationFieldSamples>;
  fileId: Scalars['String']['output'];
};

export type GetRegexSuggestionInput = {
  descriptionOfRegex: Scalars['String']['input'];
};

export type GetRegexSuggestionResponse = {
  __typename: 'GetRegexSuggestionResponse';
  response: Scalars['String']['output'];
};

export type GetRelatedDataColumnsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetRelatedDataColumnsByDataTableResponse = {
  __typename: 'GetRelatedDataColumnsByDataTableResponse';
  dataColumns: Array<DataColumn>;
};

export type GetRepositoryRowDeleteQueriesByRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type GetRepositoryRowDeleteQueriesByRepositoryResponse = {
  __typename: 'GetRepositoryRowDeleteQueriesByRepositoryResponse';
  repositoryRowDeleteQueries: Array<RepositoryRowDeleteQuery>;
};

export type GetRolesInput = {
  includeGlobal: Scalars['Boolean']['input'];
};

export type GetRolesResponse = {
  __typename: 'GetRolesResponse';
  result: Array<Role>;
};

export type GetRowsSearchWithLabelsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetRowsSearchWithLabelsInput;
};

export type GetRowsSearchWithLabelsResponse = {
  __typename: 'GetRowsSearchWithLabelsResponse';
  dataTableRowsWithLabel: Array<DataTableRowWithLabel>;
  total: Scalars['Int']['output'];
};

export type GetRuleCountPerRuleSetByDataColumnInput = {
  dataColumnId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type GetRuleCountPerRuleSetByDataColumnResponse = {
  __typename: 'GetRuleCountPerRuleSetByDataColumnResponse';
  ruleSetIdsWithRuleCounts: Array<RuleSetIdWithRuleCount>;
};

export type GetSavedCategoriesResponse = {
  __typename: 'GetSavedCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSpendCategoriesInput = {
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type GetSpendCategoriesResponse = {
  __typename: 'GetSpendCategoriesResponse';
  categories: Array<SpendCategory>;
};

export type GetSpendCategoryInput = {
  id: Scalars['String']['input'];
};

export type GetSpendCategoryResponse = {
  __typename: 'GetSpendCategoryResponse';
  category: SpendCategory;
};

export type GetSupplierFieldsByColumnResponse = {
  __typename: 'GetSupplierFieldsByColumnResponse';
  fields: Array<ConnectedFieldAndColumn>;
};

export type GetSupplierTableDataSourcesResponse = {
  __typename: 'GetSupplierTableDataSourcesResponse';
  dataPipelineIds: Array<Scalars['String']['output']>;
  dataTableId?: Maybe<Scalars['String']['output']>;
  sources: Array<DataRepository>;
};

export type GetTagValuesByColumnIdInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetTagValuesByColumnIdResponse = {
  __typename: 'GetTagValuesByColumnIdResponse';
  tagValues: Array<EnumElement>;
};

export type GetUniqueIdentifierConfigurationByRepositoryInput = {
  repositoryId: Scalars['String']['input'];
};

export type GetUniqueIdentifierConfigurationByRepositoryResponse = {
  __typename: 'GetUniqueIdentifierConfigurationByRepositoryResponse';
  uniqueIdentifierConfiguration: UniqueIdentifierConfiguration;
};

export type GetUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
};

export type GetUniqueIdentifierConfigurationResponse = {
  __typename: 'GetUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type GetUsersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  terms?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetUsersResponse = {
  __typename: 'GetUsersResponse';
  result: Array<RoleUser>;
  total: Scalars['Int']['output'];
};

export type Group = {
  __typename: 'Group';
  groupStructureId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupNode = {
  __typename: 'GroupNode';
  children: Array<GroupNode>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GroupPredictionInputInput = {
  fieldValuePair: Array<KeyValuePairInput>;
};

export type GroupPredictionOutput = {
  __typename: 'GroupPredictionOutput';
  fieldValuePairs: Array<KeyValuePair>;
  predictedGroup?: Maybe<Group>;
  predictedGroupPath: Array<Scalars['String']['output']>;
};

export type GroupStructure = {
  __typename: 'GroupStructure';
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  predefinedType?: Maybe<GroupStructurePredefinedType>;
};

export type GroupStructurePredefinedType =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupStructurePredefinedTypeInput =
  | 'DEPARTMENT'
  | 'SPEND_CATEGORY';

export type GroupWithHasChildren = {
  __typename: 'GroupWithHasChildren';
  groupStructureId: Scalars['String']['output'];
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numChildren?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type GroupingConfiguration = {
  __typename: 'GroupingConfiguration';
  dataColumnId: Scalars['String']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IdKeyPair = {
  __typename: 'IdKeyPair';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
};

export type Import = {
  __typename: 'Import';
  dataRepositoryId: Scalars['String']['output'];
  file: FileInfo;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importConfigurationId: Scalars['String']['output'];
  importInfo: ImportInfo;
  isBeingDeleted: Scalars['Boolean']['output'];
};

export type ImportConfiguration = {
  __typename: 'ImportConfiguration';
  compressionType?: Maybe<CompressionType>;
  contentType: ContentType;
  csvConfiguration?: Maybe<CsvConfiguration>;
  fieldMappings: Array<ImportFieldMapping>;
  id: Scalars['ID']['output'];
  importType: ImportType;
  name: Scalars['String']['output'];
  targetDataRepositoryId: Scalars['String']['output'];
  xlsxConfiguration?: Maybe<XlsxConfiguration>;
};

export type ImportFieldMapping = {
  __typename: 'ImportFieldMapping';
  dataRepositoryFieldId: Scalars['String']['output'];
  fileFieldKey: Scalars['String']['output'];
};

export type ImportFieldMappingInput = {
  dataRepositoryFieldId: Scalars['String']['input'];
  fileFieldKey: Scalars['String']['input'];
};

export type ImportInfo = {
  __typename: 'ImportInfo';
  alreadyExisting: Scalars['Int']['output'];
  ignoredEmpty: Scalars['Int']['output'];
  ignoredMissing: Scalars['Int']['output'];
  importedAt: Scalars['String']['output'];
  importedBy?: Maybe<Scalars['String']['output']>;
  importedRows: Scalars['Int']['output'];
  updatedRows: Scalars['Int']['output'];
};

export type ImportType =
  | 'FILE'
  | 'PUSH_ENDPOINT';

export type ImportTypeInput =
  | 'FILE'
  | 'PUSH_ENDPOINT';

export type IndexDataTableToElasticInput = {
  dataTableId: Scalars['String']['input'];
};

export type IndexDataTableToElasticResponse = {
  __typename: 'IndexDataTableToElasticResponse';
  notificationId: Scalars['String']['output'];
};

export type InputOutputConnection = {
  __typename: 'InputOutputConnection';
  dataPipelineId: Scalars['String']['output'];
  from: InputOutputNode;
  id: Scalars['ID']['output'];
  to: InputOutputNode;
};

export type InputOutputConnectionCreationInputInput = {
  from: InputOutputNodeInput;
  to: InputOutputNodeInput;
};

export type InputOutputNode = {
  __typename: 'InputOutputNode';
  referencedId: Scalars['String']['output'];
  type: InputOutputNodeType;
};

export type InputOutputNodeInput = {
  referencedId: Scalars['String']['input'];
  type: InputOutputNodeTypeInput;
};

export type InputOutputNodeType =
  | 'DATA_REPOSITORY_FIELD'
  | 'DATA_TABLE_COLUMN'
  | 'OPERATION';

export type InputOutputNodeTypeInput =
  | 'DATA_REPOSITORY_FIELD'
  | 'DATA_TABLE_COLUMN'
  | 'OPERATION';

export type Invite = {
  __typename: 'Invite';
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expiredAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitedBy: Scalars['String']['output'];
  invitedByDisplayName: Scalars['String']['output'];
  lastSentAt?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  status: InviteStatus;
};

export type InviteStatus =
  | 'COMPLETE'
  | 'EMAIL_SENT'
  | 'EXPIRED'
  | 'PROCESSING';

export type InviteUserInput = {
  email: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InviteUserResponse = {
  __typename: 'InviteUserResponse';
  invite: Invite;
};

export type InviteUsersResponse = {
  __typename: 'InviteUsersResponse';
  alreadyExists: Array<User>;
  invites: Array<Invite>;
};

export type KeyValuePair = {
  __typename: 'KeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LetterCasingOperationAdapter = {
  __typename: 'LetterCasingOperationAdapter';
  letterCasing: Scalars['String']['output'];
};

export type ListAccessRequestsResponse = {
  __typename: 'ListAccessRequestsResponse';
  data: Array<AccessRequest>;
  total: Scalars['Int']['output'];
};

export type ListParserOperationAdapter = {
  __typename: 'ListParserOperationAdapter';
  splitString: Scalars['String']['output'];
};

export type LookupCriterion = {
  __typename: 'LookupCriterion';
  dataRepositoryFieldId: Scalars['String']['output'];
  operationInputId: Scalars['String']['output'];
};

export type LookupFieldOutput = {
  __typename: 'LookupFieldOutput';
  dataRepositoryFieldId: Scalars['String']['output'];
  operationOutputId: Scalars['String']['output'];
};

export type LookupOperationAdapter = {
  __typename: 'LookupOperationAdapter';
  dataRepositoryId: Scalars['String']['output'];
  lookupCriteria: Array<LookupCriterion>;
  lookupFieldOutputs: Array<LookupFieldOutput>;
};

export type MappingElement = {
  __typename: 'MappingElement';
  elementId: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type MappingOperationAdapter = {
  __typename: 'MappingOperationAdapter';
  mappingElements: Array<MappingElement>;
};

export type MathematicalExpressionOperationAdapter = {
  __typename: 'MathematicalExpressionOperationAdapter';
  expressionJson: Scalars['String']['output'];
};

export type MergeGroupInput = {
  groupStructureId: Scalars['String']['input'];
  mergeFromGroupId: Scalars['String']['input'];
  mergeIntoGroupId: Scalars['String']['input'];
};

export type MergeGroupResponse = {
  __typename: 'MergeGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type MoveDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  skipApplyClassification?: InputMaybe<Scalars['Boolean']['input']>;
  targetRuleSetId: Scalars['String']['input'];
};

export type MoveDataColumnRulesResponse = {
  __typename: 'MoveDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type MoveGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveGroupResponse = {
  __typename: 'MoveGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addAccessRequest: AddAccessRequestResponse;
  addDataPipelineSource: AddDataPipelineSourceResponse;
  addDataRepositoryField: AddDataRepositoryFieldResponse;
  addDataTableConnectionCriterion: AddDataTableConnectionCriterionResponse;
  addImportFieldMapping: AddImportFieldMappingResponse;
  addLookupOperationCriteria: AddLookupOperationCriteriaResponse;
  addLookupOperationFieldOutputs: AddLookupOperationFieldOutputsResponse;
  addManyDataRepositoryFields: AddManyDataRepositoryFieldsResponse;
  addManyImportFieldMappings: AddManyImportFieldMappingsResponse;
  addOrRemoveFieldsFromDataCubeConfiguration: AddOrRemoveFieldsFromDataCubeConfigurationResponse;
  addTagValueToColumn: AddTagValueToColumnResponse;
  applyChanges: ApplyChangesResponse;
  applyDataTableConnectionChanges: ApplyDataTableConnectionChangesResponse;
  checkNewDataColumnRules: CheckNewDataColumnRulesResponse;
  createAggregatedDataColumn: CreateAggregatedDataColumnResponse;
  createAggregatedView: CreateAggregatedViewResponse;
  createConvertCountryDataPipelineOperation: CreateConvertCountryDataPipelineOperationResponse;
  createDataColumn: CreateDataColumnResponse;
  createDataColumnRuleSetFromGroupStructure: CreateDataColumnRuleSetFromGroupStructureResponse;
  createDataColumns: CreateDataColumnsResponse;
  createDataPipeline: CreateDataPipelineResponse;
  createDataRepository: CreateDataRepositoryResponse;
  createDataRepositoryCollection: CreateDataRepositoryCollectionResponse;
  createDataRow: CreateDataRowResponse;
  createDataTable: CreateDataTableResponse;
  createDataTableCollection: CreateDataTableCollectionResponse;
  createDataTableConnection: CreateDataTableConnectionResponse;
  createDateParserDataPipelineOperation: CreateDateParserDataPipelineOperationResponse;
  createDownloadUrlsV2: CreateDownloadUrlsV2Response;
  createFillBlanksDataPipelineOperation: CreateFillBlanksDataPipelineOperationResponse;
  createGetOrCreateDataPipelineOperation: CreateGetOrCreateDataPipelineOperationResponse;
  createGroup: CreateGroupResponse;
  createGroupStructure: CreateGroupStructureResponse;
  createGroupStructureFromUploadFile: CreateGroupStructureFromUploadFileResponse;
  createImportConfiguration: CreateImportConfigurationResponse;
  createInputOutputConnection: CreateInputOutputConnectionResponse;
  createKeywordTaggerDataPipelineOperation: CreateKeywordTaggerDataPipelineOperationResponse;
  createLetterCasingDataPipelineOperation: CreateLetterCasingDataPipelineOperationResponse;
  createListParserDataPipelineOperation: CreateListParserDataPipelineOperationResponse;
  createLookupDataPipelineOperation: CreateLookupDataPipelineOperationResponse;
  createManyInputOutputConnections: CreateManyInputOutputConnectionsResponse;
  createMappingDataPipelineOperation: CreateMappingDataPipelineOperationResponse;
  createMatchCompanyDataPipelineOperation: CreateMatchCompanyDataPipelineOperationResponse;
  createMathematicalExpressionDataPipelineOperation: CreateMathematicalExpressionDataPipelineOperationResponse;
  createMonetaryAmountDataPipelineOperation: CreateMonetaryAmountDataPipelineOperationResponse;
  createPipelineFilter: CreatePipelineFilterResponse;
  createPipelineFilterCombination: CreatePipelineFilterCombinationResponse;
  createRegexDataPipelineOperation: CreateRegexDataPipelineOperationResponse;
  createReplaceDataPipelineOperation: CreateReplaceDataPipelineOperationResponse;
  createRepositoryRowDeleteQuery: CreateRepositoryRowDeleteQueryResponse;
  createRules: CreateRulesResponse;
  createRulesV2: CreateRulesV2Response;
  createSplitDataPipelineOperation: CreateSplitDataPipelineOperationResponse;
  createTagColumn: CreateTagColumnResponse;
  createTemplateStringDataPipelineOperation: CreateTemplateStringDataPipelineOperationResponse;
  createTranslationDataPipelineOperation: CreateTranslationDataPipelineOperationResponse;
  createUniqueIdentifierConfiguration: CreateUniqueIdentifierConfigurationResponse;
  createUploadUrlsV2: CreateUploadUrlsV2Response;
  deleteAccessRequest: AccessRequest;
  deleteDataColumnRuleSets: DeleteDataColumnRuleSetsResponse;
  deleteDataColumnRules: DeleteDataColumnRulesResponse;
  deleteDataColumns: DeleteDataColumnsResponse;
  deleteDataPipeline: DeleteDataPipelineResponse;
  deleteDataPipelineOperation: DeleteDataPipelineOperationResponse;
  deleteDataRepository: DeleteDataRepositoryResponse;
  deleteDataRepositoryCollection: DeleteDataRepositoryCollectionResponse;
  deleteDataRow: DeleteDataRowResponse;
  deleteDataTable: DeleteDataTableResponse;
  deleteDataTableCollection: DeleteDataTableCollectionResponse;
  deleteDataTableConnection: DeleteDataTableConnectionResponse;
  deleteFilesV2: DeleteFilesV2Response;
  deleteGroup: DeleteGroupResponse;
  deleteImportConfiguration: DeleteImportConfigurationResponse;
  deleteImports: DeleteImportsResponse;
  deleteInputOutputConnection: DeleteInputOutputConnectionResponse;
  deleteInvites: DeleteInvitesResponse;
  deletePipelineFilter: DeletePipelineFilterResponse;
  deletePipelineFilterCombination: DeletePipelineFilterCombinationResponse;
  deleteRepositoryRowDeleteQuery: DeleteRepositoryRowDeleteQueryResponse;
  deleteRowsByDeleteQuery: DeleteRowsByDeleteQueryResponse;
  deleteTagValueByValue: DeleteTagValueByValueResponse;
  deleteUniqueIdentifierConfiguration: DeleteUniqueIdentifierConfigurationResponse;
  editRoles: EditRolesResponse;
  ensureValidSupplierUploadState: EnsureValidSupplierUploadStateResponse;
  exportDataTableToFile: ExportDataTableToFileResponse;
  findBlankCellsInColumn: FindBlankCellsInColumnResponse;
  findDuplicatesInFile: FindDuplicatesInFileResponse;
  grantSupportAccess: SupportConsent;
  indexDataTableToElastic: IndexDataTableToElasticResponse;
  inviteUser: InviteUserResponse;
  inviteUsers: InviteUsersResponse;
  mergeGroup: MergeGroupResponse;
  moveDataColumnRules: MoveDataColumnRulesResponse;
  moveGroup: MoveGroupResponse;
  optimizeDataTable: OptimizeDataTableResponse;
  parseFile: ParseFileResponse;
  parseFileSamples: ParseFileSamplesResponse;
  reapplyDataColumnRules: ReapplyDataColumnRulesResponse;
  reapplyDataTableConnections: ReapplyDataTableConnectionsResponse;
  removeDataPipelineSource: RemoveDataPipelineSourceResponse;
  removeDataTableConnectionCriterion: RemoveDataTableConnectionCriterionResponse;
  removeGhostRows: RemoveGhostRowsResponse;
  removeLookupOperationCriteria: RemoveLookupOperationCriteriaResponse;
  removeLookupOperationFieldOutputs: RemoveLookupOperationFieldOutputsResponse;
  removeUsers: RemoveUsersResponse;
  reorderDataColumnRuleSets: ReorderDataColumnRuleSetsResponse;
  replaceDataColumnWithNewDataType: ReplaceDataColumnWithNewDataTypeResponse;
  resendInvites: ResendInvitesResponse;
  revokeAccessRequest: AccessRequest;
  revokeSupportAccess: SupportConsent;
  saveCategory: SaveCategoryResponse;
  setActiveTenant: SetActiveTenantResponse;
  setUserRoles: SetUserRolesResponse;
  startAggregatedView: StartAggregatedViewResponse;
  startAutofillGroupStructure: StartAutofillGroupStructureResponse;
  startDataPipelinesByDataTableId: StartDataPipelinesByDataTableIdResponse;
  startDefaultSetup: StartDefaultSetupResponse;
  startFileImportByFileId: StartFileImportByFileIdResponse;
  startFindConflictingRulesForUpload: StartFindConflictingRulesForUploadResponse;
  startFindDanglingRulesTask: StartFindDanglingRulesTaskResponse;
  startPartialDataPipeline: StartPartialDataPipelineResponse;
  startSqlImport: StartSqlImportResponse;
  startTestDataPipeline: StartTestDataPipelineResponse;
  testDataTableConnection: TestDataTableConnectionResponse;
  unsaveCategory: UnsaveCategoryResponse;
  updateAggregatedView: UpdateAggregatedViewResponse;
  updateDataColumn: UpdateDataColumnResponse;
  updateDataColumnRuleSet: UpdateDataColumnRuleSetResponse;
  updateDataColumnRules: UpdateDataColumnRulesResponse;
  updateDataPipelineOperation: UpdateDataPipelineOperationResponse;
  updateDataRepository: UpdateDataRepositoryResponse;
  updateDataRepositoryCollection: UpdateDataRepositoryCollectionResponse;
  updateDataTable: UpdateDataTableResponse;
  updateDataTableCollection: UpdateDataTableCollectionResponse;
  updateDataTableCollectionOrder: UpdateDataTableCollectionOrderResponse;
  updateDataTableRow: UpdateDataTableRowResponse;
  updateFilesUploadStatusV2: UpdateFilesUploadStatusV2Response;
  updateGroup: UpdateGroupResponse;
  updateGroupStructure: UpdateGroupStructureResponse;
  updateImportConfiguration: UpdateImportConfigurationResponse;
  updateMappingOperationElement: UpdateMappingOperationElementResponse;
  updateOrCreateDataPipelineLayout: UpdateOrCreateDataPipelineLayoutResponse;
  updatePipelineFilter: UpdatePipelineFilterResponse;
  updateUniqueIdentifierConfiguration: UpdateUniqueIdentifierConfigurationResponse;
  uploadRules: UploadRulesResponse;
  validateFile: ValidateFileResponse;
};


export type MutationAddAccessRequestArgs = {
  input: AddAccessRequestInput;
};


export type MutationAddDataPipelineSourceArgs = {
  input: AddDataPipelineSourceInput;
};


export type MutationAddDataRepositoryFieldArgs = {
  input: AddDataRepositoryFieldInput;
};


export type MutationAddDataTableConnectionCriterionArgs = {
  input: AddDataTableConnectionCriterionInput;
};


export type MutationAddImportFieldMappingArgs = {
  input: AddImportFieldMappingInput;
};


export type MutationAddLookupOperationCriteriaArgs = {
  input: AddLookupOperationCriteriaInput;
};


export type MutationAddLookupOperationFieldOutputsArgs = {
  input: AddLookupOperationFieldOutputsInput;
};


export type MutationAddManyDataRepositoryFieldsArgs = {
  input: AddManyDataRepositoryFieldsInput;
};


export type MutationAddManyImportFieldMappingsArgs = {
  input: AddManyImportFieldMappingsInput;
};


export type MutationAddOrRemoveFieldsFromDataCubeConfigurationArgs = {
  input: AddOrRemoveFieldsFromDataCubeConfigurationInput;
};


export type MutationAddTagValueToColumnArgs = {
  input: AddTagValueToColumnInput;
};


export type MutationApplyChangesArgs = {
  input: ApplyChangesInput;
};


export type MutationApplyDataTableConnectionChangesArgs = {
  input: ApplyDataTableConnectionChangesInput;
};


export type MutationCheckNewDataColumnRulesArgs = {
  input: CheckNewDataColumnRulesInput;
};


export type MutationCreateAggregatedDataColumnArgs = {
  input: CreateAggregatedDataColumnInput;
};


export type MutationCreateAggregatedViewArgs = {
  input: CreateAggregatedViewInput;
};


export type MutationCreateConvertCountryDataPipelineOperationArgs = {
  input: CreateConvertCountryDataPipelineOperationInput;
};


export type MutationCreateDataColumnArgs = {
  input: CreateDataColumnInput;
};


export type MutationCreateDataColumnRuleSetFromGroupStructureArgs = {
  input: CreateDataColumnRuleSetFromGroupStructureInput;
};


export type MutationCreateDataColumnsArgs = {
  input: CreateDataColumnsInput;
};


export type MutationCreateDataPipelineArgs = {
  input: CreateDataPipelineInput;
};


export type MutationCreateDataRepositoryArgs = {
  input: CreateDataRepositoryInput;
};


export type MutationCreateDataRepositoryCollectionArgs = {
  input: CreateDataRepositoryCollectionInput;
};


export type MutationCreateDataRowArgs = {
  input: CreateDataRowInput;
};


export type MutationCreateDataTableArgs = {
  input: CreateDataTableInput;
};


export type MutationCreateDataTableCollectionArgs = {
  input: CreateDataTableCollectionInput;
};


export type MutationCreateDataTableConnectionArgs = {
  input: CreateDataTableConnectionInput;
};


export type MutationCreateDateParserDataPipelineOperationArgs = {
  input: CreateDateParserDataPipelineOperationInput;
};


export type MutationCreateDownloadUrlsV2Args = {
  input: CreateDownloadUrlsV2Input;
};


export type MutationCreateFillBlanksDataPipelineOperationArgs = {
  input: CreateFillBlanksDataPipelineOperationInput;
};


export type MutationCreateGetOrCreateDataPipelineOperationArgs = {
  input: CreateGetOrCreateDataPipelineOperationInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateGroupStructureArgs = {
  input: CreateGroupStructureInput;
};


export type MutationCreateGroupStructureFromUploadFileArgs = {
  input: CreateGroupStructureFromUploadFileInput;
};


export type MutationCreateImportConfigurationArgs = {
  input: CreateImportConfigurationInput;
};


export type MutationCreateInputOutputConnectionArgs = {
  input: CreateInputOutputConnectionInput;
};


export type MutationCreateKeywordTaggerDataPipelineOperationArgs = {
  input: CreateKeywordTaggerDataPipelineOperationInput;
};


export type MutationCreateLetterCasingDataPipelineOperationArgs = {
  input: CreateLetterCasingDataPipelineOperationInput;
};


export type MutationCreateListParserDataPipelineOperationArgs = {
  input: CreateListParserDataPipelineOperationInput;
};


export type MutationCreateLookupDataPipelineOperationArgs = {
  input: CreateLookupDataPipelineOperationInput;
};


export type MutationCreateManyInputOutputConnectionsArgs = {
  input: CreateManyInputOutputConnectionsInput;
};


export type MutationCreateMappingDataPipelineOperationArgs = {
  input: CreateMappingDataPipelineOperationInput;
};


export type MutationCreateMatchCompanyDataPipelineOperationArgs = {
  input: CreateMatchCompanyDataPipelineOperationInput;
};


export type MutationCreateMathematicalExpressionDataPipelineOperationArgs = {
  input: CreateMathematicalExpressionDataPipelineOperationInput;
};


export type MutationCreateMonetaryAmountDataPipelineOperationArgs = {
  input: CreateMonetaryAmountDataPipelineOperationInput;
};


export type MutationCreatePipelineFilterArgs = {
  input: CreatePipelineFilterInput;
};


export type MutationCreatePipelineFilterCombinationArgs = {
  input: CreatePipelineFilterCombinationInput;
};


export type MutationCreateRegexDataPipelineOperationArgs = {
  input: CreateRegexDataPipelineOperationInput;
};


export type MutationCreateReplaceDataPipelineOperationArgs = {
  input: CreateReplaceDataPipelineOperationInput;
};


export type MutationCreateRepositoryRowDeleteQueryArgs = {
  input: CreateRepositoryRowDeleteQueryInput;
};


export type MutationCreateRulesArgs = {
  input: CreateRulesInput;
};


export type MutationCreateRulesV2Args = {
  input: CreateRulesV2Input;
};


export type MutationCreateSplitDataPipelineOperationArgs = {
  input: CreateSplitDataPipelineOperationInput;
};


export type MutationCreateTagColumnArgs = {
  input: CreateTagColumnInput;
};


export type MutationCreateTemplateStringDataPipelineOperationArgs = {
  input: CreateTemplateStringDataPipelineOperationInput;
};


export type MutationCreateTranslationDataPipelineOperationArgs = {
  input: CreateTranslationDataPipelineOperationInput;
};


export type MutationCreateUniqueIdentifierConfigurationArgs = {
  input: CreateUniqueIdentifierConfigurationInput;
};


export type MutationCreateUploadUrlsV2Args = {
  input: CreateUploadUrlsV2Input;
};


export type MutationDeleteAccessRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDataColumnRuleSetsArgs = {
  input: DeleteDataColumnRuleSetsInput;
};


export type MutationDeleteDataColumnRulesArgs = {
  input: DeleteDataColumnRulesInput;
};


export type MutationDeleteDataColumnsArgs = {
  input: DeleteDataColumnsInput;
};


export type MutationDeleteDataPipelineArgs = {
  input: DeleteDataPipelineInput;
};


export type MutationDeleteDataPipelineOperationArgs = {
  input: DeleteDataPipelineOperationInput;
};


export type MutationDeleteDataRepositoryArgs = {
  input: DeleteDataRepositoryInput;
};


export type MutationDeleteDataRepositoryCollectionArgs = {
  input: DeleteDataRepositoryCollectionInput;
};


export type MutationDeleteDataRowArgs = {
  input: DeleteDataRowInput;
};


export type MutationDeleteDataTableArgs = {
  input: DeleteDataTableInput;
};


export type MutationDeleteDataTableCollectionArgs = {
  input: DeleteDataTableCollectionInput;
};


export type MutationDeleteDataTableConnectionArgs = {
  input: DeleteDataTableConnectionInput;
};


export type MutationDeleteFilesV2Args = {
  input: DeleteFilesV2Input;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteImportConfigurationArgs = {
  input: DeleteImportConfigurationInput;
};


export type MutationDeleteImportsArgs = {
  input: DeleteImportsInput;
};


export type MutationDeleteInputOutputConnectionArgs = {
  input: DeleteInputOutputConnectionInput;
};


export type MutationDeleteInvitesArgs = {
  input: DeleteInvitesInput;
};


export type MutationDeletePipelineFilterArgs = {
  input: DeletePipelineFilterInput;
};


export type MutationDeletePipelineFilterCombinationArgs = {
  input: DeletePipelineFilterCombinationInput;
};


export type MutationDeleteRepositoryRowDeleteQueryArgs = {
  input: DeleteRepositoryRowDeleteQueryInput;
};


export type MutationDeleteRowsByDeleteQueryArgs = {
  input: DeleteRowsByDeleteQueryInput;
};


export type MutationDeleteTagValueByValueArgs = {
  input: DeleteTagValueByValueInput;
};


export type MutationDeleteUniqueIdentifierConfigurationArgs = {
  input: DeleteUniqueIdentifierConfigurationInput;
};


export type MutationEditRolesArgs = {
  input: EditRolesInput;
};


export type MutationEnsureValidSupplierUploadStateArgs = {
  input: EnsureValidSupplierUploadStateInput;
};


export type MutationExportDataTableToFileArgs = {
  input: ExportDataTableToFileInput;
};


export type MutationFindBlankCellsInColumnArgs = {
  input: FindBlankCellsInColumnInput;
};


export type MutationFindDuplicatesInFileArgs = {
  input: FindDuplicatesInFileInput;
};


export type MutationGrantSupportAccessArgs = {
  validHours?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationIndexDataTableToElasticArgs = {
  input: IndexDataTableToElasticInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationInviteUsersArgs = {
  emails: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};


export type MutationMergeGroupArgs = {
  input: MergeGroupInput;
};


export type MutationMoveDataColumnRulesArgs = {
  input: MoveDataColumnRulesInput;
};


export type MutationMoveGroupArgs = {
  input: MoveGroupInput;
};


export type MutationOptimizeDataTableArgs = {
  input: OptimizeDataTableInput;
};


export type MutationParseFileArgs = {
  input: ParseFileInput;
};


export type MutationParseFileSamplesArgs = {
  input: ParseFileSamplesInput;
};


export type MutationReapplyDataColumnRulesArgs = {
  input: ReapplyDataColumnRulesInput;
};


export type MutationReapplyDataTableConnectionsArgs = {
  input: ReapplyDataTableConnectionsInput;
};


export type MutationRemoveDataPipelineSourceArgs = {
  input: RemoveDataPipelineSourceInput;
};


export type MutationRemoveDataTableConnectionCriterionArgs = {
  input: RemoveDataTableConnectionCriterionInput;
};


export type MutationRemoveGhostRowsArgs = {
  input: RemoveGhostRowsInput;
};


export type MutationRemoveLookupOperationCriteriaArgs = {
  input: RemoveLookupOperationCriteriaInput;
};


export type MutationRemoveLookupOperationFieldOutputsArgs = {
  input: RemoveLookupOperationFieldOutputsInput;
};


export type MutationRemoveUsersArgs = {
  input: RemoveUsersInput;
};


export type MutationReorderDataColumnRuleSetsArgs = {
  input: ReorderDataColumnRuleSetsInput;
};


export type MutationReplaceDataColumnWithNewDataTypeArgs = {
  input: ReplaceDataColumnWithNewDataTypeInput;
};


export type MutationResendInvitesArgs = {
  input: ResendInvitesInput;
};


export type MutationRevokeAccessRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationSaveCategoryArgs = {
  input: SaveCategoryInput;
};


export type MutationSetActiveTenantArgs = {
  input: SetActiveTenantInput;
};


export type MutationSetUserRolesArgs = {
  input: SetUserRolesInput;
};


export type MutationStartAggregatedViewArgs = {
  input: StartAggregatedViewInput;
};


export type MutationStartAutofillGroupStructureArgs = {
  input: StartAutofillGroupStructureInput;
};


export type MutationStartDataPipelinesByDataTableIdArgs = {
  input: StartDataPipelinesByDataTableIdInput;
};


export type MutationStartDefaultSetupArgs = {
  input: StartDefaultSetupInput;
};


export type MutationStartFileImportByFileIdArgs = {
  input: StartFileImportByFileIdInput;
};


export type MutationStartFindConflictingRulesForUploadArgs = {
  input: StartFindConflictingRulesForUploadInput;
};


export type MutationStartFindDanglingRulesTaskArgs = {
  input: StartFindDanglingRulesTaskInput;
};


export type MutationStartPartialDataPipelineArgs = {
  input: StartPartialDataPipelineInput;
};


export type MutationStartSqlImportArgs = {
  input: StartSqlImportInput;
};


export type MutationStartTestDataPipelineArgs = {
  input: StartTestDataPipelineInput;
};


export type MutationTestDataTableConnectionArgs = {
  input: TestDataTableConnectionInput;
};


export type MutationUnsaveCategoryArgs = {
  input: UnsaveCategoryInput;
};


export type MutationUpdateAggregatedViewArgs = {
  input: UpdateAggregatedViewInput;
};


export type MutationUpdateDataColumnArgs = {
  input: UpdateDataColumnInput;
};


export type MutationUpdateDataColumnRuleSetArgs = {
  input: UpdateDataColumnRuleSetInput;
};


export type MutationUpdateDataColumnRulesArgs = {
  input: UpdateDataColumnRulesInput;
};


export type MutationUpdateDataPipelineOperationArgs = {
  input: UpdateDataPipelineOperationInput;
};


export type MutationUpdateDataRepositoryArgs = {
  input: UpdateDataRepositoryInput;
};


export type MutationUpdateDataRepositoryCollectionArgs = {
  input: UpdateDataRepositoryCollectionInput;
};


export type MutationUpdateDataTableArgs = {
  input: UpdateDataTableInput;
};


export type MutationUpdateDataTableCollectionArgs = {
  input: UpdateDataTableCollectionInput;
};


export type MutationUpdateDataTableCollectionOrderArgs = {
  input: UpdateDataTableCollectionOrderInput;
};


export type MutationUpdateDataTableRowArgs = {
  input: UpdateDataTableRowInput;
};


export type MutationUpdateFilesUploadStatusV2Args = {
  input: UpdateFilesUploadStatusV2Input;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateGroupStructureArgs = {
  input: UpdateGroupStructureInput;
};


export type MutationUpdateImportConfigurationArgs = {
  input: UpdateImportConfigurationInput;
};


export type MutationUpdateMappingOperationElementArgs = {
  input: UpdateMappingOperationElementInput;
};


export type MutationUpdateOrCreateDataPipelineLayoutArgs = {
  input: UpdateOrCreateDataPipelineLayoutInput;
};


export type MutationUpdatePipelineFilterArgs = {
  input: UpdatePipelineFilterInput;
};


export type MutationUpdateUniqueIdentifierConfigurationArgs = {
  input: UpdateUniqueIdentifierConfigurationInput;
};


export type MutationUploadRulesArgs = {
  input: UploadRulesInput;
};


export type MutationValidateFileArgs = {
  input: ValidateFileInput;
};

export type Namespace = {
  __typename: 'Namespace';
  name: Scalars['String']['output'];
  permissions: Array<PermissionTuple>;
};

export type OperationAdapterFieldValueTupleInput = {
  field: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OperationType =
  | 'CONVERT_COUNTRY'
  | 'DATE_PARSER'
  | 'FILL_BLANKS'
  | 'GET_OR_CREATE'
  | 'KEYWORD_TAGGER'
  | 'LETTER_CASING'
  | 'LIST_PARSER'
  | 'LOOKUP'
  | 'MAPPING'
  | 'MATCH_COMPANY'
  | 'MATHEMATICAL_EXPRESSION'
  | 'MONETARY_AMOUNT'
  | 'REGEX'
  | 'REPLACE'
  | 'SPLIT'
  | 'TEMPLATE_STRING'
  | 'TRANSLATION';

export type OperationTypeInput =
  | 'CONVERT_COUNTRY'
  | 'DATE_PARSER'
  | 'FILL_BLANKS'
  | 'GET_OR_CREATE'
  | 'KEYWORD_TAGGER'
  | 'LETTER_CASING'
  | 'LIST_PARSER'
  | 'LOOKUP'
  | 'MAPPING'
  | 'MATCH_COMPANY'
  | 'MATHEMATICAL_EXPRESSION'
  | 'MONETARY_AMOUNT'
  | 'REGEX'
  | 'REPLACE'
  | 'SPLIT'
  | 'TEMPLATE_STRING'
  | 'TRANSLATION';

export type OptimizeDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type OptimizeDataTableResponse = {
  __typename: 'OptimizeDataTableResponse';
  notificationId: Scalars['String']['output'];
};

export type OverwriteExistingRulesInput =
  | 'CONTINUE_WITH_CONFLICTS'
  | 'KEEP_EXISTING'
  | 'OVERWRITE_EXISTING';

export type PaginatedGetDataColumnRulesByRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  igniteFiltersJSON?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetDataRepositoryRowsInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type PaginatedGetDataTableRowsSearchInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type PaginatedGetPaginatedConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDanglingRulesInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedDuplicateRulesInFileInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
};

export type PaginatedGetPaginatedImportsByDataRepositoryInput = {
  dataRepositoryId: Scalars['String']['input'];
};

export type PaginatedGetRowsSearchWithLabelsInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type ParseFileInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  fileId: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type ParseFileResponse = {
  __typename: 'ParseFileResponse';
  fields: Array<FieldSamples>;
};

export type ParseFileSamplesInput = {
  fileId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
};

export type ParseFileSamplesResponse = {
  __typename: 'ParseFileSamplesResponse';
  errors: Array<Scalars['String']['output']>;
  fields: Array<FieldSamples>;
  fileId: Scalars['String']['output'];
  importConfiguration: ImportConfiguration;
  warnings: Array<Scalars['String']['output']>;
};

export type PendingChange = {
  __typename: 'PendingChange';
  createdAt: Scalars['String']['output'];
  dataColumnId?: Maybe<Scalars['String']['output']>;
  dataPipelineId: Scalars['String']['output'];
  from?: Maybe<InputOutputNode>;
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
  isBeingProcessed: Scalars['Boolean']['output'];
  pipelineOperationId?: Maybe<Scalars['String']['output']>;
  to?: Maybe<InputOutputNode>;
  type: PendingChangeType;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PendingChangeType =
  | 'IMPORT_CHANGE'
  | 'INPUT_OUTPUT_CONNECTION_CHANGE'
  | 'NEW_DATA_PIPELINE'
  | 'OPERATION_CHANGE'
  | 'PIPELINE_FILTER_CHANGE';

export type PeriodGranularity =
  | 'DAY'
  | 'MONTH'
  | 'WEEK'
  | 'YEAR';

export type PeriodGranularityInput =
  | 'DAY'
  | 'MONTH'
  | 'WEEK'
  | 'YEAR';

export type PermissionTuple = {
  __typename: 'PermissionTuple';
  object: Scalars['String']['output'];
  relation: Scalars['String']['output'];
};

export type PipelineExecutionRow = {
  __typename: 'PipelineExecutionRow';
  dataRepositoryFieldsJson: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operationInputsJson: Scalars['String']['output'];
  outputResultsJson: Scalars['String']['output'];
};

export type Query = {
  __typename: 'Query';
  getAllDataColumnRuleSets: GetAllDataColumnRuleSetsResponse;
  getAllDataColumns: GetAllDataColumnsResponse;
  getAllDataPipelineExecutions: GetAllDataPipelineExecutionsResponse;
  getAllDataPipelines: GetAllDataPipelinesResponse;
  getAllDataRepositories: GetAllDataRepositoriesResponse;
  getAllDataRepositoryCollections: GetAllDataRepositoryCollectionsResponse;
  getAllDataTableCollections: GetAllDataTableCollectionsResponse;
  getAllDataTableConnections: GetAllDataTableConnectionsResponse;
  getAllElasticFields: GetAllElasticFieldsResponse;
  getAllGroupStructures: GetAllGroupStructuresResponse;
  getAllImportConfigurations: GetAllImportConfigurationsResponse;
  getAllImportsByDataTable: GetAllImportsByDataTableResponse;
  getAllPendingChanges: GetAllPendingChangesResponse;
  getAllRunningRules: GetAllRunningRulesResponse;
  getCompleteStructureByPredefinedType: GetCompleteStructureByPredefinedTypeResponse;
  getCredentialState: GetCredentialStateResponse;
  getDataColumnDependencies: GetDataColumnDependenciesResponse;
  getDataColumnElasticFieldMap: GetDataColumnElasticFieldMapResponse;
  getDataColumnRulesByRuleSet: GetDataColumnRulesByRuleSetResponse;
  getDataColumns: GetDataColumnsResponse;
  getDataCubeConfiguration: GetDataCubeConfigurationResponse;
  getDataPipeline: GetDataPipelineResponse;
  getDataPipelineLayout: GetDataPipelineLayoutResponse;
  getDataPipelineOperation: GetDataPipelineOperationResponse;
  getDataPipelineOperations: GetDataPipelineOperationsResponse;
  getDataRepository: GetDataRepositoryResponse;
  getDataRepositoryRowCount: GetDataRepositoryRowCountResponse;
  getDataRepositoryRows: GetDataRepositoryRowsResponse;
  getDataSourcesByDataTable: GetDataSourcesByDataTableResponse;
  getDataTable: GetDataTableResponse;
  getDataTableByGlobalType: GetDataTableByGlobalTypeResponse;
  getDataTableElasticFields: GetDataTableElasticFieldsResponse;
  getDataTableFieldLabels: GetDataTableFieldLabelsResponse;
  getDataTableRow: GetDataTableRowResponse;
  getDataTableRowCount: GetDataTableRowCountResponse;
  getDataTableRows: GetDataTableRowsResponse;
  getDataTableRowsSearch: GetDataTableRowsSearchResponse;
  getDataTableValidationSummary: GetDataTableValidationSummaryResponse;
  getDependantColumns: GetDependantColumnsResponse;
  getDependentRulesByGroupId: GetDependentRulesByGroupIdResponse;
  getExportDownloadUrls: GetExportDownloadUrlsResponse;
  getExportsByTableId: GetExportsByTableIdResponse;
  getFilesByIdsV2: GetFilesByIdsV2Response;
  getGlobalRoles: GetGlobalRolesResponse;
  getGroup: GetGroupResponse;
  getGroupHasChildren: GetGroupHasChildrenResponse;
  getGroupPrediction: GetGroupPredictionResponse;
  getGroupPredictions: GetGroupPredictionsResponse;
  getGroupStructure: GetGroupStructureResponse;
  getGroupStructures: GetGroupStructuresResponse;
  getGroups: GetGroupsResponse;
  getGroupsByGroupStructure: GetGroupsByGroupStructureResponse;
  getImportConfiguration: GetImportConfigurationResponse;
  getImports: GetImportsResponse;
  getImportsByDataRepository: GetImportsByDataRepositoryResponse;
  getInputOutputConnections: GetInputOutputConnectionsResponse;
  getInvites: GetInvitesResponse;
  getLinkedProviders: GetLinkedProvidersResponse;
  getManyAggregatedViews: GetManyAggregatedViewsResponse;
  getManyDataTables: GetManyDataTablesResponse;
  getManyUniqueIdentifierConfigurations: GetManyUniqueIdentifierConfigurationsResponse;
  getOperationOutputUsage: GetOperationOutputUsageResponse;
  getOrCreateDataTableCollectionOrder: GetOrCreateDataTableCollectionOrderResponse;
  getPaginatedConflictingRulesForUpload: GetPaginatedConflictingRulesForUploadResponse;
  getPaginatedDanglingRules: GetPaginatedDanglingRulesResponse;
  getPaginatedDuplicateRulesInFile: GetPaginatedDuplicateRulesInFileResponse;
  getPaginatedImportsByDataRepository: GetPaginatedImportsByDataRepositoryResponse;
  getParentIdsBySearchTerm: GetParentIdsBySearchTermResponse;
  getParentNamesByChildId: GetParentNamesByChildIdResponse;
  getParentsByChildId: GetParentsByChildIdResponse;
  getPipelineOperationExecutionRowsByBatchId: GetPipelineOperationExecutionRowsByBatchIdResponse;
  getPreviewFile: GetPreviewFileResponse;
  getRegexSuggestion: GetRegexSuggestionResponse;
  getRelatedDataColumnsByDataTable: GetRelatedDataColumnsByDataTableResponse;
  getRepositoryRowDeleteQueriesByRepository: GetRepositoryRowDeleteQueriesByRepositoryResponse;
  getRoles: GetRolesResponse;
  getRowsSearchWithLabels: GetRowsSearchWithLabelsResponse;
  getRuleCountPerRuleSetByDataColumn: GetRuleCountPerRuleSetByDataColumnResponse;
  getSavedCategories: GetSavedCategoriesResponse;
  getSpendCategories: GetSpendCategoriesResponse;
  getSpendCategory: GetSpendCategoryResponse;
  getSupplierFieldsByColumn: GetSupplierFieldsByColumnResponse;
  getSupplierTableDataSources: GetSupplierTableDataSourcesResponse;
  getTagValuesByColumnId: GetTagValuesByColumnIdResponse;
  getUniqueIdentifierConfiguration: GetUniqueIdentifierConfigurationResponse;
  getUniqueIdentifierConfigurationByRepository: GetUniqueIdentifierConfigurationByRepositoryResponse;
  getUsers: GetUsersResponse;
  listAccessRequests: ListAccessRequestsResponse;
  listConsents: Array<SupportConsent>;
};


export type QueryGetAllDataColumnRuleSetsArgs = {
  input: GetAllDataColumnRuleSetsInput;
};


export type QueryGetAllDataPipelineExecutionsArgs = {
  input: GetAllDataPipelineExecutionsInput;
};


export type QueryGetAllImportsByDataTableArgs = {
  input: GetAllImportsByDataTableInput;
};


export type QueryGetAllPendingChangesArgs = {
  input: GetAllPendingChangesInput;
};


export type QueryGetCompleteStructureByPredefinedTypeArgs = {
  input: GetCompleteStructureByPredefinedTypeInput;
};


export type QueryGetDataColumnDependenciesArgs = {
  input: GetDataColumnDependenciesInput;
};


export type QueryGetDataColumnElasticFieldMapArgs = {
  input: GetDataColumnElasticFieldMapInput;
};


export type QueryGetDataColumnRulesByRuleSetArgs = {
  input: GetDataColumnRulesByRuleSetInput;
};


export type QueryGetDataColumnsArgs = {
  input: GetDataColumnsInput;
};


export type QueryGetDataCubeConfigurationArgs = {
  input: GetDataCubeConfigurationInput;
};


export type QueryGetDataPipelineArgs = {
  input: GetDataPipelineInput;
};


export type QueryGetDataPipelineLayoutArgs = {
  input: GetDataPipelineLayoutInput;
};


export type QueryGetDataPipelineOperationArgs = {
  input: GetDataPipelineOperationInput;
};


export type QueryGetDataPipelineOperationsArgs = {
  input: GetDataPipelineOperationsInput;
};


export type QueryGetDataRepositoryArgs = {
  input: GetDataRepositoryInput;
};


export type QueryGetDataRepositoryRowCountArgs = {
  input: GetDataRepositoryRowCountInput;
};


export type QueryGetDataRepositoryRowsArgs = {
  input: GetDataRepositoryRowsInput;
};


export type QueryGetDataSourcesByDataTableArgs = {
  input: GetDataSourcesByDataTableInput;
};


export type QueryGetDataTableArgs = {
  input: GetDataTableInput;
};


export type QueryGetDataTableByGlobalTypeArgs = {
  input: GetDataTableByGlobalTypeInput;
};


export type QueryGetDataTableElasticFieldsArgs = {
  input: GetDataTableElasticFieldsInput;
};


export type QueryGetDataTableFieldLabelsArgs = {
  input: GetDataTableFieldLabelsInput;
};


export type QueryGetDataTableRowArgs = {
  input: GetDataTableRowInput;
};


export type QueryGetDataTableRowCountArgs = {
  input: GetDataTableRowCountInput;
};


export type QueryGetDataTableRowsArgs = {
  input: GetDataTableRowsInput;
};


export type QueryGetDataTableRowsSearchArgs = {
  input: GetDataTableRowsSearchInput;
};


export type QueryGetDataTableValidationSummaryArgs = {
  input: GetDataTableValidationSummaryInput;
};


export type QueryGetDependantColumnsArgs = {
  input: GetDependantColumnsInput;
};


export type QueryGetDependentRulesByGroupIdArgs = {
  input: GetDependentRulesByGroupIdInput;
};


export type QueryGetExportDownloadUrlsArgs = {
  input: GetExportDownloadUrlsInput;
};


export type QueryGetExportsByTableIdArgs = {
  input: GetExportsByTableIdInput;
};


export type QueryGetFilesByIdsV2Args = {
  input: GetFilesByIdsV2Input;
};


export type QueryGetGroupArgs = {
  input: GetGroupInput;
};


export type QueryGetGroupHasChildrenArgs = {
  input: GetGroupHasChildrenInput;
};


export type QueryGetGroupPredictionArgs = {
  input: GetGroupPredictionInput;
};


export type QueryGetGroupPredictionsArgs = {
  input: GetGroupPredictionsInput;
};


export type QueryGetGroupStructureArgs = {
  input: GetGroupStructureInput;
};


export type QueryGetGroupStructuresArgs = {
  input: GetGroupStructuresInput;
};


export type QueryGetGroupsArgs = {
  input: GetGroupsInput;
};


export type QueryGetGroupsByGroupStructureArgs = {
  input: GetGroupsByGroupStructureInput;
};


export type QueryGetImportConfigurationArgs = {
  input: GetImportConfigurationInput;
};


export type QueryGetImportsArgs = {
  input: GetImportsInput;
};


export type QueryGetImportsByDataRepositoryArgs = {
  input: GetImportsByDataRepositoryInput;
};


export type QueryGetInputOutputConnectionsArgs = {
  input: GetInputOutputConnectionsInput;
};


export type QueryGetInvitesArgs = {
  input?: InputMaybe<GetInvitesInput>;
};


export type QueryGetOperationOutputUsageArgs = {
  input: GetOperationOutputUsageInput;
};


export type QueryGetPaginatedConflictingRulesForUploadArgs = {
  input: GetPaginatedConflictingRulesForUploadInput;
};


export type QueryGetPaginatedDanglingRulesArgs = {
  input: GetPaginatedDanglingRulesInput;
};


export type QueryGetPaginatedDuplicateRulesInFileArgs = {
  input: GetPaginatedDuplicateRulesInFileInput;
};


export type QueryGetPaginatedImportsByDataRepositoryArgs = {
  input: GetPaginatedImportsByDataRepositoryInput;
};


export type QueryGetParentIdsBySearchTermArgs = {
  input: GetParentIdsBySearchTermInput;
};


export type QueryGetParentNamesByChildIdArgs = {
  input: GetParentNamesByChildIdInput;
};


export type QueryGetParentsByChildIdArgs = {
  input: GetParentsByChildIdInput;
};


export type QueryGetPipelineOperationExecutionRowsByBatchIdArgs = {
  input: GetPipelineOperationExecutionRowsByBatchIdInput;
};


export type QueryGetPreviewFileArgs = {
  input: GetPreviewFileInput;
};


export type QueryGetRegexSuggestionArgs = {
  input: GetRegexSuggestionInput;
};


export type QueryGetRelatedDataColumnsByDataTableArgs = {
  input: GetRelatedDataColumnsByDataTableInput;
};


export type QueryGetRepositoryRowDeleteQueriesByRepositoryArgs = {
  input: GetRepositoryRowDeleteQueriesByRepositoryInput;
};


export type QueryGetRolesArgs = {
  input: GetRolesInput;
};


export type QueryGetRowsSearchWithLabelsArgs = {
  input: GetRowsSearchWithLabelsInput;
};


export type QueryGetRuleCountPerRuleSetByDataColumnArgs = {
  input: GetRuleCountPerRuleSetByDataColumnInput;
};


export type QueryGetSpendCategoriesArgs = {
  input: GetSpendCategoriesInput;
};


export type QueryGetSpendCategoryArgs = {
  input: GetSpendCategoryInput;
};


export type QueryGetTagValuesByColumnIdArgs = {
  input: GetTagValuesByColumnIdInput;
};


export type QueryGetUniqueIdentifierConfigurationArgs = {
  input: GetUniqueIdentifierConfigurationInput;
};


export type QueryGetUniqueIdentifierConfigurationByRepositoryArgs = {
  input: GetUniqueIdentifierConfigurationByRepositoryInput;
};


export type QueryGetUsersArgs = {
  input?: InputMaybe<GetUsersInput>;
};


export type QueryListAccessRequestsArgs = {
  _cacheKey?: InputMaybe<Scalars['String']['input']>;
  grantedAfter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  revokedAfter?: InputMaybe<Scalars['String']['input']>;
};

export type QueryType =
  | 'FIELD_VALUE'
  | 'RELATIVE_DATE';

export type QueryTypeInput =
  | 'FIELD_VALUE'
  | 'RELATIVE_DATE';

export type QuoteCharacterType =
  | 'DISABLED_QUOTING'
  | 'DOUBLE_QUOTE_QUOTING'
  | 'SINGLE_QUOTE_QUOTING';

export type QuoteCharacterTypeInput =
  | 'DISABLED_QUOTING'
  | 'DOUBLE_QUOTE_QUOTING'
  | 'SINGLE_QUOTE_QUOTING';

export type ReapplyDataColumnRulesInput = {
  targetDataColumnId: Scalars['String']['input'];
  whatGroupStructures?: InputMaybe<Scalars['String']['input']>;
  whatRows?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyDataColumnRulesResponse = {
  __typename: 'ReapplyDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type ReapplyDataTableConnectionsInput = {
  dataColumns: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type ReapplyDataTableConnectionsResponse = {
  __typename: 'ReapplyDataTableConnectionsResponse';
  notificationId: Scalars['String']['output'];
};

export type RegexOperationAdapter = {
  __typename: 'RegexOperationAdapter';
  regex: Scalars['String']['output'];
};

export type RelativeDateSetting =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RelativeDateSettingInput =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RemoveDataPipelineSourceInput = {
  dataPipelineId: Scalars['String']['input'];
  dataRepositoryId: Scalars['String']['input'];
};

export type RemoveDataPipelineSourceResponse = {
  __typename: 'RemoveDataPipelineSourceResponse';
  dataPipeline: DataPipeline;
};

export type RemoveDataTableConnectionCriterionInput = {
  dataTableConnectionCriterionId: Scalars['String']['input'];
  dataTableConnectionId: Scalars['String']['input'];
};

export type RemoveDataTableConnectionCriterionResponse = {
  __typename: 'RemoveDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type RemoveGhostRowsInput = {
  dataTableId: Scalars['String']['input'];
};

export type RemoveGhostRowsResponse = {
  __typename: 'RemoveGhostRowsResponse';
  notificationId: Scalars['String']['output'];
};

export type RemoveLookupOperationCriteriaInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type RemoveLookupOperationCriteriaResponse = {
  __typename: 'RemoveLookupOperationCriteriaResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type RemoveLookupOperationFieldOutputsInput = {
  dataPipelineOperationId: Scalars['String']['input'];
  dataRepositoryFieldIds: Array<Scalars['String']['input']>;
};

export type RemoveLookupOperationFieldOutputsResponse = {
  __typename: 'RemoveLookupOperationFieldOutputsResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type RemoveUsersInput = {
  userIds: Array<Scalars['String']['input']>;
};

export type RemoveUsersResponse = {
  __typename: 'RemoveUsersResponse';
  count: Scalars['Int']['output'];
  userIds: Array<Scalars['String']['output']>;
};

export type ReorderDataColumnRuleSetsInput = {
  dataColumnId: Scalars['String']['input'];
  ruleSetChanges: Array<RuleSetChangeInput>;
};

export type ReorderDataColumnRuleSetsResponse = {
  __typename: 'ReorderDataColumnRuleSetsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReplaceDataColumnWithNewDataTypeInput = {
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  newDataType: DataColumnTypeInput;
  toList: Scalars['Boolean']['input'];
};

export type ReplaceDataColumnWithNewDataTypeResponse = {
  __typename: 'ReplaceDataColumnWithNewDataTypeResponse';
  newDataColumn: DataColumn;
  notificationIds: Array<Scalars['String']['output']>;
};

export type ReplaceOperationAdapter = {
  __typename: 'ReplaceOperationAdapter';
  isRegex: Scalars['Boolean']['output'];
  replaceString: Scalars['String']['output'];
  withString: Scalars['String']['output'];
};

export type RepositoryRowDeleteQuery = {
  __typename: 'RepositoryRowDeleteQuery';
  dataRepositoryFieldId: Scalars['String']['output'];
  dataRepositoryId: Scalars['String']['output'];
  dateFormat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  numberOfPeriods?: Maybe<Scalars['Int']['output']>;
  periodGranularity?: Maybe<PeriodGranularity>;
  queryType: QueryType;
  value?: Maybe<Scalars['String']['output']>;
};

export type ResendInvitesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type ResendInvitesResponse = {
  __typename: 'ResendInvitesResponse';
  ids: Array<Scalars['ID']['output']>;
};

export type Role = {
  __typename: 'Role';
  name: Scalars['String']['output'];
  namespaces: Array<Namespace>;
};

export type RoleUser = {
  __typename: 'RoleUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  lastActivity: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  scimmed: Scalars['Boolean']['output'];
};

export type RuleFilterType =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleFilterTypeInput =
  | 'CONTAINS'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'MIGRATION_RULE'
  | 'STARTS_WITH';

export type RuleSetChangeInput = {
  newPrecedence: Scalars['Int']['input'];
  ruleSetId: Scalars['String']['input'];
};

export type RuleSetIdWithRuleCount = {
  __typename: 'RuleSetIdWithRuleCount';
  ruleCount: Scalars['Int']['output'];
  ruleSetId: Scalars['String']['output'];
};

export type RunningRules = {
  __typename: 'RunningRules';
  dataColumnId: Scalars['String']['output'];
  selection: Array<Filter>;
};

export type SaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type SaveCategoryResponse = {
  __typename: 'SaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type SetActiveTenantInput = {
  tenant: Scalars['String']['input'];
};

export type SetActiveTenantResponse = {
  __typename: 'SetActiveTenantResponse';
  oldTenant: Scalars['String']['output'];
  tenant: Scalars['String']['output'];
};

export type SetUserRolesInput = {
  roleNames: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type SetUserRolesResponse = {
  __typename: 'SetUserRolesResponse';
  roleNames: Array<Scalars['String']['output']>;
};

export type SortDirectionsInput =
  | 'asc'
  | 'desc';

export type SortingArrayInput = {
  dataColumnId: Scalars['String']['input'];
  order: SortDirectionsInput;
};

export type SpecificationInput =
  | 'ACCENTURE';

export type SpendCategory = {
  __typename: 'SpendCategory';
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numChildren?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type SplitOperationAdapter = {
  __typename: 'SplitOperationAdapter';
  splitString: Scalars['String']['output'];
};

export type SqlDialectInput =
  | 'mssql'
  | 'mysql';

export type StartAggregatedViewInput = {
  id: Scalars['String']['input'];
};

export type StartAggregatedViewResponse = {
  __typename: 'StartAggregatedViewResponse';
  notificationId: Scalars['String']['output'];
};

export type StartAutofillGroupStructureInput = {
  dataTableId: Scalars['String']['input'];
  fields: Array<AutofillGroupStructureFieldInputInput>;
  groupStructureId: Scalars['String']['input'];
};

export type StartAutofillGroupStructureResponse = {
  __typename: 'StartAutofillGroupStructureResponse';
  notificationId: Scalars['String']['output'];
};

export type StartDataPipelinesByDataTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type StartDataPipelinesByDataTableIdResponse = {
  __typename: 'StartDataPipelinesByDataTableIdResponse';
  notificationIds: Array<Scalars['String']['output']>;
};

export type StartDefaultSetupInput = {
  relate: Scalars['Boolean']['input'];
  specification: SpecificationInput;
};

export type StartDefaultSetupResponse = {
  __typename: 'StartDefaultSetupResponse';
  success: Scalars['Boolean']['output'];
};

export type StartFileImportByFileIdInput = {
  deleteQueryId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
  importedBy?: InputMaybe<Scalars['String']['input']>;
};

export type StartFileImportByFileIdResponse = {
  __typename: 'StartFileImportByFileIdResponse';
  notificationId: Scalars['String']['output'];
};

export type StartFindConflictingRulesForUploadInput = {
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindConflictingRulesForUploadResponse = {
  __typename: 'StartFindConflictingRulesForUploadResponse';
  conflictingRulesStatus: AsyncDataStatus;
  duplicateRulesStatus: AsyncDataStatus;
};

export type StartFindDanglingRulesTaskInput = {
  dataTableId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type StartFindDanglingRulesTaskResponse = {
  __typename: 'StartFindDanglingRulesTaskResponse';
  status: AsyncDataStatus;
};

export type StartPartialDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
  includedDataColumnIds: Array<Scalars['String']['input']>;
};

export type StartPartialDataPipelineResponse = {
  __typename: 'StartPartialDataPipelineResponse';
  notificationId?: Maybe<Scalars['String']['output']>;
};

export type StartSqlImportInput = {
  dataRepositoryId: Scalars['String']['input'];
  database: Scalars['String']['input'];
  dialect: SqlDialectInput;
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type StartSqlImportResponse = {
  __typename: 'StartSqlImportResponse';
  dataRepositoryId: Scalars['String']['output'];
};

export type StartTestDataPipelineInput = {
  dataPipelineId: Scalars['String']['input'];
};

export type StartTestDataPipelineResponse = {
  __typename: 'StartTestDataPipelineResponse';
  dummyOutput: Scalars['String']['output'];
};

export type SupportConsent = {
  __typename: 'SupportConsent';
  approvedAt: Scalars['String']['output'];
  approvedBy: User;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
};

export type TagConfiguration = {
  __typename: 'TagConfiguration';
  enumValues: Array<EnumElement>;
  id: Scalars['ID']['output'];
};

export type TemplateStringOperationAdapter = {
  __typename: 'TemplateStringOperationAdapter';
  templateString: Scalars['String']['output'];
};

export type TestDataTableConnectionInput = {
  dataTableConnection: DataTableConnectionInput;
  filtersJSON: Scalars['String']['input'];
};

export type TestDataTableConnectionResponse = {
  __typename: 'TestDataTableConnectionResponse';
  baseTableRowsWithMatch: Scalars['Int']['output'];
  matchesJSON: Scalars['String']['output'];
  numberOfTargetRowsTested: Scalars['Int']['output'];
  targetRowsWithNoMatch: Scalars['Int']['output'];
};

export type UniqueIdentifierConfiguration = {
  __typename: 'UniqueIdentifierConfiguration';
  id: Scalars['ID']['output'];
  partialReplace: Scalars['Boolean']['output'];
  repositoryId: Scalars['String']['output'];
  retroactive: Scalars['Boolean']['output'];
  uniqueIdentifierFields: Array<Scalars['String']['output']>;
};

export type UnsaveCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type UnsaveCategoryResponse = {
  __typename: 'UnsaveCategoryResponse';
  ack: Scalars['Boolean']['output'];
};

export type UpdatableAggregatedViewFieldsInput = {
  aggregationsToAdd: Array<CreateAggregationInput>;
  aggregationsToDelete: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFieldsToAdd: Array<Scalars['String']['input']>;
  groupByFieldsToDelete: Array<Scalars['String']['input']>;
};

export type UpdatableDataTableFieldsInput = {
  dataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateSetting?: InputMaybe<RelativeDateSettingInput>;
};

export type UpdatableUniqueIdentifierConfigurationFieldsInput = {
  uniqueIdentifierFields: Array<Scalars['String']['input']>;
};

export type UpdateAggregatedViewInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableAggregatedViewFieldsInput;
};

export type UpdateAggregatedViewResponse = {
  __typename: 'UpdateAggregatedViewResponse';
  entity: AggregatedView;
};

export type UpdateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType?: InputMaybe<DataColumnTypeInput>;
  externalSourceKey?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnResponse = {
  __typename: 'UpdateDataColumnResponse';
  dataColumn: DataColumn;
};

export type UpdateDataColumnRuleSetInput = {
  dataColumnRuleSetId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnRuleSetResponse = {
  __typename: 'UpdateDataColumnRuleSetResponse';
  dataColumnRuleSet: DataColumnRuleSet;
};

export type UpdateDataColumnRulesInput = {
  dataColumnRuleIds: Array<Scalars['String']['input']>;
  newGroupId: Scalars['String']['input'];
  targetDataColumnId: Scalars['String']['input'];
};

export type UpdateDataColumnRulesResponse = {
  __typename: 'UpdateDataColumnRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateDataPipelineOperationInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  operationSpecific: Array<OperationAdapterFieldValueTupleInput>;
  operationType: OperationTypeInput;
};

export type UpdateDataPipelineOperationResponse = {
  __typename: 'UpdateDataPipelineOperationResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type UpdateDataRepositoryCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataRepositoryCollectionResponse = {
  __typename: 'UpdateDataRepositoryCollectionResponse';
  dataRepositoryCollection: DataRepositoryCollection;
};

export type UpdateDataRepositoryInput = {
  autoTriggerPipelines: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  repositoryCollectionId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataRepositoryResponse = {
  __typename: 'UpdateDataRepositoryResponse';
  dataRepository: DataRepository;
};

export type UpdateDataTableCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataTableCollectionOrderInput = {
  collectionOrder: Array<DataTableCollectionOrderItemInput>;
};

export type UpdateDataTableCollectionOrderResponse = {
  __typename: 'UpdateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type UpdateDataTableCollectionResponse = {
  __typename: 'UpdateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type UpdateDataTableInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableDataTableFieldsInput;
};

export type UpdateDataTableResponse = {
  __typename: 'UpdateDataTableResponse';
  entity: DataTable;
};

export type UpdateDataTableRowInput = {
  data: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  rowId?: InputMaybe<Scalars['String']['input']>;
  type: ChangeOperationTypeInput;
};

export type UpdateDataTableRowResponse = {
  __typename: 'UpdateDataTableRowResponse';
  id: Scalars['String']['output'];
};

export type UpdateFilesUploadStatusV2Input = {
  files: Array<FileUploadStatusV2Input>;
};

export type UpdateFilesUploadStatusV2Response = {
  __typename: 'UpdateFilesUploadStatusV2Response';
  files: Array<FileUploadStatusV2>;
};

export type UpdateGroupInput = {
  groupStructureId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupResponse = {
  __typename: 'UpdateGroupResponse';
  notificationId: Scalars['String']['output'];
};

export type UpdateGroupStructureInput = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  predefinedType?: InputMaybe<GroupStructurePredefinedTypeInput>;
};

export type UpdateGroupStructureResponse = {
  __typename: 'UpdateGroupStructureResponse';
  groupStructure: GroupStructure;
};

export type UpdateImportConfigurationInput = {
  contentType: ContentTypeInput;
  csvConfiguration?: InputMaybe<CsvConfigurationInput>;
  id: Scalars['String']['input'];
  xlsxConfiguration?: InputMaybe<XlsxConfigurationInput>;
};

export type UpdateImportConfigurationResponse = {
  __typename: 'UpdateImportConfigurationResponse';
  importConfiguration: ImportConfiguration;
};

export type UpdateMappingOperationElementInput = {
  elementId?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['String']['input'];
  operationId: Scalars['String']['input'];
  to: Scalars['String']['input'];
  updateType: UpdateMappingOperationElementTypeInput;
};

export type UpdateMappingOperationElementResponse = {
  __typename: 'UpdateMappingOperationElementResponse';
  dataPipelineOperation: DataPipelineOperation;
};

export type UpdateMappingOperationElementTypeInput =
  | 'ADD'
  | 'EDIT'
  | 'REMOVE';

export type UpdateOrCreateDataPipelineLayoutInput = {
  dataTableId: Scalars['String']['input'];
  layoutJson: Scalars['String']['input'];
};

export type UpdateOrCreateDataPipelineLayoutResponse = {
  __typename: 'UpdateOrCreateDataPipelineLayoutResponse';
  dataPipelineLayout: DataPipelineLayout;
};

export type UpdatePipelineFilterInput = {
  filterDataType: FilterDataTypeInput;
  id: Scalars['String']['input'];
  operator: FilterOperatorTypeInput;
  sourceId: Scalars['String']['input'];
  sourceType: InputOutputNodeTypeInput;
  value: Scalars['String']['input'];
};

export type UpdatePipelineFilterResponse = {
  __typename: 'UpdatePipelineFilterResponse';
  dataPipeline: DataPipeline;
};

export type UpdateUniqueIdentifierConfigurationInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableUniqueIdentifierConfigurationFieldsInput;
};

export type UpdateUniqueIdentifierConfigurationResponse = {
  __typename: 'UpdateUniqueIdentifierConfigurationResponse';
  entity: UniqueIdentifierConfiguration;
};

export type UploadRulesInput = {
  caseSensitiveGroupMatch: Scalars['Boolean']['input'];
  classificationMode: ClassificationModeInput;
  createUnmatchedGroups: Scalars['Boolean']['input'];
  fieldCombinations: Array<ElasticFieldWithOptionalRelationalFieldInput>;
  fileId: Scalars['String']['input'];
  groupStructureId: Scalars['String']['input'];
  levelDepth: Scalars['Int']['input'];
  nonIncludedGroupLevels: Array<Scalars['String']['input']>;
  overwriteExistingRules: OverwriteExistingRulesInput;
  path?: InputMaybe<Scalars['String']['input']>;
  targetDataColumnId: Scalars['String']['input'];
};

export type UploadRulesResponse = {
  __typename: 'UploadRulesResponse';
  notificationId: Scalars['String']['output'];
};

export type UploadStatusType =
  | 'FAIL'
  | 'FAILED'
  | 'PENDING'
  | 'SUCCESS';

export type UploadStatusTypeInput =
  | 'FAIL'
  | 'FAILED'
  | 'PENDING'
  | 'SUCCESS';

export type User = {
  __typename: 'User';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type UserIdentifier = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateFileInput = {
  fileId: Scalars['String']['input'];
  importConfigurationId: Scalars['String']['input'];
};

export type ValidateFileResponse = {
  __typename: 'ValidateFileResponse';
  fileId: Scalars['String']['output'];
};

export type XlsxConfiguration = {
  __typename: 'XlsxConfiguration';
  dataStartIndex?: Maybe<Scalars['Int']['output']>;
  headerRowIndex?: Maybe<Scalars['Int']['output']>;
  numberOfEndLinesToDrop?: Maybe<Scalars['Int']['output']>;
};

export type XlsxConfigurationInput = {
  dataStartIndex?: InputMaybe<Scalars['Int']['input']>;
  headerRowIndex?: InputMaybe<Scalars['Int']['input']>;
  numberOfEndLinesToDrop?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleUserFieldsFragment = { __typename: 'RoleUser', id: string, firstName: string, lastName: string, email: string };

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;


export type GetUsersQuery = { __typename: 'Query', getUsers: { __typename: 'GetUsersResponse', result: Array<{ __typename: 'RoleUser', id: string, firstName: string, lastName: string, email: string }> } };

export type DataPipelineFilterFieldsFragment = { __typename: 'DataPipelineFilter', id: string, sourceId: string, sourceType: InputOutputNodeType, value: string, operator: FilterOperatorType, filterDataType: FilterDataType };

export type DataPipelineFilterCombinationFieldsFragment = { __typename: 'DataPipelineFilterCombination', id: string, filters: Array<{ __typename: 'DataPipelineFilter', id: string, sourceId: string, sourceType: InputOutputNodeType, value: string, operator: FilterOperatorType, filterDataType: FilterDataType }> };

export type DataPipelineFieldsFragment = { __typename: 'DataPipeline', id: string, name: string, sourceDataRepositoryIds: Array<string>, targetDataTableId: string, dataFilterCombinations: Array<{ __typename: 'DataPipelineFilterCombination', id: string, filters: Array<{ __typename: 'DataPipelineFilter', id: string, sourceId: string, sourceType: InputOutputNodeType, value: string, operator: FilterOperatorType, filterDataType: FilterDataType }> }> };

export type DataPipelineOperationInputOutputFieldsFragment = { __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null };

export type DataPipelineOperationFieldsFragment = { __typename: 'DataPipelineOperation', id: string, name: string, operationType: OperationType, dataPipelineId: string, input: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }>, output: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }> };

export type InputOutputConnectionFieldsFragment = { __typename: 'InputOutputConnection', id: string, dataPipelineId: string, from: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType }, to: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType } };

export type CreateGetOrCreateDataPipelineOperationMutationVariables = Exact<{
  input: CreateGetOrCreateDataPipelineOperationInput;
}>;


export type CreateGetOrCreateDataPipelineOperationMutation = { __typename: 'Mutation', createGetOrCreateDataPipelineOperation: { __typename: 'CreateGetOrCreateDataPipelineOperationResponse', dataPipelineOperation: { __typename: 'DataPipelineOperation', id: string, name: string, operationType: OperationType, dataPipelineId: string, input: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }>, output: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }> } } };

export type GetDataPipelineQueryVariables = Exact<{
  input: GetDataPipelineInput;
}>;


export type GetDataPipelineQuery = { __typename: 'Query', getDataPipeline: { __typename: 'GetDataPipelineResponse', dataPipeline: { __typename: 'DataPipeline', id: string, name: string, sourceDataRepositoryIds: Array<string>, targetDataTableId: string, dataFilterCombinations: Array<{ __typename: 'DataPipelineFilterCombination', id: string, filters: Array<{ __typename: 'DataPipelineFilter', id: string, sourceId: string, sourceType: InputOutputNodeType, value: string, operator: FilterOperatorType, filterDataType: FilterDataType }> }> } } };

export type CreateDataPipelineMutationVariables = Exact<{
  input: CreateDataPipelineInput;
}>;


export type CreateDataPipelineMutation = { __typename: 'Mutation', createDataPipeline: { __typename: 'CreateDataPipelineResponse', dataPipeline: { __typename: 'DataPipeline', id: string, name: string, sourceDataRepositoryIds: Array<string>, targetDataTableId: string, dataFilterCombinations: Array<{ __typename: 'DataPipelineFilterCombination', id: string, filters: Array<{ __typename: 'DataPipelineFilter', id: string, sourceId: string, sourceType: InputOutputNodeType, value: string, operator: FilterOperatorType, filterDataType: FilterDataType }> }> } } };

export type CreateInputOutputConnectionMutationVariables = Exact<{
  input: CreateInputOutputConnectionInput;
}>;


export type CreateInputOutputConnectionMutation = { __typename: 'Mutation', createInputOutputConnection: { __typename: 'CreateInputOutputConnectionResponse', inputOutputConnection: { __typename: 'InputOutputConnection', id: string, dataPipelineId: string, from: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType }, to: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType } } } };

export type GetInputOutputConnectionsQueryVariables = Exact<{
  input: GetInputOutputConnectionsInput;
}>;


export type GetInputOutputConnectionsQuery = { __typename: 'Query', getInputOutputConnections: { __typename: 'GetInputOutputConnectionsResponse', inputOutputConnections: Array<{ __typename: 'InputOutputConnection', id: string, dataPipelineId: string, from: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType }, to: { __typename: 'InputOutputNode', referencedId: string, type: InputOutputNodeType } }> } };

export type CreateMatchCompanyDataPipelineOperationMutationVariables = Exact<{
  input: CreateMatchCompanyDataPipelineOperationInput;
}>;


export type CreateMatchCompanyDataPipelineOperationMutation = { __typename: 'Mutation', createMatchCompanyDataPipelineOperation: { __typename: 'CreateMatchCompanyDataPipelineOperationResponse', dataPipelineOperation: { __typename: 'DataPipelineOperation', id: string, name: string, operationType: OperationType, dataPipelineId: string, input: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }>, output: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }> } } };

export type CreateMonetaryAmountDataPipelineOperationMutationVariables = Exact<{
  input: CreateMonetaryAmountDataPipelineOperationInput;
}>;


export type CreateMonetaryAmountDataPipelineOperationMutation = { __typename: 'Mutation', createMonetaryAmountDataPipelineOperation: { __typename: 'CreateMonetaryAmountDataPipelineOperationResponse', dataPipelineOperation: { __typename: 'DataPipelineOperation', id: string, name: string, operationType: OperationType, dataPipelineId: string, input: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }>, output: Array<{ __typename: 'DataPipelineOperationInputOutput', id: string, key?: string | null, label?: string | null }> } } };

export type DataRepositoryFieldFieldsFragment = { __typename: 'DataRepositoryField', id: string, name: string };

export type RepositoryRowDeleteQueryFieldsFragment = { __typename: 'RepositoryRowDeleteQuery', id: string, dataRepositoryId: string, dataRepositoryFieldId: string, queryType: QueryType, value?: string | null, dateFormat?: string | null, periodGranularity?: PeriodGranularity | null, numberOfPeriods?: number | null };

export type ImportFieldsFragment = { __typename: 'Import', id: string, fileName: string, importConfigurationId: string, dataRepositoryId: string, isBeingDeleted: boolean, file: { __typename: 'FileInfo', fileId: string, name: string, downloadUrl?: string | null }, importInfo: { __typename: 'ImportInfo', importedBy?: string | null, importedRows: number, updatedRows: number, ignoredMissing: number, ignoredEmpty: number, importedAt: string, alreadyExisting: number } };

export type CreateDataRepositoryMutationVariables = Exact<{
  input: CreateDataRepositoryInput;
}>;


export type CreateDataRepositoryMutation = { __typename: 'Mutation', createDataRepository: { __typename: 'CreateDataRepositoryResponse', dataRepository: { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null } } };

export type UpdateDataRepositoryMutationVariables = Exact<{
  input: UpdateDataRepositoryInput;
}>;


export type UpdateDataRepositoryMutation = { __typename: 'Mutation', updateDataRepository: { __typename: 'UpdateDataRepositoryResponse', dataRepository: { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null } } };

export type StartSqlImportMutationVariables = Exact<{
  input: StartSqlImportInput;
}>;


export type StartSqlImportMutation = { __typename: 'Mutation', startSqlImport: { __typename: 'StartSqlImportResponse', dataRepositoryId: string } };

export type CreateRepositoryRowDeleteQueryMutationVariables = Exact<{
  input: CreateRepositoryRowDeleteQueryInput;
}>;


export type CreateRepositoryRowDeleteQueryMutation = { __typename: 'Mutation', createRepositoryRowDeleteQuery: { __typename: 'CreateRepositoryRowDeleteQueryResponse', repositoryRowDeleteQuery: { __typename: 'RepositoryRowDeleteQuery', id: string, dataRepositoryId: string, dataRepositoryFieldId: string, queryType: QueryType, value?: string | null, dateFormat?: string | null, periodGranularity?: PeriodGranularity | null, numberOfPeriods?: number | null } } };

export type DeleteRepositoryRowDeleteQueryMutationVariables = Exact<{
  input: DeleteRepositoryRowDeleteQueryInput;
}>;


export type DeleteRepositoryRowDeleteQueryMutation = { __typename: 'Mutation', deleteRepositoryRowDeleteQuery: { __typename: 'DeleteRepositoryRowDeleteQueryResponse', deletedId: string } };

export type CreateDataRepositoryCollectionMutationVariables = Exact<{
  input: CreateDataRepositoryCollectionInput;
}>;


export type CreateDataRepositoryCollectionMutation = { __typename: 'Mutation', createDataRepositoryCollection: { __typename: 'CreateDataRepositoryCollectionResponse', dataRepositoryCollection: { __typename: 'DataRepositoryCollection', id: string, name: string, index?: number | null } } };

export type AddDataRepositoryFieldMutationVariables = Exact<{
  input: AddDataRepositoryFieldInput;
}>;


export type AddDataRepositoryFieldMutation = { __typename: 'Mutation', addDataRepositoryField: { __typename: 'AddDataRepositoryFieldResponse', dataRepository: { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null } } };

export type AddManyDataRepositoryFieldsMutationVariables = Exact<{
  input: AddManyDataRepositoryFieldsInput;
}>;


export type AddManyDataRepositoryFieldsMutation = { __typename: 'Mutation', addManyDataRepositoryFields: { __typename: 'AddManyDataRepositoryFieldsResponse', dataRepository: { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null } } };

export type GetDataRepositoryQueryVariables = Exact<{
  input: GetDataRepositoryInput;
}>;


export type GetDataRepositoryQuery = { __typename: 'Query', getDataRepository: { __typename: 'GetDataRepositoryResponse', dataRepository: { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null } } };

export type GetAllDataRepositoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDataRepositoriesQuery = { __typename: 'Query', getAllDataRepositories: { __typename: 'GetAllDataRepositoriesResponse', dataRepositories: Array<{ __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null }> } };

export type GetAllDataRepositoryCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDataRepositoryCollectionsQuery = { __typename: 'Query', getAllDataRepositoryCollections: { __typename: 'GetAllDataRepositoryCollectionsResponse', dataRepositoryCollections: Array<{ __typename: 'DataRepositoryCollection', id: string, name: string, index?: number | null }> } };

export type GetDataRepositoryRowsQueryVariables = Exact<{
  input: GetDataRepositoryRowsInput;
}>;


export type GetDataRepositoryRowsQuery = { __typename: 'Query', getDataRepositoryRows: { __typename: 'GetDataRepositoryRowsResponse', rows: Array<{ __typename: 'DataRepositoryRow', id: string, departmentId: string, importId: string, dataJson: string }> } };

export type GetRepositoryRowDeleteQueriesByRepositoryQueryVariables = Exact<{
  input: GetRepositoryRowDeleteQueriesByRepositoryInput;
}>;


export type GetRepositoryRowDeleteQueriesByRepositoryQuery = { __typename: 'Query', getRepositoryRowDeleteQueriesByRepository: { __typename: 'GetRepositoryRowDeleteQueriesByRepositoryResponse', repositoryRowDeleteQueries: Array<{ __typename: 'RepositoryRowDeleteQuery', id: string, dataRepositoryId: string, dataRepositoryFieldId: string, queryType: QueryType, value?: string | null, dateFormat?: string | null, periodGranularity?: PeriodGranularity | null, numberOfPeriods?: number | null }> } };

export type GetImportsByDataRepositoryQueryVariables = Exact<{
  input: GetImportsByDataRepositoryInput;
}>;


export type GetImportsByDataRepositoryQuery = { __typename: 'Query', getImportsByDataRepository: { __typename: 'GetImportsByDataRepositoryResponse', imports: Array<{ __typename: 'Import', id: string, fileName: string, importConfigurationId: string, dataRepositoryId: string, isBeingDeleted: boolean, file: { __typename: 'FileInfo', fileId: string, name: string, downloadUrl?: string | null }, importInfo: { __typename: 'ImportInfo', importedBy?: string | null, importedRows: number, updatedRows: number, ignoredMissing: number, ignoredEmpty: number, importedAt: string, alreadyExisting: number } }> } };

export type DeleteImportsMutationVariables = Exact<{
  input: DeleteImportsInput;
}>;


export type DeleteImportsMutation = { __typename: 'Mutation', deleteImports: { __typename: 'DeleteImportsResponse', notificationId: string } };

export type DeleteDataRepositoryCollectionMutationVariables = Exact<{
  input: DeleteDataRepositoryCollectionInput;
}>;


export type DeleteDataRepositoryCollectionMutation = { __typename: 'Mutation', deleteDataRepositoryCollection: { __typename: 'DeleteDataRepositoryCollectionResponse', deletedId: string } };

export type UpdateDataRepositoryCollectionMutationVariables = Exact<{
  input: UpdateDataRepositoryCollectionInput;
}>;


export type UpdateDataRepositoryCollectionMutation = { __typename: 'Mutation', updateDataRepositoryCollection: { __typename: 'UpdateDataRepositoryCollectionResponse', dataRepositoryCollection: { __typename: 'DataRepositoryCollection', id: string, name: string, index?: number | null } } };

export type DeleteDataRepositoryMutationVariables = Exact<{
  input: DeleteDataRepositoryInput;
}>;


export type DeleteDataRepositoryMutation = { __typename: 'Mutation', deleteDataRepository: { __typename: 'DeleteDataRepositoryResponse', notificationId: string } };

export type GetDataRepositoryRowCountQueryVariables = Exact<{
  input: GetDataRepositoryRowCountInput;
}>;


export type GetDataRepositoryRowCountQuery = { __typename: 'Query', getDataRepositoryRowCount: { __typename: 'GetDataRepositoryRowCountResponse', rowCount: number } };

export type DeleteRowsByDeleteQueryMutationVariables = Exact<{
  input: DeleteRowsByDeleteQueryInput;
}>;


export type DeleteRowsByDeleteQueryMutation = { __typename: 'Mutation', deleteRowsByDeleteQuery: { __typename: 'DeleteRowsByDeleteQueryResponse', notificationId: string } };

export type GetPaginatedImportsByDataRepositoryQueryVariables = Exact<{
  input: GetPaginatedImportsByDataRepositoryInput;
}>;


export type GetPaginatedImportsByDataRepositoryQuery = { __typename: 'Query', getPaginatedImportsByDataRepository: { __typename: 'GetPaginatedImportsByDataRepositoryResponse', totalImports: number, imports: Array<{ __typename: 'Import', id: string, fileName: string, importConfigurationId: string, dataRepositoryId: string, isBeingDeleted: boolean, file: { __typename: 'FileInfo', fileId: string, name: string, downloadUrl?: string | null }, importInfo: { __typename: 'ImportInfo', importedBy?: string | null, importedRows: number, updatedRows: number, ignoredMissing: number, ignoredEmpty: number, importedAt: string, alreadyExisting: number } }> } };

export type ValidateFileMutationVariables = Exact<{
  input: ValidateFileInput;
}>;


export type ValidateFileMutation = { __typename: 'Mutation', validateFile: { __typename: 'ValidateFileResponse', fileId: string } };

export type GetSupplierTableDataSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplierTableDataSourcesQuery = { __typename: 'Query', getSupplierTableDataSources: { __typename: 'GetSupplierTableDataSourcesResponse', sources: Array<{ __typename: 'DataRepository', id: string }> } };

export type CreateUploadUrlsV2MutationVariables = Exact<{
  input: CreateUploadUrlsV2Input;
}>;


export type CreateUploadUrlsV2Mutation = { __typename: 'Mutation', createUploadUrlsV2: { __typename: 'CreateUploadUrlsV2Response', urls: Array<{ __typename: 'FileUrl', fileName: string, fileId: string, url: string }> } };

export type UpdateFilesUploadStatusV2MutationVariables = Exact<{
  input: UpdateFilesUploadStatusV2Input;
}>;


export type UpdateFilesUploadStatusV2Mutation = { __typename: 'Mutation', updateFilesUploadStatusV2: { __typename: 'UpdateFilesUploadStatusV2Response', files: Array<{ __typename: 'FileUploadStatusV2', uploadStatus: UploadStatusType }> } };

export type DataTableFieldsFragment = { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null };

export type DataColumnFieldsFragment = { __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean };

export type ElasticFieldItemFieldsFragment = { __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, min?: number | null, max?: number | null };

export type GetDataTableElasticFieldsQueryVariables = Exact<{
  input: GetDataTableElasticFieldsInput;
}>;


export type GetDataTableElasticFieldsQuery = { __typename: 'Query', getDataTableElasticFields: { __typename: 'GetDataTableElasticFieldsResponse', elasticFields: Array<{ __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, min?: number | null, max?: number | null }> } };

export type GetDataTableQueryVariables = Exact<{
  input: GetDataTableInput;
}>;


export type GetDataTableQuery = { __typename: 'Query', getDataTable: { __typename: 'GetDataTableResponse', entity: { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null } } };

export type GetManyDataTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyDataTablesQuery = { __typename: 'Query', getManyDataTables: { __typename: 'GetManyDataTablesResponse', entities: Array<{ __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null }> } };

export type GetDataColumnsQueryVariables = Exact<{
  input: GetDataColumnsInput;
}>;


export type GetDataColumnsQuery = { __typename: 'Query', getDataColumns: { __typename: 'GetDataColumnsResponse', dataColumns: Array<{ __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean }> } };

export type GetDataTableRowsSearchQueryVariables = Exact<{
  input: GetDataTableRowsSearchInput;
}>;


export type GetDataTableRowsSearchQuery = { __typename: 'Query', getDataTableRowsSearch: { __typename: 'GetDataTableRowsSearchResponse', dataTableRows: Array<{ __typename: 'DataTableRow', id: string, departmentId: string, dataJson: string }> } };

export type CreateDataColumnsMutationVariables = Exact<{
  input: CreateDataColumnsInput;
}>;


export type CreateDataColumnsMutation = { __typename: 'Mutation', createDataColumns: { __typename: 'CreateDataColumnsResponse', dataColumns: Array<{ __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean }> } };

export type UpdateDataColumnMutationVariables = Exact<{
  input: UpdateDataColumnInput;
}>;


export type UpdateDataColumnMutation = { __typename: 'Mutation', updateDataColumn: { __typename: 'UpdateDataColumnResponse', dataColumn: { __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean } } };

export type DeleteDataColumnsMutationVariables = Exact<{
  input: DeleteDataColumnsInput;
}>;


export type DeleteDataColumnsMutation = { __typename: 'Mutation', deleteDataColumns: { __typename: 'DeleteDataColumnsResponse', dataTable: { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null } } };

export type CreateDataTableMutationVariables = Exact<{
  input: CreateDataTableInput;
}>;


export type CreateDataTableMutation = { __typename: 'Mutation', createDataTable: { __typename: 'CreateDataTableResponse', entity: { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null } } };

export type UpdateDataTableMutationVariables = Exact<{
  input: UpdateDataTableInput;
}>;


export type UpdateDataTableMutation = { __typename: 'Mutation', updateDataTable: { __typename: 'UpdateDataTableResponse', entity: { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, nameColumnId?: string | null, elasticIndex: string, DataTableCollectionId?: string | null } } };

export type DeleteDataTableMutationVariables = Exact<{
  input: DeleteDataTableInput;
}>;


export type DeleteDataTableMutation = { __typename: 'Mutation', deleteDataTable: { __typename: 'DeleteDataTableResponse', deletedId: string } };

export type IndexDataTableToElasticMutationVariables = Exact<{
  input: IndexDataTableToElasticInput;
}>;


export type IndexDataTableToElasticMutation = { __typename: 'Mutation', indexDataTableToElastic: { __typename: 'IndexDataTableToElasticResponse', notificationId: string } };

export type CreateDataTableCollectionMutationVariables = Exact<{
  input: CreateDataTableCollectionInput;
}>;


export type CreateDataTableCollectionMutation = { __typename: 'Mutation', createDataTableCollection: { __typename: 'CreateDataTableCollectionResponse', dataTableCollection: { __typename: 'DataTableCollection', id: string, name: string, index?: number | null } } };

export type GetAllDataColumnsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDataColumnsQuery = { __typename: 'Query', getAllDataColumns: { __typename: 'GetAllDataColumnsResponse', dataColumns: Array<{ __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean }> } };

export type DeleteDataTableCollectionMutationVariables = Exact<{
  input: DeleteDataTableCollectionInput;
}>;


export type DeleteDataTableCollectionMutation = { __typename: 'Mutation', deleteDataTableCollection: { __typename: 'DeleteDataTableCollectionResponse', deleteId: string } };

export type UpdateDataTableCollectionMutationVariables = Exact<{
  input: UpdateDataTableCollectionInput;
}>;


export type UpdateDataTableCollectionMutation = { __typename: 'Mutation', updateDataTableCollection: { __typename: 'UpdateDataTableCollectionResponse', dataTableCollection: { __typename: 'DataTableCollection', id: string, name: string, index?: number | null } } };

export type GetAllDataTableCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDataTableCollectionsQuery = { __typename: 'Query', getAllDataTableCollections: { __typename: 'GetAllDataTableCollectionsResponse', dataTableCollections: Array<{ __typename: 'DataTableCollection', id: string, name: string, index?: number | null }> } };

export type ImportFieldMappingFieldsFragment = { __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string };

export type CsvConfigurationFieldsFragment = { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null };

export type XlsxConfigurationFieldsFragment = { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null };

export type ImportConfigurationFieldsFragment = { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null };

export type FieldSampleFieldsFragment = { __typename: 'FieldSamples', fieldKey: string, samples: Array<string> };

export type CreateImportConfigurationMutationVariables = Exact<{
  input: CreateImportConfigurationInput;
}>;


export type CreateImportConfigurationMutation = { __typename: 'Mutation', createImportConfiguration: { __typename: 'CreateImportConfigurationResponse', importConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type UpdateImportConfigurationMutationVariables = Exact<{
  input: UpdateImportConfigurationInput;
}>;


export type UpdateImportConfigurationMutation = { __typename: 'Mutation', updateImportConfiguration: { __typename: 'UpdateImportConfigurationResponse', importConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type GetImportConfigurationQueryVariables = Exact<{
  input: GetImportConfigurationInput;
}>;


export type GetImportConfigurationQuery = { __typename: 'Query', getImportConfiguration: { __typename: 'GetImportConfigurationResponse', importConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type DeleteImportConfigurationMutationVariables = Exact<{
  input: DeleteImportConfigurationInput;
}>;


export type DeleteImportConfigurationMutation = { __typename: 'Mutation', deleteImportConfiguration: { __typename: 'DeleteImportConfigurationResponse', deletedId: string } };

export type GetAllImportConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllImportConfigurationsQuery = { __typename: 'Query', getAllImportConfigurations: { __typename: 'GetAllImportConfigurationsResponse', importConfigurations: Array<{ __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null }> } };

export type ParseFileSamplesMutationVariables = Exact<{
  input: ParseFileSamplesInput;
}>;


export type ParseFileSamplesMutation = { __typename: 'Mutation', parseFileSamples: { __typename: 'ParseFileSamplesResponse', fileId: string, warnings: Array<string>, errors: Array<string>, fields: Array<{ __typename: 'FieldSamples', fieldKey: string, samples: Array<string> }>, importConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type AddImportFieldMappingMutationVariables = Exact<{
  input: AddImportFieldMappingInput;
}>;


export type AddImportFieldMappingMutation = { __typename: 'Mutation', addImportFieldMapping: { __typename: 'AddImportFieldMappingResponse', updatedImportConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type AddManyImportFieldMappingsMutationVariables = Exact<{
  input: AddManyImportFieldMappingsInput;
}>;


export type AddManyImportFieldMappingsMutation = { __typename: 'Mutation', addManyImportFieldMappings: { __typename: 'AddManyImportFieldMappingsResponse', updatedImportConfiguration: { __typename: 'ImportConfiguration', id: string, name: string, targetDataRepositoryId: string, importType: ImportType, contentType: ContentType, compressionType?: CompressionType | null, fieldMappings: Array<{ __typename: 'ImportFieldMapping', fileFieldKey: string, dataRepositoryFieldId: string }>, csvConfiguration?: { __typename: 'CsvConfiguration', delimiter?: DelimiterType | null, encoding?: EncodingType | null, escapeCharacter?: EscapeCharacterType | null, quoteCharacter?: QuoteCharacterType | null } | null, xlsxConfiguration?: { __typename: 'XlsxConfiguration', headerRowIndex?: number | null, dataStartIndex?: number | null, numberOfEndLinesToDrop?: number | null } | null } } };

export type StartFileImportByFileIdMutationVariables = Exact<{
  input: StartFileImportByFileIdInput;
}>;


export type StartFileImportByFileIdMutation = { __typename: 'Mutation', startFileImportByFileId: { __typename: 'StartFileImportByFileIdResponse', notificationId: string } };

export type UniqueIdentifierConfigurationFieldsFragment = { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> };

export type DataRepositoryFieldsFragment = { __typename: 'DataRepository', id: string, name: string, repositoryCollectionId?: string | null, autoTriggerPipelines: boolean, fields: Array<{ __typename: 'DataRepositoryField', id: string, name: string }>, uniqueIdentifierConfiguration?: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } | null };

export type CreateUniqueIdentifierConfigurationMutationVariables = Exact<{
  input: CreateUniqueIdentifierConfigurationInput;
}>;


export type CreateUniqueIdentifierConfigurationMutation = { __typename: 'Mutation', createUniqueIdentifierConfiguration: { __typename: 'CreateUniqueIdentifierConfigurationResponse', entity: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } } };

export type GetUniqueIdentifierConfigurationQueryVariables = Exact<{
  input: GetUniqueIdentifierConfigurationInput;
}>;


export type GetUniqueIdentifierConfigurationQuery = { __typename: 'Query', getUniqueIdentifierConfiguration: { __typename: 'GetUniqueIdentifierConfigurationResponse', entity: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } } };

export type DeleteUniqueIdentifierConfigurationMutationVariables = Exact<{
  input: DeleteUniqueIdentifierConfigurationInput;
}>;


export type DeleteUniqueIdentifierConfigurationMutation = { __typename: 'Mutation', deleteUniqueIdentifierConfiguration: { __typename: 'DeleteUniqueIdentifierConfigurationResponse', deletedId: string } };

export type UpdateUniqueIdentifierConfigurationMutationVariables = Exact<{
  input: UpdateUniqueIdentifierConfigurationInput;
}>;


export type UpdateUniqueIdentifierConfigurationMutation = { __typename: 'Mutation', updateUniqueIdentifierConfiguration: { __typename: 'UpdateUniqueIdentifierConfigurationResponse', entity: { __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> } } };

export type GetManyUniqueIdentifierConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyUniqueIdentifierConfigurationsQuery = { __typename: 'Query', getManyUniqueIdentifierConfigurations: { __typename: 'GetManyUniqueIdentifierConfigurationsResponse', entities: Array<{ __typename: 'UniqueIdentifierConfiguration', id: string, repositoryId: string, retroactive: boolean, partialReplace: boolean, uniqueIdentifierFields: Array<string> }> } };

export const RoleUserFieldsFragmentDoc = gql`
    fragment RoleUserFields on RoleUser {
  id
  firstName
  lastName
  email
}
    `;
export const DataPipelineFilterFieldsFragmentDoc = gql`
    fragment DataPipelineFilterFields on DataPipelineFilter {
  id
  sourceId
  sourceType
  value
  operator
  filterDataType
}
    `;
export const DataPipelineFilterCombinationFieldsFragmentDoc = gql`
    fragment DataPipelineFilterCombinationFields on DataPipelineFilterCombination {
  id
  filters {
    ...DataPipelineFilterFields
  }
}
    ${DataPipelineFilterFieldsFragmentDoc}`;
export const DataPipelineFieldsFragmentDoc = gql`
    fragment DataPipelineFields on DataPipeline {
  id
  name
  sourceDataRepositoryIds
  targetDataTableId
  dataFilterCombinations {
    ...DataPipelineFilterCombinationFields
  }
}
    ${DataPipelineFilterCombinationFieldsFragmentDoc}`;
export const DataPipelineOperationInputOutputFieldsFragmentDoc = gql`
    fragment DataPipelineOperationInputOutputFields on DataPipelineOperationInputOutput {
  id
  key
  label
}
    `;
export const DataPipelineOperationFieldsFragmentDoc = gql`
    fragment DataPipelineOperationFields on DataPipelineOperation {
  id
  name
  operationType
  input {
    ...DataPipelineOperationInputOutputFields
  }
  output {
    ...DataPipelineOperationInputOutputFields
  }
  dataPipelineId
}
    ${DataPipelineOperationInputOutputFieldsFragmentDoc}`;
export const InputOutputConnectionFieldsFragmentDoc = gql`
    fragment InputOutputConnectionFields on InputOutputConnection {
  id
  from {
    referencedId
    type
  }
  to {
    referencedId
    type
  }
  dataPipelineId
}
    `;
export const RepositoryRowDeleteQueryFieldsFragmentDoc = gql`
    fragment RepositoryRowDeleteQueryFields on RepositoryRowDeleteQuery {
  id
  dataRepositoryId
  dataRepositoryFieldId
  queryType
  value
  dateFormat
  periodGranularity
  numberOfPeriods
}
    `;
export const ImportFieldsFragmentDoc = gql`
    fragment ImportFields on Import {
  id
  fileName
  importConfigurationId
  dataRepositoryId
  file {
    fileId
    name
    downloadUrl
  }
  importInfo {
    importedBy
    importedRows
    updatedRows
    ignoredMissing
    ignoredEmpty
    importedAt
    alreadyExisting
  }
  isBeingDeleted
}
    `;
export const DataTableFieldsFragmentDoc = gql`
    fragment DataTableFields on DataTable {
  id
  name
  globalTypeKey
  nameColumnId
  elasticIndex
  DataTableCollectionId
}
    `;
export const DataColumnFieldsFragmentDoc = gql`
    fragment DataColumnFields on DataColumn {
  id
  name
  dataType
  dataTableId
  globalTypeKey
  referencedId
  isList
}
    `;
export const ElasticFieldItemFieldsFragmentDoc = gql`
    fragment ElasticFieldItemFields on ElasticFieldItem {
  field
  label
  type
  globalTypeKey
  globalTypeSubKey
  labelField
  labelFieldType
  min
  max
}
    `;
export const ImportFieldMappingFieldsFragmentDoc = gql`
    fragment ImportFieldMappingFields on ImportFieldMapping {
  fileFieldKey
  dataRepositoryFieldId
}
    `;
export const CsvConfigurationFieldsFragmentDoc = gql`
    fragment CsvConfigurationFields on CsvConfiguration {
  delimiter
  encoding
  escapeCharacter
  quoteCharacter
}
    `;
export const XlsxConfigurationFieldsFragmentDoc = gql`
    fragment XlsxConfigurationFields on XlsxConfiguration {
  headerRowIndex
  dataStartIndex
  numberOfEndLinesToDrop
}
    `;
export const ImportConfigurationFieldsFragmentDoc = gql`
    fragment ImportConfigurationFields on ImportConfiguration {
  id
  name
  targetDataRepositoryId
  importType
  contentType
  fieldMappings {
    ...ImportFieldMappingFields
  }
  csvConfiguration {
    ...CsvConfigurationFields
  }
  xlsxConfiguration {
    ...XlsxConfigurationFields
  }
  compressionType
}
    ${ImportFieldMappingFieldsFragmentDoc}
${CsvConfigurationFieldsFragmentDoc}
${XlsxConfigurationFieldsFragmentDoc}`;
export const FieldSampleFieldsFragmentDoc = gql`
    fragment FieldSampleFields on FieldSamples {
  fieldKey
  samples
}
    `;
export const DataRepositoryFieldFieldsFragmentDoc = gql`
    fragment DataRepositoryFieldFields on DataRepositoryField {
  id
  name
}
    `;
export const UniqueIdentifierConfigurationFieldsFragmentDoc = gql`
    fragment UniqueIdentifierConfigurationFields on UniqueIdentifierConfiguration {
  id
  repositoryId
  retroactive
  partialReplace
  uniqueIdentifierFields
}
    `;
export const DataRepositoryFieldsFragmentDoc = gql`
    fragment DataRepositoryFields on DataRepository {
  id
  name
  fields {
    ...DataRepositoryFieldFields
  }
  repositoryCollectionId
  uniqueIdentifierConfiguration {
    ...UniqueIdentifierConfigurationFields
  }
  autoTriggerPipelines
}
    ${DataRepositoryFieldFieldsFragmentDoc}
${UniqueIdentifierConfigurationFieldsFragmentDoc}`;
export const GetUsersDocument = gql`
    query getUsers($input: GetUsersInput!) {
  getUsers(input: $input) {
    result {
      ...RoleUserFields
    }
  }
}
    ${RoleUserFieldsFragmentDoc}`;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const CreateGetOrCreateDataPipelineOperationDocument = gql`
    mutation createGetOrCreateDataPipelineOperation($input: CreateGetOrCreateDataPipelineOperationInput!) {
  createGetOrCreateDataPipelineOperation(input: $input) {
    dataPipelineOperation {
      ...DataPipelineOperationFields
    }
  }
}
    ${DataPipelineOperationFieldsFragmentDoc}`;
export type CreateGetOrCreateDataPipelineOperationMutationFn = Apollo.MutationFunction<CreateGetOrCreateDataPipelineOperationMutation, CreateGetOrCreateDataPipelineOperationMutationVariables>;
export type CreateGetOrCreateDataPipelineOperationMutationResult = Apollo.MutationResult<CreateGetOrCreateDataPipelineOperationMutation>;
export type CreateGetOrCreateDataPipelineOperationMutationOptions = Apollo.BaseMutationOptions<CreateGetOrCreateDataPipelineOperationMutation, CreateGetOrCreateDataPipelineOperationMutationVariables>;
export const GetDataPipelineDocument = gql`
    query getDataPipeline($input: GetDataPipelineInput!) {
  getDataPipeline(input: $input) {
    dataPipeline {
      ...DataPipelineFields
    }
  }
}
    ${DataPipelineFieldsFragmentDoc}`;
export type GetDataPipelineQueryResult = Apollo.QueryResult<GetDataPipelineQuery, GetDataPipelineQueryVariables>;
export const CreateDataPipelineDocument = gql`
    mutation createDataPipeline($input: CreateDataPipelineInput!) {
  createDataPipeline(input: $input) {
    dataPipeline {
      ...DataPipelineFields
    }
  }
}
    ${DataPipelineFieldsFragmentDoc}`;
export type CreateDataPipelineMutationFn = Apollo.MutationFunction<CreateDataPipelineMutation, CreateDataPipelineMutationVariables>;
export type CreateDataPipelineMutationResult = Apollo.MutationResult<CreateDataPipelineMutation>;
export type CreateDataPipelineMutationOptions = Apollo.BaseMutationOptions<CreateDataPipelineMutation, CreateDataPipelineMutationVariables>;
export const CreateInputOutputConnectionDocument = gql`
    mutation createInputOutputConnection($input: CreateInputOutputConnectionInput!) {
  createInputOutputConnection(input: $input) {
    inputOutputConnection {
      ...InputOutputConnectionFields
    }
  }
}
    ${InputOutputConnectionFieldsFragmentDoc}`;
export type CreateInputOutputConnectionMutationFn = Apollo.MutationFunction<CreateInputOutputConnectionMutation, CreateInputOutputConnectionMutationVariables>;
export type CreateInputOutputConnectionMutationResult = Apollo.MutationResult<CreateInputOutputConnectionMutation>;
export type CreateInputOutputConnectionMutationOptions = Apollo.BaseMutationOptions<CreateInputOutputConnectionMutation, CreateInputOutputConnectionMutationVariables>;
export const GetInputOutputConnectionsDocument = gql`
    query getInputOutputConnections($input: GetInputOutputConnectionsInput!) {
  getInputOutputConnections(input: $input) {
    inputOutputConnections {
      ...InputOutputConnectionFields
    }
  }
}
    ${InputOutputConnectionFieldsFragmentDoc}`;
export type GetInputOutputConnectionsQueryResult = Apollo.QueryResult<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>;
export const CreateMatchCompanyDataPipelineOperationDocument = gql`
    mutation createMatchCompanyDataPipelineOperation($input: CreateMatchCompanyDataPipelineOperationInput!) {
  createMatchCompanyDataPipelineOperation(input: $input) {
    dataPipelineOperation {
      ...DataPipelineOperationFields
    }
  }
}
    ${DataPipelineOperationFieldsFragmentDoc}`;
export type CreateMatchCompanyDataPipelineOperationMutationFn = Apollo.MutationFunction<CreateMatchCompanyDataPipelineOperationMutation, CreateMatchCompanyDataPipelineOperationMutationVariables>;
export type CreateMatchCompanyDataPipelineOperationMutationResult = Apollo.MutationResult<CreateMatchCompanyDataPipelineOperationMutation>;
export type CreateMatchCompanyDataPipelineOperationMutationOptions = Apollo.BaseMutationOptions<CreateMatchCompanyDataPipelineOperationMutation, CreateMatchCompanyDataPipelineOperationMutationVariables>;
export const CreateMonetaryAmountDataPipelineOperationDocument = gql`
    mutation createMonetaryAmountDataPipelineOperation($input: CreateMonetaryAmountDataPipelineOperationInput!) {
  createMonetaryAmountDataPipelineOperation(input: $input) {
    dataPipelineOperation {
      ...DataPipelineOperationFields
    }
  }
}
    ${DataPipelineOperationFieldsFragmentDoc}`;
export type CreateMonetaryAmountDataPipelineOperationMutationFn = Apollo.MutationFunction<CreateMonetaryAmountDataPipelineOperationMutation, CreateMonetaryAmountDataPipelineOperationMutationVariables>;
export type CreateMonetaryAmountDataPipelineOperationMutationResult = Apollo.MutationResult<CreateMonetaryAmountDataPipelineOperationMutation>;
export type CreateMonetaryAmountDataPipelineOperationMutationOptions = Apollo.BaseMutationOptions<CreateMonetaryAmountDataPipelineOperationMutation, CreateMonetaryAmountDataPipelineOperationMutationVariables>;
export const CreateDataRepositoryDocument = gql`
    mutation createDataRepository($input: CreateDataRepositoryInput!) {
  createDataRepository(input: $input) {
    dataRepository {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type CreateDataRepositoryMutationFn = Apollo.MutationFunction<CreateDataRepositoryMutation, CreateDataRepositoryMutationVariables>;
export type CreateDataRepositoryMutationResult = Apollo.MutationResult<CreateDataRepositoryMutation>;
export type CreateDataRepositoryMutationOptions = Apollo.BaseMutationOptions<CreateDataRepositoryMutation, CreateDataRepositoryMutationVariables>;
export const UpdateDataRepositoryDocument = gql`
    mutation UpdateDataRepository($input: UpdateDataRepositoryInput!) {
  updateDataRepository(input: $input) {
    dataRepository {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type UpdateDataRepositoryMutationFn = Apollo.MutationFunction<UpdateDataRepositoryMutation, UpdateDataRepositoryMutationVariables>;
export type UpdateDataRepositoryMutationResult = Apollo.MutationResult<UpdateDataRepositoryMutation>;
export type UpdateDataRepositoryMutationOptions = Apollo.BaseMutationOptions<UpdateDataRepositoryMutation, UpdateDataRepositoryMutationVariables>;
export const StartSqlImportDocument = gql`
    mutation StartSqlImport($input: StartSqlImportInput!) {
  startSqlImport(input: $input) {
    dataRepositoryId
  }
}
    `;
export type StartSqlImportMutationFn = Apollo.MutationFunction<StartSqlImportMutation, StartSqlImportMutationVariables>;
export type StartSqlImportMutationResult = Apollo.MutationResult<StartSqlImportMutation>;
export type StartSqlImportMutationOptions = Apollo.BaseMutationOptions<StartSqlImportMutation, StartSqlImportMutationVariables>;
export const CreateRepositoryRowDeleteQueryDocument = gql`
    mutation createRepositoryRowDeleteQuery($input: CreateRepositoryRowDeleteQueryInput!) {
  createRepositoryRowDeleteQuery(input: $input) {
    repositoryRowDeleteQuery {
      ...RepositoryRowDeleteQueryFields
    }
  }
}
    ${RepositoryRowDeleteQueryFieldsFragmentDoc}`;
export type CreateRepositoryRowDeleteQueryMutationFn = Apollo.MutationFunction<CreateRepositoryRowDeleteQueryMutation, CreateRepositoryRowDeleteQueryMutationVariables>;
export type CreateRepositoryRowDeleteQueryMutationResult = Apollo.MutationResult<CreateRepositoryRowDeleteQueryMutation>;
export type CreateRepositoryRowDeleteQueryMutationOptions = Apollo.BaseMutationOptions<CreateRepositoryRowDeleteQueryMutation, CreateRepositoryRowDeleteQueryMutationVariables>;
export const DeleteRepositoryRowDeleteQueryDocument = gql`
    mutation deleteRepositoryRowDeleteQuery($input: DeleteRepositoryRowDeleteQueryInput!) {
  deleteRepositoryRowDeleteQuery(input: $input) {
    deletedId
  }
}
    `;
export type DeleteRepositoryRowDeleteQueryMutationFn = Apollo.MutationFunction<DeleteRepositoryRowDeleteQueryMutation, DeleteRepositoryRowDeleteQueryMutationVariables>;
export type DeleteRepositoryRowDeleteQueryMutationResult = Apollo.MutationResult<DeleteRepositoryRowDeleteQueryMutation>;
export type DeleteRepositoryRowDeleteQueryMutationOptions = Apollo.BaseMutationOptions<DeleteRepositoryRowDeleteQueryMutation, DeleteRepositoryRowDeleteQueryMutationVariables>;
export const CreateDataRepositoryCollectionDocument = gql`
    mutation createDataRepositoryCollection($input: CreateDataRepositoryCollectionInput!) {
  createDataRepositoryCollection(input: $input) {
    dataRepositoryCollection {
      id
      name
      index
    }
  }
}
    `;
export type CreateDataRepositoryCollectionMutationFn = Apollo.MutationFunction<CreateDataRepositoryCollectionMutation, CreateDataRepositoryCollectionMutationVariables>;
export type CreateDataRepositoryCollectionMutationResult = Apollo.MutationResult<CreateDataRepositoryCollectionMutation>;
export type CreateDataRepositoryCollectionMutationOptions = Apollo.BaseMutationOptions<CreateDataRepositoryCollectionMutation, CreateDataRepositoryCollectionMutationVariables>;
export const AddDataRepositoryFieldDocument = gql`
    mutation addDataRepositoryField($input: AddDataRepositoryFieldInput!) {
  addDataRepositoryField(input: $input) {
    dataRepository {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type AddDataRepositoryFieldMutationFn = Apollo.MutationFunction<AddDataRepositoryFieldMutation, AddDataRepositoryFieldMutationVariables>;
export type AddDataRepositoryFieldMutationResult = Apollo.MutationResult<AddDataRepositoryFieldMutation>;
export type AddDataRepositoryFieldMutationOptions = Apollo.BaseMutationOptions<AddDataRepositoryFieldMutation, AddDataRepositoryFieldMutationVariables>;
export const AddManyDataRepositoryFieldsDocument = gql`
    mutation addManyDataRepositoryFields($input: AddManyDataRepositoryFieldsInput!) {
  addManyDataRepositoryFields(input: $input) {
    dataRepository {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type AddManyDataRepositoryFieldsMutationFn = Apollo.MutationFunction<AddManyDataRepositoryFieldsMutation, AddManyDataRepositoryFieldsMutationVariables>;
export type AddManyDataRepositoryFieldsMutationResult = Apollo.MutationResult<AddManyDataRepositoryFieldsMutation>;
export type AddManyDataRepositoryFieldsMutationOptions = Apollo.BaseMutationOptions<AddManyDataRepositoryFieldsMutation, AddManyDataRepositoryFieldsMutationVariables>;
export const GetDataRepositoryDocument = gql`
    query getDataRepository($input: GetDataRepositoryInput!) {
  getDataRepository(input: $input) {
    dataRepository {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type GetDataRepositoryQueryResult = Apollo.QueryResult<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>;
export const GetAllDataRepositoriesDocument = gql`
    query getAllDataRepositories {
  getAllDataRepositories {
    dataRepositories {
      ...DataRepositoryFields
    }
  }
}
    ${DataRepositoryFieldsFragmentDoc}`;
export type GetAllDataRepositoriesQueryResult = Apollo.QueryResult<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>;
export const GetAllDataRepositoryCollectionsDocument = gql`
    query getAllDataRepositoryCollections {
  getAllDataRepositoryCollections {
    dataRepositoryCollections {
      id
      name
      index
    }
  }
}
    `;
export type GetAllDataRepositoryCollectionsQueryResult = Apollo.QueryResult<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>;
export const GetDataRepositoryRowsDocument = gql`
    query getDataRepositoryRows($input: GetDataRepositoryRowsInput!) {
  getDataRepositoryRows(input: $input) {
    rows {
      id
      departmentId
      importId
      dataJson
    }
  }
}
    `;
export type GetDataRepositoryRowsQueryResult = Apollo.QueryResult<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>;
export const GetRepositoryRowDeleteQueriesByRepositoryDocument = gql`
    query getRepositoryRowDeleteQueriesByRepository($input: GetRepositoryRowDeleteQueriesByRepositoryInput!) {
  getRepositoryRowDeleteQueriesByRepository(input: $input) {
    repositoryRowDeleteQueries {
      ...RepositoryRowDeleteQueryFields
    }
  }
}
    ${RepositoryRowDeleteQueryFieldsFragmentDoc}`;
export type GetRepositoryRowDeleteQueriesByRepositoryQueryResult = Apollo.QueryResult<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>;
export const GetImportsByDataRepositoryDocument = gql`
    query getImportsByDataRepository($input: GetImportsByDataRepositoryInput!) {
  getImportsByDataRepository(input: $input) {
    imports {
      ...ImportFields
    }
  }
}
    ${ImportFieldsFragmentDoc}`;
export type GetImportsByDataRepositoryQueryResult = Apollo.QueryResult<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>;
export const DeleteImportsDocument = gql`
    mutation deleteImports($input: DeleteImportsInput!) {
  deleteImports(input: $input) {
    notificationId
  }
}
    `;
export type DeleteImportsMutationFn = Apollo.MutationFunction<DeleteImportsMutation, DeleteImportsMutationVariables>;
export type DeleteImportsMutationResult = Apollo.MutationResult<DeleteImportsMutation>;
export type DeleteImportsMutationOptions = Apollo.BaseMutationOptions<DeleteImportsMutation, DeleteImportsMutationVariables>;
export const DeleteDataRepositoryCollectionDocument = gql`
    mutation deleteDataRepositoryCollection($input: DeleteDataRepositoryCollectionInput!) {
  deleteDataRepositoryCollection(input: $input) {
    deletedId
  }
}
    `;
export type DeleteDataRepositoryCollectionMutationFn = Apollo.MutationFunction<DeleteDataRepositoryCollectionMutation, DeleteDataRepositoryCollectionMutationVariables>;
export type DeleteDataRepositoryCollectionMutationResult = Apollo.MutationResult<DeleteDataRepositoryCollectionMutation>;
export type DeleteDataRepositoryCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteDataRepositoryCollectionMutation, DeleteDataRepositoryCollectionMutationVariables>;
export const UpdateDataRepositoryCollectionDocument = gql`
    mutation updateDataRepositoryCollection($input: UpdateDataRepositoryCollectionInput!) {
  updateDataRepositoryCollection(input: $input) {
    dataRepositoryCollection {
      id
      name
      index
    }
  }
}
    `;
export type UpdateDataRepositoryCollectionMutationFn = Apollo.MutationFunction<UpdateDataRepositoryCollectionMutation, UpdateDataRepositoryCollectionMutationVariables>;
export type UpdateDataRepositoryCollectionMutationResult = Apollo.MutationResult<UpdateDataRepositoryCollectionMutation>;
export type UpdateDataRepositoryCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateDataRepositoryCollectionMutation, UpdateDataRepositoryCollectionMutationVariables>;
export const DeleteDataRepositoryDocument = gql`
    mutation deleteDataRepository($input: DeleteDataRepositoryInput!) {
  deleteDataRepository(input: $input) {
    notificationId
  }
}
    `;
export type DeleteDataRepositoryMutationFn = Apollo.MutationFunction<DeleteDataRepositoryMutation, DeleteDataRepositoryMutationVariables>;
export type DeleteDataRepositoryMutationResult = Apollo.MutationResult<DeleteDataRepositoryMutation>;
export type DeleteDataRepositoryMutationOptions = Apollo.BaseMutationOptions<DeleteDataRepositoryMutation, DeleteDataRepositoryMutationVariables>;
export const GetDataRepositoryRowCountDocument = gql`
    query getDataRepositoryRowCount($input: GetDataRepositoryRowCountInput!) {
  getDataRepositoryRowCount(input: $input) {
    rowCount
  }
}
    `;
export type GetDataRepositoryRowCountQueryResult = Apollo.QueryResult<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>;
export const DeleteRowsByDeleteQueryDocument = gql`
    mutation deleteRowsByDeleteQuery($input: DeleteRowsByDeleteQueryInput!) {
  deleteRowsByDeleteQuery(input: $input) {
    notificationId
  }
}
    `;
export type DeleteRowsByDeleteQueryMutationFn = Apollo.MutationFunction<DeleteRowsByDeleteQueryMutation, DeleteRowsByDeleteQueryMutationVariables>;
export type DeleteRowsByDeleteQueryMutationResult = Apollo.MutationResult<DeleteRowsByDeleteQueryMutation>;
export type DeleteRowsByDeleteQueryMutationOptions = Apollo.BaseMutationOptions<DeleteRowsByDeleteQueryMutation, DeleteRowsByDeleteQueryMutationVariables>;
export const GetPaginatedImportsByDataRepositoryDocument = gql`
    query GetPaginatedImportsByDataRepository($input: GetPaginatedImportsByDataRepositoryInput!) {
  getPaginatedImportsByDataRepository(input: $input) {
    imports {
      ...ImportFields
    }
    totalImports
  }
}
    ${ImportFieldsFragmentDoc}`;
export type GetPaginatedImportsByDataRepositoryQueryResult = Apollo.QueryResult<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>;
export const ValidateFileDocument = gql`
    mutation ValidateFile($input: ValidateFileInput!) {
  validateFile(input: $input) {
    fileId
  }
}
    `;
export type ValidateFileMutationFn = Apollo.MutationFunction<ValidateFileMutation, ValidateFileMutationVariables>;
export type ValidateFileMutationResult = Apollo.MutationResult<ValidateFileMutation>;
export type ValidateFileMutationOptions = Apollo.BaseMutationOptions<ValidateFileMutation, ValidateFileMutationVariables>;
export const GetSupplierTableDataSourcesDocument = gql`
    query GetSupplierTableDataSources {
  getSupplierTableDataSources {
    sources {
      id
    }
  }
}
    `;
export type GetSupplierTableDataSourcesQueryResult = Apollo.QueryResult<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>;
export const CreateUploadUrlsV2Document = gql`
    mutation createUploadUrlsV2($input: CreateUploadUrlsV2Input!) {
  createUploadUrlsV2(input: $input) {
    urls {
      fileName
      fileId
      url
    }
  }
}
    `;
export type CreateUploadUrlsV2MutationFn = Apollo.MutationFunction<CreateUploadUrlsV2Mutation, CreateUploadUrlsV2MutationVariables>;
export type CreateUploadUrlsV2MutationResult = Apollo.MutationResult<CreateUploadUrlsV2Mutation>;
export type CreateUploadUrlsV2MutationOptions = Apollo.BaseMutationOptions<CreateUploadUrlsV2Mutation, CreateUploadUrlsV2MutationVariables>;
export const UpdateFilesUploadStatusV2Document = gql`
    mutation updateFilesUploadStatusV2($input: UpdateFilesUploadStatusV2Input!) {
  updateFilesUploadStatusV2(input: $input) {
    files {
      uploadStatus
    }
  }
}
    `;
export type UpdateFilesUploadStatusV2MutationFn = Apollo.MutationFunction<UpdateFilesUploadStatusV2Mutation, UpdateFilesUploadStatusV2MutationVariables>;
export type UpdateFilesUploadStatusV2MutationResult = Apollo.MutationResult<UpdateFilesUploadStatusV2Mutation>;
export type UpdateFilesUploadStatusV2MutationOptions = Apollo.BaseMutationOptions<UpdateFilesUploadStatusV2Mutation, UpdateFilesUploadStatusV2MutationVariables>;
export const GetDataTableElasticFieldsDocument = gql`
    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {
  getDataTableElasticFields(input: $input) {
    elasticFields {
      ...ElasticFieldItemFields
    }
  }
}
    ${ElasticFieldItemFieldsFragmentDoc}`;
export type GetDataTableElasticFieldsQueryResult = Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>;
export const GetDataTableDocument = gql`
    query getDataTable($input: GetDataTableInput!) {
  getDataTable(input: $input) {
    entity {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type GetDataTableQueryResult = Apollo.QueryResult<GetDataTableQuery, GetDataTableQueryVariables>;
export const GetManyDataTablesDocument = gql`
    query getManyDataTables {
  getManyDataTables {
    entities {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type GetManyDataTablesQueryResult = Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>;
export const GetDataColumnsDocument = gql`
    query getDataColumns($input: GetDataColumnsInput!) {
  getDataColumns(input: $input) {
    dataColumns {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type GetDataColumnsQueryResult = Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>;
export const GetDataTableRowsSearchDocument = gql`
    query getDataTableRowsSearch($input: GetDataTableRowsSearchInput!) {
  getDataTableRowsSearch(input: $input) {
    dataTableRows {
      id
      departmentId
      dataJson
    }
  }
}
    `;
export type GetDataTableRowsSearchQueryResult = Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>;
export const CreateDataColumnsDocument = gql`
    mutation createDataColumns($input: CreateDataColumnsInput!) {
  createDataColumns(input: $input) {
    dataColumns {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type CreateDataColumnsMutationFn = Apollo.MutationFunction<CreateDataColumnsMutation, CreateDataColumnsMutationVariables>;
export type CreateDataColumnsMutationResult = Apollo.MutationResult<CreateDataColumnsMutation>;
export type CreateDataColumnsMutationOptions = Apollo.BaseMutationOptions<CreateDataColumnsMutation, CreateDataColumnsMutationVariables>;
export const UpdateDataColumnDocument = gql`
    mutation updateDataColumn($input: UpdateDataColumnInput!) {
  updateDataColumn(input: $input) {
    dataColumn {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type UpdateDataColumnMutationFn = Apollo.MutationFunction<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>;
export type UpdateDataColumnMutationResult = Apollo.MutationResult<UpdateDataColumnMutation>;
export type UpdateDataColumnMutationOptions = Apollo.BaseMutationOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>;
export const DeleteDataColumnsDocument = gql`
    mutation deleteDataColumns($input: DeleteDataColumnsInput!) {
  deleteDataColumns(input: $input) {
    dataTable {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type DeleteDataColumnsMutationFn = Apollo.MutationFunction<DeleteDataColumnsMutation, DeleteDataColumnsMutationVariables>;
export type DeleteDataColumnsMutationResult = Apollo.MutationResult<DeleteDataColumnsMutation>;
export type DeleteDataColumnsMutationOptions = Apollo.BaseMutationOptions<DeleteDataColumnsMutation, DeleteDataColumnsMutationVariables>;
export const CreateDataTableDocument = gql`
    mutation createDataTable($input: CreateDataTableInput!) {
  createDataTable(input: $input) {
    entity {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type CreateDataTableMutationFn = Apollo.MutationFunction<CreateDataTableMutation, CreateDataTableMutationVariables>;
export type CreateDataTableMutationResult = Apollo.MutationResult<CreateDataTableMutation>;
export type CreateDataTableMutationOptions = Apollo.BaseMutationOptions<CreateDataTableMutation, CreateDataTableMutationVariables>;
export const UpdateDataTableDocument = gql`
    mutation updateDataTable($input: UpdateDataTableInput!) {
  updateDataTable(input: $input) {
    entity {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type UpdateDataTableMutationFn = Apollo.MutationFunction<UpdateDataTableMutation, UpdateDataTableMutationVariables>;
export type UpdateDataTableMutationResult = Apollo.MutationResult<UpdateDataTableMutation>;
export type UpdateDataTableMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>;
export const DeleteDataTableDocument = gql`
    mutation deleteDataTable($input: DeleteDataTableInput!) {
  deleteDataTable(input: $input) {
    deletedId
  }
}
    `;
export type DeleteDataTableMutationFn = Apollo.MutationFunction<DeleteDataTableMutation, DeleteDataTableMutationVariables>;
export type DeleteDataTableMutationResult = Apollo.MutationResult<DeleteDataTableMutation>;
export type DeleteDataTableMutationOptions = Apollo.BaseMutationOptions<DeleteDataTableMutation, DeleteDataTableMutationVariables>;
export const IndexDataTableToElasticDocument = gql`
    mutation indexDataTableToElastic($input: IndexDataTableToElasticInput!) {
  indexDataTableToElastic(input: $input) {
    notificationId
  }
}
    `;
export type IndexDataTableToElasticMutationFn = Apollo.MutationFunction<IndexDataTableToElasticMutation, IndexDataTableToElasticMutationVariables>;
export type IndexDataTableToElasticMutationResult = Apollo.MutationResult<IndexDataTableToElasticMutation>;
export type IndexDataTableToElasticMutationOptions = Apollo.BaseMutationOptions<IndexDataTableToElasticMutation, IndexDataTableToElasticMutationVariables>;
export const CreateDataTableCollectionDocument = gql`
    mutation createDataTableCollection($input: CreateDataTableCollectionInput!) {
  createDataTableCollection(input: $input) {
    dataTableCollection {
      id
      name
      index
    }
  }
}
    `;
export type CreateDataTableCollectionMutationFn = Apollo.MutationFunction<CreateDataTableCollectionMutation, CreateDataTableCollectionMutationVariables>;
export type CreateDataTableCollectionMutationResult = Apollo.MutationResult<CreateDataTableCollectionMutation>;
export type CreateDataTableCollectionMutationOptions = Apollo.BaseMutationOptions<CreateDataTableCollectionMutation, CreateDataTableCollectionMutationVariables>;
export const GetAllDataColumnsDocument = gql`
    query getAllDataColumns {
  getAllDataColumns {
    dataColumns {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type GetAllDataColumnsQueryResult = Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>;
export const DeleteDataTableCollectionDocument = gql`
    mutation deleteDataTableCollection($input: DeleteDataTableCollectionInput!) {
  deleteDataTableCollection(input: $input) {
    deleteId
  }
}
    `;
export type DeleteDataTableCollectionMutationFn = Apollo.MutationFunction<DeleteDataTableCollectionMutation, DeleteDataTableCollectionMutationVariables>;
export type DeleteDataTableCollectionMutationResult = Apollo.MutationResult<DeleteDataTableCollectionMutation>;
export type DeleteDataTableCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteDataTableCollectionMutation, DeleteDataTableCollectionMutationVariables>;
export const UpdateDataTableCollectionDocument = gql`
    mutation updateDataTableCollection($input: UpdateDataTableCollectionInput!) {
  updateDataTableCollection(input: $input) {
    dataTableCollection {
      id
      name
      index
    }
  }
}
    `;
export type UpdateDataTableCollectionMutationFn = Apollo.MutationFunction<UpdateDataTableCollectionMutation, UpdateDataTableCollectionMutationVariables>;
export type UpdateDataTableCollectionMutationResult = Apollo.MutationResult<UpdateDataTableCollectionMutation>;
export type UpdateDataTableCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableCollectionMutation, UpdateDataTableCollectionMutationVariables>;
export const GetAllDataTableCollectionsDocument = gql`
    query getAllDataTableCollections {
  getAllDataTableCollections {
    dataTableCollections {
      id
      name
      index
    }
  }
}
    `;
export type GetAllDataTableCollectionsQueryResult = Apollo.QueryResult<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>;
export const CreateImportConfigurationDocument = gql`
    mutation createImportConfiguration($input: CreateImportConfigurationInput!) {
  createImportConfiguration(input: $input) {
    importConfiguration {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type CreateImportConfigurationMutationFn = Apollo.MutationFunction<CreateImportConfigurationMutation, CreateImportConfigurationMutationVariables>;
export type CreateImportConfigurationMutationResult = Apollo.MutationResult<CreateImportConfigurationMutation>;
export type CreateImportConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateImportConfigurationMutation, CreateImportConfigurationMutationVariables>;
export const UpdateImportConfigurationDocument = gql`
    mutation updateImportConfiguration($input: UpdateImportConfigurationInput!) {
  updateImportConfiguration(input: $input) {
    importConfiguration {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type UpdateImportConfigurationMutationFn = Apollo.MutationFunction<UpdateImportConfigurationMutation, UpdateImportConfigurationMutationVariables>;
export type UpdateImportConfigurationMutationResult = Apollo.MutationResult<UpdateImportConfigurationMutation>;
export type UpdateImportConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateImportConfigurationMutation, UpdateImportConfigurationMutationVariables>;
export const GetImportConfigurationDocument = gql`
    query getImportConfiguration($input: GetImportConfigurationInput!) {
  getImportConfiguration(input: $input) {
    importConfiguration {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type GetImportConfigurationQueryResult = Apollo.QueryResult<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>;
export const DeleteImportConfigurationDocument = gql`
    mutation deleteImportConfiguration($input: DeleteImportConfigurationInput!) {
  deleteImportConfiguration(input: $input) {
    deletedId
  }
}
    `;
export type DeleteImportConfigurationMutationFn = Apollo.MutationFunction<DeleteImportConfigurationMutation, DeleteImportConfigurationMutationVariables>;
export type DeleteImportConfigurationMutationResult = Apollo.MutationResult<DeleteImportConfigurationMutation>;
export type DeleteImportConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteImportConfigurationMutation, DeleteImportConfigurationMutationVariables>;
export const GetAllImportConfigurationsDocument = gql`
    query getAllImportConfigurations {
  getAllImportConfigurations {
    importConfigurations {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type GetAllImportConfigurationsQueryResult = Apollo.QueryResult<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>;
export const ParseFileSamplesDocument = gql`
    mutation parseFileSamples($input: ParseFileSamplesInput!) {
  parseFileSamples(input: $input) {
    fileId
    fields {
      ...FieldSampleFields
    }
    importConfiguration {
      ...ImportConfigurationFields
    }
    warnings
    errors
  }
}
    ${FieldSampleFieldsFragmentDoc}
${ImportConfigurationFieldsFragmentDoc}`;
export type ParseFileSamplesMutationFn = Apollo.MutationFunction<ParseFileSamplesMutation, ParseFileSamplesMutationVariables>;
export type ParseFileSamplesMutationResult = Apollo.MutationResult<ParseFileSamplesMutation>;
export type ParseFileSamplesMutationOptions = Apollo.BaseMutationOptions<ParseFileSamplesMutation, ParseFileSamplesMutationVariables>;
export const AddImportFieldMappingDocument = gql`
    mutation addImportFieldMapping($input: AddImportFieldMappingInput!) {
  addImportFieldMapping(input: $input) {
    updatedImportConfiguration {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type AddImportFieldMappingMutationFn = Apollo.MutationFunction<AddImportFieldMappingMutation, AddImportFieldMappingMutationVariables>;
export type AddImportFieldMappingMutationResult = Apollo.MutationResult<AddImportFieldMappingMutation>;
export type AddImportFieldMappingMutationOptions = Apollo.BaseMutationOptions<AddImportFieldMappingMutation, AddImportFieldMappingMutationVariables>;
export const AddManyImportFieldMappingsDocument = gql`
    mutation addManyImportFieldMappings($input: AddManyImportFieldMappingsInput!) {
  addManyImportFieldMappings(input: $input) {
    updatedImportConfiguration {
      ...ImportConfigurationFields
    }
  }
}
    ${ImportConfigurationFieldsFragmentDoc}`;
export type AddManyImportFieldMappingsMutationFn = Apollo.MutationFunction<AddManyImportFieldMappingsMutation, AddManyImportFieldMappingsMutationVariables>;
export type AddManyImportFieldMappingsMutationResult = Apollo.MutationResult<AddManyImportFieldMappingsMutation>;
export type AddManyImportFieldMappingsMutationOptions = Apollo.BaseMutationOptions<AddManyImportFieldMappingsMutation, AddManyImportFieldMappingsMutationVariables>;
export const StartFileImportByFileIdDocument = gql`
    mutation startFileImportByFileId($input: StartFileImportByFileIdInput!) {
  startFileImportByFileId(input: $input) {
    notificationId
  }
}
    `;
export type StartFileImportByFileIdMutationFn = Apollo.MutationFunction<StartFileImportByFileIdMutation, StartFileImportByFileIdMutationVariables>;
export type StartFileImportByFileIdMutationResult = Apollo.MutationResult<StartFileImportByFileIdMutation>;
export type StartFileImportByFileIdMutationOptions = Apollo.BaseMutationOptions<StartFileImportByFileIdMutation, StartFileImportByFileIdMutationVariables>;
export const CreateUniqueIdentifierConfigurationDocument = gql`
    mutation createUniqueIdentifierConfiguration($input: CreateUniqueIdentifierConfigurationInput!) {
  createUniqueIdentifierConfiguration(input: $input) {
    entity {
      ...UniqueIdentifierConfigurationFields
    }
  }
}
    ${UniqueIdentifierConfigurationFieldsFragmentDoc}`;
export type CreateUniqueIdentifierConfigurationMutationFn = Apollo.MutationFunction<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>;
export type CreateUniqueIdentifierConfigurationMutationResult = Apollo.MutationResult<CreateUniqueIdentifierConfigurationMutation>;
export type CreateUniqueIdentifierConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>;
export const GetUniqueIdentifierConfigurationDocument = gql`
    query GetUniqueIdentifierConfiguration($input: GetUniqueIdentifierConfigurationInput!) {
  getUniqueIdentifierConfiguration(input: $input) {
    entity {
      ...UniqueIdentifierConfigurationFields
    }
  }
}
    ${UniqueIdentifierConfigurationFieldsFragmentDoc}`;
export type GetUniqueIdentifierConfigurationQueryResult = Apollo.QueryResult<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>;
export const DeleteUniqueIdentifierConfigurationDocument = gql`
    mutation deleteUniqueIdentifierConfiguration($input: DeleteUniqueIdentifierConfigurationInput!) {
  deleteUniqueIdentifierConfiguration(input: $input) {
    deletedId
  }
}
    `;
export type DeleteUniqueIdentifierConfigurationMutationFn = Apollo.MutationFunction<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>;
export type DeleteUniqueIdentifierConfigurationMutationResult = Apollo.MutationResult<DeleteUniqueIdentifierConfigurationMutation>;
export type DeleteUniqueIdentifierConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>;
export const UpdateUniqueIdentifierConfigurationDocument = gql`
    mutation updateUniqueIdentifierConfiguration($input: UpdateUniqueIdentifierConfigurationInput!) {
  updateUniqueIdentifierConfiguration(input: $input) {
    entity {
      ...UniqueIdentifierConfigurationFields
    }
  }
}
    ${UniqueIdentifierConfigurationFieldsFragmentDoc}`;
export type UpdateUniqueIdentifierConfigurationMutationFn = Apollo.MutationFunction<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>;
export type UpdateUniqueIdentifierConfigurationMutationResult = Apollo.MutationResult<UpdateUniqueIdentifierConfigurationMutation>;
export type UpdateUniqueIdentifierConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>;
export const GetManyUniqueIdentifierConfigurationsDocument = gql`
    query getManyUniqueIdentifierConfigurations {
  getManyUniqueIdentifierConfigurations {
    entities {
      ...UniqueIdentifierConfigurationFields
    }
  }
}
    ${UniqueIdentifierConfigurationFieldsFragmentDoc}`;
export type GetManyUniqueIdentifierConfigurationsQueryResult = Apollo.QueryResult<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>;

export type GraphqlAsyncData<ResultType> =
    | { type: "not-asked" }
    | { type: "success"; data: ResultType }
    | { type: "error", error: Apollo.ApolloError }
    | { type: "loading" };
function queryResultToGraphqlAsyncData<
    ResultType,
    Key extends keyof ResultType
>(queryKey: Key, loading: boolean, data?: ResultType | null, error?: Apollo.ApolloError): GraphqlAsyncData<ResultType[Key]> {
    if (loading) {
        return { type: "loading" };
    }
    if (error) {
        return { type: "error", error };
    }
    if (data) {
        return { type: "success", data: data[queryKey] };
    }
    return { type: "not-asked" };
}

type GetUsersQueryHookReturnValue = {
    result: GraphqlAsyncData<GetUsersQuery["getUsers"]>;
    refetch: (variables?: Partial<GetUsersQueryVariables>) => void;
}
export function useGetUsersQuery(
    variables: GetUsersQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>, 'variables'> = {}
): GetUsersQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getUsers", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetUsersLazyQueryHookReturnValue = [
    (variables: GetUsersQueryVariables) => Promise<Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>>,
    { result: GraphqlAsyncData<GetUsersQuery["getUsers"]>, refetch: (variables: GetUsersQueryVariables) => Promise<Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetUsersQuery>> }
]
export function useGetUsersLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>, 'variables'> = {}
): GetUsersLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
    return [
        useCallback((variables: GetUsersQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getUsers", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetUsersQueryVariables) => query.refetch(variables)
                : (variables: GetUsersQueryVariables) => action({ variables })
        }
    ];
}
type CreateGetOrCreateDataPipelineOperationMutationHookReturnValue = [
    (variables: CreateGetOrCreateDataPipelineOperationMutationVariables) => Promise<Apollo.FetchResult<CreateGetOrCreateDataPipelineOperationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateGetOrCreateDataPipelineOperationMutation["createGetOrCreateDataPipelineOperation"]> }
]
export function useCreateGetOrCreateDataPipelineOperationMutation(
    options: Omit<Apollo.MutationHookOptions<CreateGetOrCreateDataPipelineOperationMutation, CreateGetOrCreateDataPipelineOperationMutationVariables>, 'variables'> = {}
): CreateGetOrCreateDataPipelineOperationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateGetOrCreateDataPipelineOperationMutation, CreateGetOrCreateDataPipelineOperationMutationVariables>(CreateGetOrCreateDataPipelineOperationDocument, options)
    return [
        useMemo(() => (variables: CreateGetOrCreateDataPipelineOperationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateGetOrCreateDataPipelineOperationMutation, CreateGetOrCreateDataPipelineOperationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createGetOrCreateDataPipelineOperation", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetDataPipelineQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataPipelineQuery["getDataPipeline"]>;
    refetch: (variables?: Partial<GetDataPipelineQueryVariables>) => void;
}
export function useGetDataPipelineQuery(
    variables: GetDataPipelineQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataPipelineQuery, GetDataPipelineQueryVariables>, 'variables'> = {}
): GetDataPipelineQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataPipelineQuery, GetDataPipelineQueryVariables>(GetDataPipelineDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataPipeline", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataPipelineLazyQueryHookReturnValue = [
    (variables: GetDataPipelineQueryVariables) => Promise<Apollo.QueryResult<GetDataPipelineQuery, GetDataPipelineQueryVariables>>,
    { result: GraphqlAsyncData<GetDataPipelineQuery["getDataPipeline"]>, refetch: (variables: GetDataPipelineQueryVariables) => Promise<Apollo.QueryResult<GetDataPipelineQuery, GetDataPipelineQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataPipelineQuery>> }
]
export function useGetDataPipelineLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataPipelineQuery, GetDataPipelineQueryVariables>, 'variables'> = {}
): GetDataPipelineLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataPipelineQuery, GetDataPipelineQueryVariables>(GetDataPipelineDocument, options);
    return [
        useCallback((variables: GetDataPipelineQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataPipeline", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataPipelineQueryVariables) => query.refetch(variables)
                : (variables: GetDataPipelineQueryVariables) => action({ variables })
        }
    ];
}
type CreateDataPipelineMutationHookReturnValue = [
    (variables: CreateDataPipelineMutationVariables) => Promise<Apollo.FetchResult<CreateDataPipelineMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataPipelineMutation["createDataPipeline"]> }
]
export function useCreateDataPipelineMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataPipelineMutation, CreateDataPipelineMutationVariables>, 'variables'> = {}
): CreateDataPipelineMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataPipelineMutation, CreateDataPipelineMutationVariables>(CreateDataPipelineDocument, options)
    return [
        useMemo(() => (variables: CreateDataPipelineMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataPipelineMutation, CreateDataPipelineMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataPipeline", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateInputOutputConnectionMutationHookReturnValue = [
    (variables: CreateInputOutputConnectionMutationVariables) => Promise<Apollo.FetchResult<CreateInputOutputConnectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateInputOutputConnectionMutation["createInputOutputConnection"]> }
]
export function useCreateInputOutputConnectionMutation(
    options: Omit<Apollo.MutationHookOptions<CreateInputOutputConnectionMutation, CreateInputOutputConnectionMutationVariables>, 'variables'> = {}
): CreateInputOutputConnectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateInputOutputConnectionMutation, CreateInputOutputConnectionMutationVariables>(CreateInputOutputConnectionDocument, options)
    return [
        useMemo(() => (variables: CreateInputOutputConnectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateInputOutputConnectionMutation, CreateInputOutputConnectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createInputOutputConnection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetInputOutputConnectionsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetInputOutputConnectionsQuery["getInputOutputConnections"]>;
    refetch: (variables?: Partial<GetInputOutputConnectionsQueryVariables>) => void;
}
export function useGetInputOutputConnectionsQuery(
    variables: GetInputOutputConnectionsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>, 'variables'> = {}
): GetInputOutputConnectionsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>(GetInputOutputConnectionsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getInputOutputConnections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetInputOutputConnectionsLazyQueryHookReturnValue = [
    (variables: GetInputOutputConnectionsQueryVariables) => Promise<Apollo.QueryResult<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>>,
    { result: GraphqlAsyncData<GetInputOutputConnectionsQuery["getInputOutputConnections"]>, refetch: (variables: GetInputOutputConnectionsQueryVariables) => Promise<Apollo.QueryResult<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetInputOutputConnectionsQuery>> }
]
export function useGetInputOutputConnectionsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>, 'variables'> = {}
): GetInputOutputConnectionsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetInputOutputConnectionsQuery, GetInputOutputConnectionsQueryVariables>(GetInputOutputConnectionsDocument, options);
    return [
        useCallback((variables: GetInputOutputConnectionsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getInputOutputConnections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetInputOutputConnectionsQueryVariables) => query.refetch(variables)
                : (variables: GetInputOutputConnectionsQueryVariables) => action({ variables })
        }
    ];
}
type CreateMatchCompanyDataPipelineOperationMutationHookReturnValue = [
    (variables: CreateMatchCompanyDataPipelineOperationMutationVariables) => Promise<Apollo.FetchResult<CreateMatchCompanyDataPipelineOperationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateMatchCompanyDataPipelineOperationMutation["createMatchCompanyDataPipelineOperation"]> }
]
export function useCreateMatchCompanyDataPipelineOperationMutation(
    options: Omit<Apollo.MutationHookOptions<CreateMatchCompanyDataPipelineOperationMutation, CreateMatchCompanyDataPipelineOperationMutationVariables>, 'variables'> = {}
): CreateMatchCompanyDataPipelineOperationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateMatchCompanyDataPipelineOperationMutation, CreateMatchCompanyDataPipelineOperationMutationVariables>(CreateMatchCompanyDataPipelineOperationDocument, options)
    return [
        useMemo(() => (variables: CreateMatchCompanyDataPipelineOperationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateMatchCompanyDataPipelineOperationMutation, CreateMatchCompanyDataPipelineOperationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createMatchCompanyDataPipelineOperation", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateMonetaryAmountDataPipelineOperationMutationHookReturnValue = [
    (variables: CreateMonetaryAmountDataPipelineOperationMutationVariables) => Promise<Apollo.FetchResult<CreateMonetaryAmountDataPipelineOperationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateMonetaryAmountDataPipelineOperationMutation["createMonetaryAmountDataPipelineOperation"]> }
]
export function useCreateMonetaryAmountDataPipelineOperationMutation(
    options: Omit<Apollo.MutationHookOptions<CreateMonetaryAmountDataPipelineOperationMutation, CreateMonetaryAmountDataPipelineOperationMutationVariables>, 'variables'> = {}
): CreateMonetaryAmountDataPipelineOperationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateMonetaryAmountDataPipelineOperationMutation, CreateMonetaryAmountDataPipelineOperationMutationVariables>(CreateMonetaryAmountDataPipelineOperationDocument, options)
    return [
        useMemo(() => (variables: CreateMonetaryAmountDataPipelineOperationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateMonetaryAmountDataPipelineOperationMutation, CreateMonetaryAmountDataPipelineOperationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createMonetaryAmountDataPipelineOperation", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateDataRepositoryMutationHookReturnValue = [
    (variables: CreateDataRepositoryMutationVariables) => Promise<Apollo.FetchResult<CreateDataRepositoryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataRepositoryMutation["createDataRepository"]> }
]
export function useCreateDataRepositoryMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataRepositoryMutation, CreateDataRepositoryMutationVariables>, 'variables'> = {}
): CreateDataRepositoryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataRepositoryMutation, CreateDataRepositoryMutationVariables>(CreateDataRepositoryDocument, options)
    return [
        useMemo(() => (variables: CreateDataRepositoryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataRepositoryMutation, CreateDataRepositoryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataRepository", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateDataRepositoryMutationHookReturnValue = [
    (variables: UpdateDataRepositoryMutationVariables) => Promise<Apollo.FetchResult<UpdateDataRepositoryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataRepositoryMutation["updateDataRepository"]> }
]
export function useUpdateDataRepositoryMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataRepositoryMutation, UpdateDataRepositoryMutationVariables>, 'variables'> = {}
): UpdateDataRepositoryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataRepositoryMutation, UpdateDataRepositoryMutationVariables>(UpdateDataRepositoryDocument, options)
    return [
        useMemo(() => (variables: UpdateDataRepositoryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataRepositoryMutation, UpdateDataRepositoryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataRepository", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type StartSqlImportMutationHookReturnValue = [
    (variables: StartSqlImportMutationVariables) => Promise<Apollo.FetchResult<StartSqlImportMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<StartSqlImportMutation["startSqlImport"]> }
]
export function useStartSqlImportMutation(
    options: Omit<Apollo.MutationHookOptions<StartSqlImportMutation, StartSqlImportMutationVariables>, 'variables'> = {}
): StartSqlImportMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<StartSqlImportMutation, StartSqlImportMutationVariables>(StartSqlImportDocument, options)
    return [
        useMemo(() => (variables: StartSqlImportMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<StartSqlImportMutation, StartSqlImportMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("startSqlImport", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateRepositoryRowDeleteQueryMutationHookReturnValue = [
    (variables: CreateRepositoryRowDeleteQueryMutationVariables) => Promise<Apollo.FetchResult<CreateRepositoryRowDeleteQueryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateRepositoryRowDeleteQueryMutation["createRepositoryRowDeleteQuery"]> }
]
export function useCreateRepositoryRowDeleteQueryMutation(
    options: Omit<Apollo.MutationHookOptions<CreateRepositoryRowDeleteQueryMutation, CreateRepositoryRowDeleteQueryMutationVariables>, 'variables'> = {}
): CreateRepositoryRowDeleteQueryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateRepositoryRowDeleteQueryMutation, CreateRepositoryRowDeleteQueryMutationVariables>(CreateRepositoryRowDeleteQueryDocument, options)
    return [
        useMemo(() => (variables: CreateRepositoryRowDeleteQueryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateRepositoryRowDeleteQueryMutation, CreateRepositoryRowDeleteQueryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createRepositoryRowDeleteQuery", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteRepositoryRowDeleteQueryMutationHookReturnValue = [
    (variables: DeleteRepositoryRowDeleteQueryMutationVariables) => Promise<Apollo.FetchResult<DeleteRepositoryRowDeleteQueryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteRepositoryRowDeleteQueryMutation["deleteRepositoryRowDeleteQuery"]> }
]
export function useDeleteRepositoryRowDeleteQueryMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteRepositoryRowDeleteQueryMutation, DeleteRepositoryRowDeleteQueryMutationVariables>, 'variables'> = {}
): DeleteRepositoryRowDeleteQueryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteRepositoryRowDeleteQueryMutation, DeleteRepositoryRowDeleteQueryMutationVariables>(DeleteRepositoryRowDeleteQueryDocument, options)
    return [
        useMemo(() => (variables: DeleteRepositoryRowDeleteQueryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteRepositoryRowDeleteQueryMutation, DeleteRepositoryRowDeleteQueryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteRepositoryRowDeleteQuery", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateDataRepositoryCollectionMutationHookReturnValue = [
    (variables: CreateDataRepositoryCollectionMutationVariables) => Promise<Apollo.FetchResult<CreateDataRepositoryCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataRepositoryCollectionMutation["createDataRepositoryCollection"]> }
]
export function useCreateDataRepositoryCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataRepositoryCollectionMutation, CreateDataRepositoryCollectionMutationVariables>, 'variables'> = {}
): CreateDataRepositoryCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataRepositoryCollectionMutation, CreateDataRepositoryCollectionMutationVariables>(CreateDataRepositoryCollectionDocument, options)
    return [
        useMemo(() => (variables: CreateDataRepositoryCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataRepositoryCollectionMutation, CreateDataRepositoryCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataRepositoryCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type AddDataRepositoryFieldMutationHookReturnValue = [
    (variables: AddDataRepositoryFieldMutationVariables) => Promise<Apollo.FetchResult<AddDataRepositoryFieldMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<AddDataRepositoryFieldMutation["addDataRepositoryField"]> }
]
export function useAddDataRepositoryFieldMutation(
    options: Omit<Apollo.MutationHookOptions<AddDataRepositoryFieldMutation, AddDataRepositoryFieldMutationVariables>, 'variables'> = {}
): AddDataRepositoryFieldMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<AddDataRepositoryFieldMutation, AddDataRepositoryFieldMutationVariables>(AddDataRepositoryFieldDocument, options)
    return [
        useMemo(() => (variables: AddDataRepositoryFieldMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<AddDataRepositoryFieldMutation, AddDataRepositoryFieldMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("addDataRepositoryField", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type AddManyDataRepositoryFieldsMutationHookReturnValue = [
    (variables: AddManyDataRepositoryFieldsMutationVariables) => Promise<Apollo.FetchResult<AddManyDataRepositoryFieldsMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<AddManyDataRepositoryFieldsMutation["addManyDataRepositoryFields"]> }
]
export function useAddManyDataRepositoryFieldsMutation(
    options: Omit<Apollo.MutationHookOptions<AddManyDataRepositoryFieldsMutation, AddManyDataRepositoryFieldsMutationVariables>, 'variables'> = {}
): AddManyDataRepositoryFieldsMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<AddManyDataRepositoryFieldsMutation, AddManyDataRepositoryFieldsMutationVariables>(AddManyDataRepositoryFieldsDocument, options)
    return [
        useMemo(() => (variables: AddManyDataRepositoryFieldsMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<AddManyDataRepositoryFieldsMutation, AddManyDataRepositoryFieldsMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("addManyDataRepositoryFields", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetDataRepositoryQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataRepositoryQuery["getDataRepository"]>;
    refetch: (variables?: Partial<GetDataRepositoryQueryVariables>) => void;
}
export function useGetDataRepositoryQuery(
    variables: GetDataRepositoryQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>, 'variables'> = {}
): GetDataRepositoryQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>(GetDataRepositoryDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataRepositoryLazyQueryHookReturnValue = [
    (variables: GetDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>>,
    { result: GraphqlAsyncData<GetDataRepositoryQuery["getDataRepository"]>, refetch: (variables: GetDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataRepositoryQuery>> }
]
export function useGetDataRepositoryLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>, 'variables'> = {}
): GetDataRepositoryLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataRepositoryQuery, GetDataRepositoryQueryVariables>(GetDataRepositoryDocument, options);
    return [
        useCallback((variables: GetDataRepositoryQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataRepositoryQueryVariables) => query.refetch(variables)
                : (variables: GetDataRepositoryQueryVariables) => action({ variables })
        }
    ];
}
type GetAllDataRepositoriesQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllDataRepositoriesQuery["getAllDataRepositories"]>;
    refetch: (variables?: Partial<GetAllDataRepositoriesQueryVariables>) => void;
}
export function useGetAllDataRepositoriesQuery(
    variables: GetAllDataRepositoriesQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>, 'variables'> = {}
): GetAllDataRepositoriesQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>(GetAllDataRepositoriesDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataRepositories", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllDataRepositoriesLazyQueryHookReturnValue = [
    (variables: GetAllDataRepositoriesQueryVariables) => Promise<Apollo.QueryResult<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>>,
    { result: GraphqlAsyncData<GetAllDataRepositoriesQuery["getAllDataRepositories"]>, refetch: (variables: GetAllDataRepositoriesQueryVariables) => Promise<Apollo.QueryResult<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllDataRepositoriesQuery>> }
]
export function useGetAllDataRepositoriesLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>, 'variables'> = {}
): GetAllDataRepositoriesLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllDataRepositoriesQuery, GetAllDataRepositoriesQueryVariables>(GetAllDataRepositoriesDocument, options);
    return [
        useCallback((variables: GetAllDataRepositoriesQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataRepositories", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllDataRepositoriesQueryVariables) => query.refetch(variables)
                : (variables: GetAllDataRepositoriesQueryVariables) => action({ variables })
        }
    ];
}
type GetAllDataRepositoryCollectionsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllDataRepositoryCollectionsQuery["getAllDataRepositoryCollections"]>;
    refetch: (variables?: Partial<GetAllDataRepositoryCollectionsQueryVariables>) => void;
}
export function useGetAllDataRepositoryCollectionsQuery(
    variables: GetAllDataRepositoryCollectionsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>, 'variables'> = {}
): GetAllDataRepositoryCollectionsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>(GetAllDataRepositoryCollectionsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataRepositoryCollections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllDataRepositoryCollectionsLazyQueryHookReturnValue = [
    (variables: GetAllDataRepositoryCollectionsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>>,
    { result: GraphqlAsyncData<GetAllDataRepositoryCollectionsQuery["getAllDataRepositoryCollections"]>, refetch: (variables: GetAllDataRepositoryCollectionsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllDataRepositoryCollectionsQuery>> }
]
export function useGetAllDataRepositoryCollectionsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>, 'variables'> = {}
): GetAllDataRepositoryCollectionsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllDataRepositoryCollectionsQuery, GetAllDataRepositoryCollectionsQueryVariables>(GetAllDataRepositoryCollectionsDocument, options);
    return [
        useCallback((variables: GetAllDataRepositoryCollectionsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataRepositoryCollections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllDataRepositoryCollectionsQueryVariables) => query.refetch(variables)
                : (variables: GetAllDataRepositoryCollectionsQueryVariables) => action({ variables })
        }
    ];
}
type GetDataRepositoryRowsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataRepositoryRowsQuery["getDataRepositoryRows"]>;
    refetch: (variables?: Partial<GetDataRepositoryRowsQueryVariables>) => void;
}
export function useGetDataRepositoryRowsQuery(
    variables: GetDataRepositoryRowsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>, 'variables'> = {}
): GetDataRepositoryRowsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>(GetDataRepositoryRowsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepositoryRows", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataRepositoryRowsLazyQueryHookReturnValue = [
    (variables: GetDataRepositoryRowsQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>>,
    { result: GraphqlAsyncData<GetDataRepositoryRowsQuery["getDataRepositoryRows"]>, refetch: (variables: GetDataRepositoryRowsQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataRepositoryRowsQuery>> }
]
export function useGetDataRepositoryRowsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>, 'variables'> = {}
): GetDataRepositoryRowsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataRepositoryRowsQuery, GetDataRepositoryRowsQueryVariables>(GetDataRepositoryRowsDocument, options);
    return [
        useCallback((variables: GetDataRepositoryRowsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepositoryRows", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataRepositoryRowsQueryVariables) => query.refetch(variables)
                : (variables: GetDataRepositoryRowsQueryVariables) => action({ variables })
        }
    ];
}
type GetRepositoryRowDeleteQueriesByRepositoryQueryHookReturnValue = {
    result: GraphqlAsyncData<GetRepositoryRowDeleteQueriesByRepositoryQuery["getRepositoryRowDeleteQueriesByRepository"]>;
    refetch: (variables?: Partial<GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>) => void;
}
export function useGetRepositoryRowDeleteQueriesByRepositoryQuery(
    variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>, 'variables'> = {}
): GetRepositoryRowDeleteQueriesByRepositoryQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>(GetRepositoryRowDeleteQueriesByRepositoryDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getRepositoryRowDeleteQueriesByRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetRepositoryRowDeleteQueriesByRepositoryLazyQueryHookReturnValue = [
    (variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>>,
    { result: GraphqlAsyncData<GetRepositoryRowDeleteQueriesByRepositoryQuery["getRepositoryRowDeleteQueriesByRepository"]>, refetch: (variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetRepositoryRowDeleteQueriesByRepositoryQuery>> }
]
export function useGetRepositoryRowDeleteQueriesByRepositoryLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>, 'variables'> = {}
): GetRepositoryRowDeleteQueriesByRepositoryLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetRepositoryRowDeleteQueriesByRepositoryQuery, GetRepositoryRowDeleteQueriesByRepositoryQueryVariables>(GetRepositoryRowDeleteQueriesByRepositoryDocument, options);
    return [
        useCallback((variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getRepositoryRowDeleteQueriesByRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables) => query.refetch(variables)
                : (variables: GetRepositoryRowDeleteQueriesByRepositoryQueryVariables) => action({ variables })
        }
    ];
}
type GetImportsByDataRepositoryQueryHookReturnValue = {
    result: GraphqlAsyncData<GetImportsByDataRepositoryQuery["getImportsByDataRepository"]>;
    refetch: (variables?: Partial<GetImportsByDataRepositoryQueryVariables>) => void;
}
export function useGetImportsByDataRepositoryQuery(
    variables: GetImportsByDataRepositoryQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>, 'variables'> = {}
): GetImportsByDataRepositoryQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>(GetImportsByDataRepositoryDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getImportsByDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetImportsByDataRepositoryLazyQueryHookReturnValue = [
    (variables: GetImportsByDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>>,
    { result: GraphqlAsyncData<GetImportsByDataRepositoryQuery["getImportsByDataRepository"]>, refetch: (variables: GetImportsByDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetImportsByDataRepositoryQuery>> }
]
export function useGetImportsByDataRepositoryLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>, 'variables'> = {}
): GetImportsByDataRepositoryLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetImportsByDataRepositoryQuery, GetImportsByDataRepositoryQueryVariables>(GetImportsByDataRepositoryDocument, options);
    return [
        useCallback((variables: GetImportsByDataRepositoryQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getImportsByDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetImportsByDataRepositoryQueryVariables) => query.refetch(variables)
                : (variables: GetImportsByDataRepositoryQueryVariables) => action({ variables })
        }
    ];
}
type DeleteImportsMutationHookReturnValue = [
    (variables: DeleteImportsMutationVariables) => Promise<Apollo.FetchResult<DeleteImportsMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteImportsMutation["deleteImports"]> }
]
export function useDeleteImportsMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteImportsMutation, DeleteImportsMutationVariables>, 'variables'> = {}
): DeleteImportsMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteImportsMutation, DeleteImportsMutationVariables>(DeleteImportsDocument, options)
    return [
        useMemo(() => (variables: DeleteImportsMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteImportsMutation, DeleteImportsMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteImports", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteDataRepositoryCollectionMutationHookReturnValue = [
    (variables: DeleteDataRepositoryCollectionMutationVariables) => Promise<Apollo.FetchResult<DeleteDataRepositoryCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataRepositoryCollectionMutation["deleteDataRepositoryCollection"]> }
]
export function useDeleteDataRepositoryCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataRepositoryCollectionMutation, DeleteDataRepositoryCollectionMutationVariables>, 'variables'> = {}
): DeleteDataRepositoryCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataRepositoryCollectionMutation, DeleteDataRepositoryCollectionMutationVariables>(DeleteDataRepositoryCollectionDocument, options)
    return [
        useMemo(() => (variables: DeleteDataRepositoryCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataRepositoryCollectionMutation, DeleteDataRepositoryCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataRepositoryCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateDataRepositoryCollectionMutationHookReturnValue = [
    (variables: UpdateDataRepositoryCollectionMutationVariables) => Promise<Apollo.FetchResult<UpdateDataRepositoryCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataRepositoryCollectionMutation["updateDataRepositoryCollection"]> }
]
export function useUpdateDataRepositoryCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataRepositoryCollectionMutation, UpdateDataRepositoryCollectionMutationVariables>, 'variables'> = {}
): UpdateDataRepositoryCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataRepositoryCollectionMutation, UpdateDataRepositoryCollectionMutationVariables>(UpdateDataRepositoryCollectionDocument, options)
    return [
        useMemo(() => (variables: UpdateDataRepositoryCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataRepositoryCollectionMutation, UpdateDataRepositoryCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataRepositoryCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteDataRepositoryMutationHookReturnValue = [
    (variables: DeleteDataRepositoryMutationVariables) => Promise<Apollo.FetchResult<DeleteDataRepositoryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataRepositoryMutation["deleteDataRepository"]> }
]
export function useDeleteDataRepositoryMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataRepositoryMutation, DeleteDataRepositoryMutationVariables>, 'variables'> = {}
): DeleteDataRepositoryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataRepositoryMutation, DeleteDataRepositoryMutationVariables>(DeleteDataRepositoryDocument, options)
    return [
        useMemo(() => (variables: DeleteDataRepositoryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataRepositoryMutation, DeleteDataRepositoryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataRepository", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetDataRepositoryRowCountQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataRepositoryRowCountQuery["getDataRepositoryRowCount"]>;
    refetch: (variables?: Partial<GetDataRepositoryRowCountQueryVariables>) => void;
}
export function useGetDataRepositoryRowCountQuery(
    variables: GetDataRepositoryRowCountQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>, 'variables'> = {}
): GetDataRepositoryRowCountQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>(GetDataRepositoryRowCountDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepositoryRowCount", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataRepositoryRowCountLazyQueryHookReturnValue = [
    (variables: GetDataRepositoryRowCountQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>>,
    { result: GraphqlAsyncData<GetDataRepositoryRowCountQuery["getDataRepositoryRowCount"]>, refetch: (variables: GetDataRepositoryRowCountQueryVariables) => Promise<Apollo.QueryResult<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataRepositoryRowCountQuery>> }
]
export function useGetDataRepositoryRowCountLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>, 'variables'> = {}
): GetDataRepositoryRowCountLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataRepositoryRowCountQuery, GetDataRepositoryRowCountQueryVariables>(GetDataRepositoryRowCountDocument, options);
    return [
        useCallback((variables: GetDataRepositoryRowCountQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataRepositoryRowCount", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataRepositoryRowCountQueryVariables) => query.refetch(variables)
                : (variables: GetDataRepositoryRowCountQueryVariables) => action({ variables })
        }
    ];
}
type DeleteRowsByDeleteQueryMutationHookReturnValue = [
    (variables: DeleteRowsByDeleteQueryMutationVariables) => Promise<Apollo.FetchResult<DeleteRowsByDeleteQueryMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteRowsByDeleteQueryMutation["deleteRowsByDeleteQuery"]> }
]
export function useDeleteRowsByDeleteQueryMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteRowsByDeleteQueryMutation, DeleteRowsByDeleteQueryMutationVariables>, 'variables'> = {}
): DeleteRowsByDeleteQueryMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteRowsByDeleteQueryMutation, DeleteRowsByDeleteQueryMutationVariables>(DeleteRowsByDeleteQueryDocument, options)
    return [
        useMemo(() => (variables: DeleteRowsByDeleteQueryMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteRowsByDeleteQueryMutation, DeleteRowsByDeleteQueryMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteRowsByDeleteQuery", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetPaginatedImportsByDataRepositoryQueryHookReturnValue = {
    result: GraphqlAsyncData<GetPaginatedImportsByDataRepositoryQuery["getPaginatedImportsByDataRepository"]>;
    refetch: (variables?: Partial<GetPaginatedImportsByDataRepositoryQueryVariables>) => void;
}
export function useGetPaginatedImportsByDataRepositoryQuery(
    variables: GetPaginatedImportsByDataRepositoryQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>, 'variables'> = {}
): GetPaginatedImportsByDataRepositoryQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>(GetPaginatedImportsByDataRepositoryDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getPaginatedImportsByDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetPaginatedImportsByDataRepositoryLazyQueryHookReturnValue = [
    (variables: GetPaginatedImportsByDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>>,
    { result: GraphqlAsyncData<GetPaginatedImportsByDataRepositoryQuery["getPaginatedImportsByDataRepository"]>, refetch: (variables: GetPaginatedImportsByDataRepositoryQueryVariables) => Promise<Apollo.QueryResult<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetPaginatedImportsByDataRepositoryQuery>> }
]
export function useGetPaginatedImportsByDataRepositoryLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>, 'variables'> = {}
): GetPaginatedImportsByDataRepositoryLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetPaginatedImportsByDataRepositoryQuery, GetPaginatedImportsByDataRepositoryQueryVariables>(GetPaginatedImportsByDataRepositoryDocument, options);
    return [
        useCallback((variables: GetPaginatedImportsByDataRepositoryQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getPaginatedImportsByDataRepository", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetPaginatedImportsByDataRepositoryQueryVariables) => query.refetch(variables)
                : (variables: GetPaginatedImportsByDataRepositoryQueryVariables) => action({ variables })
        }
    ];
}
type ValidateFileMutationHookReturnValue = [
    (variables: ValidateFileMutationVariables) => Promise<Apollo.FetchResult<ValidateFileMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<ValidateFileMutation["validateFile"]> }
]
export function useValidateFileMutation(
    options: Omit<Apollo.MutationHookOptions<ValidateFileMutation, ValidateFileMutationVariables>, 'variables'> = {}
): ValidateFileMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<ValidateFileMutation, ValidateFileMutationVariables>(ValidateFileDocument, options)
    return [
        useMemo(() => (variables: ValidateFileMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<ValidateFileMutation, ValidateFileMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("validateFile", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetSupplierTableDataSourcesQueryHookReturnValue = {
    result: GraphqlAsyncData<GetSupplierTableDataSourcesQuery["getSupplierTableDataSources"]>;
    refetch: (variables?: Partial<GetSupplierTableDataSourcesQueryVariables>) => void;
}
export function useGetSupplierTableDataSourcesQuery(
    variables: GetSupplierTableDataSourcesQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>, 'variables'> = {}
): GetSupplierTableDataSourcesQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>(GetSupplierTableDataSourcesDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getSupplierTableDataSources", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetSupplierTableDataSourcesLazyQueryHookReturnValue = [
    (variables: GetSupplierTableDataSourcesQueryVariables) => Promise<Apollo.QueryResult<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>>,
    { result: GraphqlAsyncData<GetSupplierTableDataSourcesQuery["getSupplierTableDataSources"]>, refetch: (variables: GetSupplierTableDataSourcesQueryVariables) => Promise<Apollo.QueryResult<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetSupplierTableDataSourcesQuery>> }
]
export function useGetSupplierTableDataSourcesLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>, 'variables'> = {}
): GetSupplierTableDataSourcesLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetSupplierTableDataSourcesQuery, GetSupplierTableDataSourcesQueryVariables>(GetSupplierTableDataSourcesDocument, options);
    return [
        useCallback((variables: GetSupplierTableDataSourcesQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getSupplierTableDataSources", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetSupplierTableDataSourcesQueryVariables) => query.refetch(variables)
                : (variables: GetSupplierTableDataSourcesQueryVariables) => action({ variables })
        }
    ];
}
type CreateUploadUrlsV2MutationHookReturnValue = [
    (variables: CreateUploadUrlsV2MutationVariables) => Promise<Apollo.FetchResult<CreateUploadUrlsV2Mutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateUploadUrlsV2Mutation["createUploadUrlsV2"]> }
]
export function useCreateUploadUrlsV2Mutation(
    options: Omit<Apollo.MutationHookOptions<CreateUploadUrlsV2Mutation, CreateUploadUrlsV2MutationVariables>, 'variables'> = {}
): CreateUploadUrlsV2MutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateUploadUrlsV2Mutation, CreateUploadUrlsV2MutationVariables>(CreateUploadUrlsV2Document, options)
    return [
        useMemo(() => (variables: CreateUploadUrlsV2MutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateUploadUrlsV2Mutation, CreateUploadUrlsV2MutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createUploadUrlsV2", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateFilesUploadStatusV2MutationHookReturnValue = [
    (variables: UpdateFilesUploadStatusV2MutationVariables) => Promise<Apollo.FetchResult<UpdateFilesUploadStatusV2Mutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateFilesUploadStatusV2Mutation["updateFilesUploadStatusV2"]> }
]
export function useUpdateFilesUploadStatusV2Mutation(
    options: Omit<Apollo.MutationHookOptions<UpdateFilesUploadStatusV2Mutation, UpdateFilesUploadStatusV2MutationVariables>, 'variables'> = {}
): UpdateFilesUploadStatusV2MutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateFilesUploadStatusV2Mutation, UpdateFilesUploadStatusV2MutationVariables>(UpdateFilesUploadStatusV2Document, options)
    return [
        useMemo(() => (variables: UpdateFilesUploadStatusV2MutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateFilesUploadStatusV2Mutation, UpdateFilesUploadStatusV2MutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateFilesUploadStatusV2", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetDataTableElasticFieldsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableElasticFieldsQuery["getDataTableElasticFields"]>;
    refetch: (variables?: Partial<GetDataTableElasticFieldsQueryVariables>) => void;
}
export function useGetDataTableElasticFieldsQuery(
    variables: GetDataTableElasticFieldsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>, 'variables'> = {}
): GetDataTableElasticFieldsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>(GetDataTableElasticFieldsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableElasticFields", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableElasticFieldsLazyQueryHookReturnValue = [
    (variables: GetDataTableElasticFieldsQueryVariables) => Promise<Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableElasticFieldsQuery["getDataTableElasticFields"]>, refetch: (variables: GetDataTableElasticFieldsQueryVariables) => Promise<Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableElasticFieldsQuery>> }
]
export function useGetDataTableElasticFieldsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>, 'variables'> = {}
): GetDataTableElasticFieldsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>(GetDataTableElasticFieldsDocument, options);
    return [
        useCallback((variables: GetDataTableElasticFieldsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableElasticFields", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableElasticFieldsQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableElasticFieldsQueryVariables) => action({ variables })
        }
    ];
}
type GetDataTableQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableQuery["getDataTable"]>;
    refetch: (variables?: Partial<GetDataTableQueryVariables>) => void;
}
export function useGetDataTableQuery(
    variables: GetDataTableQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableQuery, GetDataTableQueryVariables>, 'variables'> = {}
): GetDataTableQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableQuery, GetDataTableQueryVariables>(GetDataTableDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTable", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableLazyQueryHookReturnValue = [
    (variables: GetDataTableQueryVariables) => Promise<Apollo.QueryResult<GetDataTableQuery, GetDataTableQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableQuery["getDataTable"]>, refetch: (variables: GetDataTableQueryVariables) => Promise<Apollo.QueryResult<GetDataTableQuery, GetDataTableQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableQuery>> }
]
export function useGetDataTableLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableQuery, GetDataTableQueryVariables>, 'variables'> = {}
): GetDataTableLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableQuery, GetDataTableQueryVariables>(GetDataTableDocument, options);
    return [
        useCallback((variables: GetDataTableQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTable", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableQueryVariables) => action({ variables })
        }
    ];
}
type GetManyDataTablesQueryHookReturnValue = {
    result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>;
    refetch: (variables?: Partial<GetManyDataTablesQueryVariables>) => void;
}
export function useGetManyDataTablesQuery(
    variables: GetManyDataTablesQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetManyDataTablesLazyQueryHookReturnValue = [
    (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>>,
    { result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>, refetch: (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetManyDataTablesQuery>> }
]
export function useGetManyDataTablesLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, options);
    return [
        useCallback((variables: GetManyDataTablesQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetManyDataTablesQueryVariables) => query.refetch(variables)
                : (variables: GetManyDataTablesQueryVariables) => action({ variables })
        }
    ];
}
type GetDataColumnsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataColumnsQuery["getDataColumns"]>;
    refetch: (variables?: Partial<GetDataColumnsQueryVariables>) => void;
}
export function useGetDataColumnsQuery(
    variables: GetDataColumnsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataColumnsQuery, GetDataColumnsQueryVariables>, 'variables'> = {}
): GetDataColumnsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataColumnsQuery, GetDataColumnsQueryVariables>(GetDataColumnsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataColumnsLazyQueryHookReturnValue = [
    (variables: GetDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>>,
    { result: GraphqlAsyncData<GetDataColumnsQuery["getDataColumns"]>, refetch: (variables: GetDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataColumnsQuery>> }
]
export function useGetDataColumnsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataColumnsQuery, GetDataColumnsQueryVariables>, 'variables'> = {}
): GetDataColumnsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataColumnsQuery, GetDataColumnsQueryVariables>(GetDataColumnsDocument, options);
    return [
        useCallback((variables: GetDataColumnsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataColumnsQueryVariables) => query.refetch(variables)
                : (variables: GetDataColumnsQueryVariables) => action({ variables })
        }
    ];
}
type GetDataTableRowsSearchQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableRowsSearchQuery["getDataTableRowsSearch"]>;
    refetch: (variables?: Partial<GetDataTableRowsSearchQueryVariables>) => void;
}
export function useGetDataTableRowsSearchQuery(
    variables: GetDataTableRowsSearchQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>, 'variables'> = {}
): GetDataTableRowsSearchQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>(GetDataTableRowsSearchDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRowsSearch", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableRowsSearchLazyQueryHookReturnValue = [
    (variables: GetDataTableRowsSearchQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableRowsSearchQuery["getDataTableRowsSearch"]>, refetch: (variables: GetDataTableRowsSearchQueryVariables) => Promise<Apollo.QueryResult<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableRowsSearchQuery>> }
]
export function useGetDataTableRowsSearchLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>, 'variables'> = {}
): GetDataTableRowsSearchLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableRowsSearchQuery, GetDataTableRowsSearchQueryVariables>(GetDataTableRowsSearchDocument, options);
    return [
        useCallback((variables: GetDataTableRowsSearchQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableRowsSearch", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableRowsSearchQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableRowsSearchQueryVariables) => action({ variables })
        }
    ];
}
type CreateDataColumnsMutationHookReturnValue = [
    (variables: CreateDataColumnsMutationVariables) => Promise<Apollo.FetchResult<CreateDataColumnsMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataColumnsMutation["createDataColumns"]> }
]
export function useCreateDataColumnsMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataColumnsMutation, CreateDataColumnsMutationVariables>, 'variables'> = {}
): CreateDataColumnsMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataColumnsMutation, CreateDataColumnsMutationVariables>(CreateDataColumnsDocument, options)
    return [
        useMemo(() => (variables: CreateDataColumnsMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataColumnsMutation, CreateDataColumnsMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataColumns", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateDataColumnMutationHookReturnValue = [
    (variables: UpdateDataColumnMutationVariables) => Promise<Apollo.FetchResult<UpdateDataColumnMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataColumnMutation["updateDataColumn"]> }
]
export function useUpdateDataColumnMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>, 'variables'> = {}
): UpdateDataColumnMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>(UpdateDataColumnDocument, options)
    return [
        useMemo(() => (variables: UpdateDataColumnMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataColumn", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteDataColumnsMutationHookReturnValue = [
    (variables: DeleteDataColumnsMutationVariables) => Promise<Apollo.FetchResult<DeleteDataColumnsMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataColumnsMutation["deleteDataColumns"]> }
]
export function useDeleteDataColumnsMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataColumnsMutation, DeleteDataColumnsMutationVariables>, 'variables'> = {}
): DeleteDataColumnsMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataColumnsMutation, DeleteDataColumnsMutationVariables>(DeleteDataColumnsDocument, options)
    return [
        useMemo(() => (variables: DeleteDataColumnsMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataColumnsMutation, DeleteDataColumnsMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataColumns", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateDataTableMutationHookReturnValue = [
    (variables: CreateDataTableMutationVariables) => Promise<Apollo.FetchResult<CreateDataTableMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataTableMutation["createDataTable"]> }
]
export function useCreateDataTableMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataTableMutation, CreateDataTableMutationVariables>, 'variables'> = {}
): CreateDataTableMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataTableMutation, CreateDataTableMutationVariables>(CreateDataTableDocument, options)
    return [
        useMemo(() => (variables: CreateDataTableMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataTableMutation, CreateDataTableMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataTable", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateDataTableMutationHookReturnValue = [
    (variables: UpdateDataTableMutationVariables) => Promise<Apollo.FetchResult<UpdateDataTableMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataTableMutation["updateDataTable"]> }
]
export function useUpdateDataTableMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>, 'variables'> = {}
): UpdateDataTableMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataTableMutation, UpdateDataTableMutationVariables>(UpdateDataTableDocument, options)
    return [
        useMemo(() => (variables: UpdateDataTableMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataTable", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type DeleteDataTableMutationHookReturnValue = [
    (variables: DeleteDataTableMutationVariables) => Promise<Apollo.FetchResult<DeleteDataTableMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataTableMutation["deleteDataTable"]> }
]
export function useDeleteDataTableMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataTableMutation, DeleteDataTableMutationVariables>, 'variables'> = {}
): DeleteDataTableMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataTableMutation, DeleteDataTableMutationVariables>(DeleteDataTableDocument, options)
    return [
        useMemo(() => (variables: DeleteDataTableMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataTableMutation, DeleteDataTableMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataTable", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type IndexDataTableToElasticMutationHookReturnValue = [
    (variables: IndexDataTableToElasticMutationVariables) => Promise<Apollo.FetchResult<IndexDataTableToElasticMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<IndexDataTableToElasticMutation["indexDataTableToElastic"]> }
]
export function useIndexDataTableToElasticMutation(
    options: Omit<Apollo.MutationHookOptions<IndexDataTableToElasticMutation, IndexDataTableToElasticMutationVariables>, 'variables'> = {}
): IndexDataTableToElasticMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<IndexDataTableToElasticMutation, IndexDataTableToElasticMutationVariables>(IndexDataTableToElasticDocument, options)
    return [
        useMemo(() => (variables: IndexDataTableToElasticMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<IndexDataTableToElasticMutation, IndexDataTableToElasticMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("indexDataTableToElastic", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateDataTableCollectionMutationHookReturnValue = [
    (variables: CreateDataTableCollectionMutationVariables) => Promise<Apollo.FetchResult<CreateDataTableCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateDataTableCollectionMutation["createDataTableCollection"]> }
]
export function useCreateDataTableCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<CreateDataTableCollectionMutation, CreateDataTableCollectionMutationVariables>, 'variables'> = {}
): CreateDataTableCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateDataTableCollectionMutation, CreateDataTableCollectionMutationVariables>(CreateDataTableCollectionDocument, options)
    return [
        useMemo(() => (variables: CreateDataTableCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateDataTableCollectionMutation, CreateDataTableCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createDataTableCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetAllDataColumnsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllDataColumnsQuery["getAllDataColumns"]>;
    refetch: (variables?: Partial<GetAllDataColumnsQueryVariables>) => void;
}
export function useGetAllDataColumnsQuery(
    variables: GetAllDataColumnsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>, 'variables'> = {}
): GetAllDataColumnsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>(GetAllDataColumnsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllDataColumnsLazyQueryHookReturnValue = [
    (variables: GetAllDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>>,
    { result: GraphqlAsyncData<GetAllDataColumnsQuery["getAllDataColumns"]>, refetch: (variables: GetAllDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllDataColumnsQuery>> }
]
export function useGetAllDataColumnsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>, 'variables'> = {}
): GetAllDataColumnsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllDataColumnsQuery, GetAllDataColumnsQueryVariables>(GetAllDataColumnsDocument, options);
    return [
        useCallback((variables: GetAllDataColumnsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllDataColumnsQueryVariables) => query.refetch(variables)
                : (variables: GetAllDataColumnsQueryVariables) => action({ variables })
        }
    ];
}
type DeleteDataTableCollectionMutationHookReturnValue = [
    (variables: DeleteDataTableCollectionMutationVariables) => Promise<Apollo.FetchResult<DeleteDataTableCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteDataTableCollectionMutation["deleteDataTableCollection"]> }
]
export function useDeleteDataTableCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteDataTableCollectionMutation, DeleteDataTableCollectionMutationVariables>, 'variables'> = {}
): DeleteDataTableCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteDataTableCollectionMutation, DeleteDataTableCollectionMutationVariables>(DeleteDataTableCollectionDocument, options)
    return [
        useMemo(() => (variables: DeleteDataTableCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteDataTableCollectionMutation, DeleteDataTableCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteDataTableCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateDataTableCollectionMutationHookReturnValue = [
    (variables: UpdateDataTableCollectionMutationVariables) => Promise<Apollo.FetchResult<UpdateDataTableCollectionMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataTableCollectionMutation["updateDataTableCollection"]> }
]
export function useUpdateDataTableCollectionMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataTableCollectionMutation, UpdateDataTableCollectionMutationVariables>, 'variables'> = {}
): UpdateDataTableCollectionMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataTableCollectionMutation, UpdateDataTableCollectionMutationVariables>(UpdateDataTableCollectionDocument, options)
    return [
        useMemo(() => (variables: UpdateDataTableCollectionMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataTableCollectionMutation, UpdateDataTableCollectionMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataTableCollection", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetAllDataTableCollectionsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllDataTableCollectionsQuery["getAllDataTableCollections"]>;
    refetch: (variables?: Partial<GetAllDataTableCollectionsQueryVariables>) => void;
}
export function useGetAllDataTableCollectionsQuery(
    variables: GetAllDataTableCollectionsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>, 'variables'> = {}
): GetAllDataTableCollectionsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>(GetAllDataTableCollectionsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataTableCollections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllDataTableCollectionsLazyQueryHookReturnValue = [
    (variables: GetAllDataTableCollectionsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>>,
    { result: GraphqlAsyncData<GetAllDataTableCollectionsQuery["getAllDataTableCollections"]>, refetch: (variables: GetAllDataTableCollectionsQueryVariables) => Promise<Apollo.QueryResult<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllDataTableCollectionsQuery>> }
]
export function useGetAllDataTableCollectionsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>, 'variables'> = {}
): GetAllDataTableCollectionsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllDataTableCollectionsQuery, GetAllDataTableCollectionsQueryVariables>(GetAllDataTableCollectionsDocument, options);
    return [
        useCallback((variables: GetAllDataTableCollectionsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllDataTableCollections", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllDataTableCollectionsQueryVariables) => query.refetch(variables)
                : (variables: GetAllDataTableCollectionsQueryVariables) => action({ variables })
        }
    ];
}
type CreateImportConfigurationMutationHookReturnValue = [
    (variables: CreateImportConfigurationMutationVariables) => Promise<Apollo.FetchResult<CreateImportConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateImportConfigurationMutation["createImportConfiguration"]> }
]
export function useCreateImportConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<CreateImportConfigurationMutation, CreateImportConfigurationMutationVariables>, 'variables'> = {}
): CreateImportConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateImportConfigurationMutation, CreateImportConfigurationMutationVariables>(CreateImportConfigurationDocument, options)
    return [
        useMemo(() => (variables: CreateImportConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateImportConfigurationMutation, CreateImportConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createImportConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateImportConfigurationMutationHookReturnValue = [
    (variables: UpdateImportConfigurationMutationVariables) => Promise<Apollo.FetchResult<UpdateImportConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateImportConfigurationMutation["updateImportConfiguration"]> }
]
export function useUpdateImportConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateImportConfigurationMutation, UpdateImportConfigurationMutationVariables>, 'variables'> = {}
): UpdateImportConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateImportConfigurationMutation, UpdateImportConfigurationMutationVariables>(UpdateImportConfigurationDocument, options)
    return [
        useMemo(() => (variables: UpdateImportConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateImportConfigurationMutation, UpdateImportConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateImportConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetImportConfigurationQueryHookReturnValue = {
    result: GraphqlAsyncData<GetImportConfigurationQuery["getImportConfiguration"]>;
    refetch: (variables?: Partial<GetImportConfigurationQueryVariables>) => void;
}
export function useGetImportConfigurationQuery(
    variables: GetImportConfigurationQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>, 'variables'> = {}
): GetImportConfigurationQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>(GetImportConfigurationDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getImportConfiguration", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetImportConfigurationLazyQueryHookReturnValue = [
    (variables: GetImportConfigurationQueryVariables) => Promise<Apollo.QueryResult<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>>,
    { result: GraphqlAsyncData<GetImportConfigurationQuery["getImportConfiguration"]>, refetch: (variables: GetImportConfigurationQueryVariables) => Promise<Apollo.QueryResult<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetImportConfigurationQuery>> }
]
export function useGetImportConfigurationLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>, 'variables'> = {}
): GetImportConfigurationLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetImportConfigurationQuery, GetImportConfigurationQueryVariables>(GetImportConfigurationDocument, options);
    return [
        useCallback((variables: GetImportConfigurationQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getImportConfiguration", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetImportConfigurationQueryVariables) => query.refetch(variables)
                : (variables: GetImportConfigurationQueryVariables) => action({ variables })
        }
    ];
}
type DeleteImportConfigurationMutationHookReturnValue = [
    (variables: DeleteImportConfigurationMutationVariables) => Promise<Apollo.FetchResult<DeleteImportConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteImportConfigurationMutation["deleteImportConfiguration"]> }
]
export function useDeleteImportConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteImportConfigurationMutation, DeleteImportConfigurationMutationVariables>, 'variables'> = {}
): DeleteImportConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteImportConfigurationMutation, DeleteImportConfigurationMutationVariables>(DeleteImportConfigurationDocument, options)
    return [
        useMemo(() => (variables: DeleteImportConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteImportConfigurationMutation, DeleteImportConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteImportConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetAllImportConfigurationsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetAllImportConfigurationsQuery["getAllImportConfigurations"]>;
    refetch: (variables?: Partial<GetAllImportConfigurationsQueryVariables>) => void;
}
export function useGetAllImportConfigurationsQuery(
    variables: GetAllImportConfigurationsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>, 'variables'> = {}
): GetAllImportConfigurationsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>(GetAllImportConfigurationsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getAllImportConfigurations", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetAllImportConfigurationsLazyQueryHookReturnValue = [
    (variables: GetAllImportConfigurationsQueryVariables) => Promise<Apollo.QueryResult<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>>,
    { result: GraphqlAsyncData<GetAllImportConfigurationsQuery["getAllImportConfigurations"]>, refetch: (variables: GetAllImportConfigurationsQueryVariables) => Promise<Apollo.QueryResult<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetAllImportConfigurationsQuery>> }
]
export function useGetAllImportConfigurationsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>, 'variables'> = {}
): GetAllImportConfigurationsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetAllImportConfigurationsQuery, GetAllImportConfigurationsQueryVariables>(GetAllImportConfigurationsDocument, options);
    return [
        useCallback((variables: GetAllImportConfigurationsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getAllImportConfigurations", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetAllImportConfigurationsQueryVariables) => query.refetch(variables)
                : (variables: GetAllImportConfigurationsQueryVariables) => action({ variables })
        }
    ];
}
type ParseFileSamplesMutationHookReturnValue = [
    (variables: ParseFileSamplesMutationVariables) => Promise<Apollo.FetchResult<ParseFileSamplesMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<ParseFileSamplesMutation["parseFileSamples"]> }
]
export function useParseFileSamplesMutation(
    options: Omit<Apollo.MutationHookOptions<ParseFileSamplesMutation, ParseFileSamplesMutationVariables>, 'variables'> = {}
): ParseFileSamplesMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<ParseFileSamplesMutation, ParseFileSamplesMutationVariables>(ParseFileSamplesDocument, options)
    return [
        useMemo(() => (variables: ParseFileSamplesMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<ParseFileSamplesMutation, ParseFileSamplesMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("parseFileSamples", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type AddImportFieldMappingMutationHookReturnValue = [
    (variables: AddImportFieldMappingMutationVariables) => Promise<Apollo.FetchResult<AddImportFieldMappingMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<AddImportFieldMappingMutation["addImportFieldMapping"]> }
]
export function useAddImportFieldMappingMutation(
    options: Omit<Apollo.MutationHookOptions<AddImportFieldMappingMutation, AddImportFieldMappingMutationVariables>, 'variables'> = {}
): AddImportFieldMappingMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<AddImportFieldMappingMutation, AddImportFieldMappingMutationVariables>(AddImportFieldMappingDocument, options)
    return [
        useMemo(() => (variables: AddImportFieldMappingMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<AddImportFieldMappingMutation, AddImportFieldMappingMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("addImportFieldMapping", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type AddManyImportFieldMappingsMutationHookReturnValue = [
    (variables: AddManyImportFieldMappingsMutationVariables) => Promise<Apollo.FetchResult<AddManyImportFieldMappingsMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<AddManyImportFieldMappingsMutation["addManyImportFieldMappings"]> }
]
export function useAddManyImportFieldMappingsMutation(
    options: Omit<Apollo.MutationHookOptions<AddManyImportFieldMappingsMutation, AddManyImportFieldMappingsMutationVariables>, 'variables'> = {}
): AddManyImportFieldMappingsMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<AddManyImportFieldMappingsMutation, AddManyImportFieldMappingsMutationVariables>(AddManyImportFieldMappingsDocument, options)
    return [
        useMemo(() => (variables: AddManyImportFieldMappingsMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<AddManyImportFieldMappingsMutation, AddManyImportFieldMappingsMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("addManyImportFieldMappings", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type StartFileImportByFileIdMutationHookReturnValue = [
    (variables: StartFileImportByFileIdMutationVariables) => Promise<Apollo.FetchResult<StartFileImportByFileIdMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<StartFileImportByFileIdMutation["startFileImportByFileId"]> }
]
export function useStartFileImportByFileIdMutation(
    options: Omit<Apollo.MutationHookOptions<StartFileImportByFileIdMutation, StartFileImportByFileIdMutationVariables>, 'variables'> = {}
): StartFileImportByFileIdMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<StartFileImportByFileIdMutation, StartFileImportByFileIdMutationVariables>(StartFileImportByFileIdDocument, options)
    return [
        useMemo(() => (variables: StartFileImportByFileIdMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<StartFileImportByFileIdMutation, StartFileImportByFileIdMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("startFileImportByFileId", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type CreateUniqueIdentifierConfigurationMutationHookReturnValue = [
    (variables: CreateUniqueIdentifierConfigurationMutationVariables) => Promise<Apollo.FetchResult<CreateUniqueIdentifierConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<CreateUniqueIdentifierConfigurationMutation["createUniqueIdentifierConfiguration"]> }
]
export function useCreateUniqueIdentifierConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}
): CreateUniqueIdentifierConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>(CreateUniqueIdentifierConfigurationDocument, options)
    return [
        useMemo(() => (variables: CreateUniqueIdentifierConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<CreateUniqueIdentifierConfigurationMutation, CreateUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("createUniqueIdentifierConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetUniqueIdentifierConfigurationQueryHookReturnValue = {
    result: GraphqlAsyncData<GetUniqueIdentifierConfigurationQuery["getUniqueIdentifierConfiguration"]>;
    refetch: (variables?: Partial<GetUniqueIdentifierConfigurationQueryVariables>) => void;
}
export function useGetUniqueIdentifierConfigurationQuery(
    variables: GetUniqueIdentifierConfigurationQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>, 'variables'> = {}
): GetUniqueIdentifierConfigurationQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>(GetUniqueIdentifierConfigurationDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getUniqueIdentifierConfiguration", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetUniqueIdentifierConfigurationLazyQueryHookReturnValue = [
    (variables: GetUniqueIdentifierConfigurationQueryVariables) => Promise<Apollo.QueryResult<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>>,
    { result: GraphqlAsyncData<GetUniqueIdentifierConfigurationQuery["getUniqueIdentifierConfiguration"]>, refetch: (variables: GetUniqueIdentifierConfigurationQueryVariables) => Promise<Apollo.QueryResult<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetUniqueIdentifierConfigurationQuery>> }
]
export function useGetUniqueIdentifierConfigurationLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>, 'variables'> = {}
): GetUniqueIdentifierConfigurationLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetUniqueIdentifierConfigurationQuery, GetUniqueIdentifierConfigurationQueryVariables>(GetUniqueIdentifierConfigurationDocument, options);
    return [
        useCallback((variables: GetUniqueIdentifierConfigurationQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getUniqueIdentifierConfiguration", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetUniqueIdentifierConfigurationQueryVariables) => query.refetch(variables)
                : (variables: GetUniqueIdentifierConfigurationQueryVariables) => action({ variables })
        }
    ];
}
type DeleteUniqueIdentifierConfigurationMutationHookReturnValue = [
    (variables: DeleteUniqueIdentifierConfigurationMutationVariables) => Promise<Apollo.FetchResult<DeleteUniqueIdentifierConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<DeleteUniqueIdentifierConfigurationMutation["deleteUniqueIdentifierConfiguration"]> }
]
export function useDeleteUniqueIdentifierConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}
): DeleteUniqueIdentifierConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>(DeleteUniqueIdentifierConfigurationDocument, options)
    return [
        useMemo(() => (variables: DeleteUniqueIdentifierConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<DeleteUniqueIdentifierConfigurationMutation, DeleteUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("deleteUniqueIdentifierConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type UpdateUniqueIdentifierConfigurationMutationHookReturnValue = [
    (variables: UpdateUniqueIdentifierConfigurationMutationVariables) => Promise<Apollo.FetchResult<UpdateUniqueIdentifierConfigurationMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateUniqueIdentifierConfigurationMutation["updateUniqueIdentifierConfiguration"]> }
]
export function useUpdateUniqueIdentifierConfigurationMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}
): UpdateUniqueIdentifierConfigurationMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>(UpdateUniqueIdentifierConfigurationDocument, options)
    return [
        useMemo(() => (variables: UpdateUniqueIdentifierConfigurationMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateUniqueIdentifierConfigurationMutation, UpdateUniqueIdentifierConfigurationMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateUniqueIdentifierConfiguration", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetManyUniqueIdentifierConfigurationsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetManyUniqueIdentifierConfigurationsQuery["getManyUniqueIdentifierConfigurations"]>;
    refetch: (variables?: Partial<GetManyUniqueIdentifierConfigurationsQueryVariables>) => void;
}
export function useGetManyUniqueIdentifierConfigurationsQuery(
    variables: GetManyUniqueIdentifierConfigurationsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>, 'variables'> = {}
): GetManyUniqueIdentifierConfigurationsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>(GetManyUniqueIdentifierConfigurationsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getManyUniqueIdentifierConfigurations", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetManyUniqueIdentifierConfigurationsLazyQueryHookReturnValue = [
    (variables: GetManyUniqueIdentifierConfigurationsQueryVariables) => Promise<Apollo.QueryResult<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>>,
    { result: GraphqlAsyncData<GetManyUniqueIdentifierConfigurationsQuery["getManyUniqueIdentifierConfigurations"]>, refetch: (variables: GetManyUniqueIdentifierConfigurationsQueryVariables) => Promise<Apollo.QueryResult<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetManyUniqueIdentifierConfigurationsQuery>> }
]
export function useGetManyUniqueIdentifierConfigurationsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>, 'variables'> = {}
): GetManyUniqueIdentifierConfigurationsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetManyUniqueIdentifierConfigurationsQuery, GetManyUniqueIdentifierConfigurationsQueryVariables>(GetManyUniqueIdentifierConfigurationsDocument, options);
    return [
        useCallback((variables: GetManyUniqueIdentifierConfigurationsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getManyUniqueIdentifierConfigurations", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetManyUniqueIdentifierConfigurationsQueryVariables) => query.refetch(variables)
                : (variables: GetManyUniqueIdentifierConfigurationsQueryVariables) => action({ variables })
        }
    ];
}